import { useLodCloudDynamicRenderer } from "@/hooks/use-lod-cloud-dynamic-renderer";
import { PointCloudObject } from "@/object-cache";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import { SubScene } from "@faro-lotv/app-component-toolbox";
import { SceneFilterFunction } from "@faro-lotv/lotv";

type PointCloudSimpleSubsceneProps = {
  /** The point cloud to be rendered */
  pointCloud: PointCloudObject | null;

  /** Whether the subscene is enabled */
  enabled?: boolean;

  /** Function to select from the scene graph only the elements to be rendered in this subscene. */
  filter?: SceneFilterFunction;
};

/**
 * @returns a simple subscene suitable to render an LOD point cloud,
 * with dynamic performance optimization. No gap filling or eye dome are used.
 */
export function PointCloudSimpleSubscene({
  pointCloud,
  enabled = true,
  filter = isObjPointCloudPoint,
}: PointCloudSimpleSubsceneProps): JSX.Element {
  const dynamicRenderer = useLodCloudDynamicRenderer(pointCloud);

  return (
    <SubScene
      filter={filter}
      renderingPolicy={dynamicRenderer}
      enabled={!!pointCloud && enabled}
    />
  );
}
