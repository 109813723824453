import { assert } from "@faro-lotv/foundation";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

/**
 * Four different profiles for the point cloud rendering pipeline.
 * The goal is to present two properties: the smooth movement/loading of the point cloud
 * and the sharpness of the point cloud details, and to claim that it is not
 * possible to have them both, so the user has to strike a balance, and select
 * one out of four options.
 */
export enum PointCloudRenderingProfile {
  /**
   * Maximum smoothness and continuous flow while moving/loading the point cloud.
   * Lots of interpolative shaders like gap filling and adaptive point size are used.
   * Especially on Slam data, but also on Focus scans, details may appear blurred.
   * Good for Sitescape data.
   */
  SmoothSilk = "SmoothSilk",

  /** Good smoothness and optical flow, details appear sharper especially when more points are loaded. */
  Smooth = "Smooth",

  /**
   * Good smoothness and optical flow, details are fairly sharp because they are highlighted with eyedome + outline
   * and the point size is fairly small. Good setting especially for Slam data, and for obtaining a "dollhouse view"
   * of the point cloud.
   */
  Sharp = "Sharp",

  /**
   * Maximum sharpness of detail (minimal gap filling and point size fixed to 1px). Details have maximum evidence, with eyedome + outline.
   * On most point clouds, when moving/loading the points the user experiences a considerable amount of jitter and noise.
   * Good for taking a snapshot of the whole cloud, or of a detail, for detailed annotation/measuring or for marketing.
   */
  Sharpest = "Sharpest",
}

/**
 * Gap filling parameter: disabled, optimized for speed, optimized for maximum gap size.
 */
export enum GapFillingOptions {
  Disabled = "Disabled",
  Speed = "Speed",
  Size = "Size",
}

/**
 * Point size parameter
 */
export enum PointSizeOptions {
  /** This option enables adaptive point size between 6 px and 2 px per point */
  Adaptive = "Adaptive",
  /** This option enables adaptive point size between 3 px and 1 px per point */
  AdaptiveSharper = "AdaptiveSharper",
  /** Point size fixed to 2px */
  FixedTwo = "FixedTwo",
  /** Point size fixed to 1px */
  FixedOne = "FixedOne",
}

export type RenderingSettings = {
  /** The gap filling setting */
  gapFilling: GapFillingOptions;

  /** Point size setting */
  pointSize: PointSizeOptions;

  /** Whether eye dome is enabled */
  eyeDomeOn: boolean;

  /** Point cloud rendering profile */
  pointCloudRenderingProfile: PointCloudRenderingProfile;

  /** Sets the point cloud rendering profile*/
  setPointCloudRenderingProfile(profile: PointCloudRenderingProfile): void;
};

const RenderingSettingsContext = createContext<RenderingSettings | undefined>(
  undefined,
);

/** @returns the rendering settings context */
export function useRenderingSettings(): RenderingSettings {
  const ret = useContext(RenderingSettingsContext);
  assert(ret, "Rendering settings context should be initialized");
  return ret;
}

/** @returns a component to create the rendering settings context */
export function RenderingSettingsContextProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [gapFilling, setGapFilling] = useState(GapFillingOptions.Size);
  const [pointSize, setPointSize] = useState(PointSizeOptions.Adaptive);
  const [eyeDomeOn, setEyeDomeOn] = useState(false);
  const [pointCloudRenderingProfile, setPointCloudRenderingProfile] = useState(
    PointCloudRenderingProfile.SmoothSilk,
  );

  // Each point cloud rendering profile corresponds to a specific set of low-level rendering parameters.
  useEffect(() => {
    switch (pointCloudRenderingProfile) {
      case PointCloudRenderingProfile.SmoothSilk:
        setGapFilling(GapFillingOptions.Size);
        setPointSize(PointSizeOptions.Adaptive);
        setEyeDomeOn(false);
        break;
      case PointCloudRenderingProfile.Smooth:
        setGapFilling(GapFillingOptions.Size);
        setPointSize(PointSizeOptions.AdaptiveSharper);
        setEyeDomeOn(false);
        break;
      case PointCloudRenderingProfile.Sharp:
        setGapFilling(GapFillingOptions.Speed);
        setPointSize(PointSizeOptions.AdaptiveSharper);
        setEyeDomeOn(true);
        break;
      case PointCloudRenderingProfile.Sharpest:
        setGapFilling(GapFillingOptions.Speed);
        setPointSize(PointSizeOptions.FixedOne);
        setEyeDomeOn(true);
        break;
    }
  }, [pointCloudRenderingProfile, setGapFilling, setPointSize, setEyeDomeOn]);

  return (
    <RenderingSettingsContext.Provider
      value={{
        gapFilling,
        pointSize,
        eyeDomeOn,
        pointCloudRenderingProfile,
        setPointCloudRenderingProfile,
      }}
    >
      {children}
    </RenderingSettingsContext.Provider>
  );
}
