import { ArrowDownIcon, FaroIconButton, neutral } from "@faro-lotv/flat-ui";
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { AlignmentWizardReferenceSelector } from "./align-wizard-reference-tree";

/**
 * @returns control to select reference element in Alignment Wizard
 */
export function ReferenceSelectorTool(): JSX.Element {
  const [isCollapsed, setCollapsed] = useState(false);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        borderRadius: "5px",
        border: "1px",
        borderLeftColor: neutral[200],
        borderLeftStyle: "solid",
        borderRightColor: isCollapsed ? neutral[0] : neutral[200],
        borderRightStyle: "solid",
        px: 1,
        pt: 1,
        backgroundColor: neutral[0],
        position: "absolute",
        top: 68,
        height: "auto",
        left: "50%",
        width: isCollapsed ? 0 : "250px",
      }}
    >
      <Box
        component="div"
        sx={{
          backgroundColor: neutral[0],
          position: "absolute",
          left: -20,
        }}
      >
        <FaroIconButton
          onClick={() => {
            setCollapsed(!isCollapsed);
          }}
          title={
            isCollapsed ? "Expand selector tool" : "Collapse selector tool"
          }
        >
          <ArrowDownIcon
            sx={{
              transform: isCollapsed ? "rotate(-90deg)" : "rotate(90deg)",
              transition: "transform 0.1s linear",
            }}
          />
        </FaroIconButton>
      </Box>
      {!isCollapsed && <AlignmentWizardReferenceSelector />}
    </Stack>
  );
}
