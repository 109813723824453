import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum ScanColoring {
  /** Different color for each scan */
  byScan = "byScan",

  /** The original point cloud color */
  originalColor = "originalColor",
}

type DataPreparationViewOptionsState = {
  /** Scan coloring to use for displaying multiple individual scans */
  scanColoring: ScanColoring;
};

/** Initial values for the ViewOptionsSlice */
export const DATA_PREPARATION_VIEW_OPTIONS_INITIAL_VALUES: DataPreparationViewOptionsState =
  {
    scanColoring: ScanColoring.byScan,
  };

export const dataPreparationViewOptionsSlice = createSlice({
  initialState: DATA_PREPARATION_VIEW_OPTIONS_INITIAL_VALUES,
  name: "dataPreparationViewOptions",
  reducers: {
    /**
     * Updates the active scan coloring mode
     *
     * @param state current state
     * @param action new scan coloring mode
     */
    setScanColoring(state, action: PayloadAction<ScanColoring>) {
      state.scanColoring = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      clearStore,
      () => DATA_PREPARATION_VIEW_OPTIONS_INITIAL_VALUES,
    );
  },
});

export const { setScanColoring } = dataPreparationViewOptionsSlice.actions;

export const dataPreparationViewOptionsReducer =
  dataPreparationViewOptionsSlice.reducer;
