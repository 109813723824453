import {
  computeReferenceSystemProperties,
  fetchProjectIElements,
  selectIElement,
} from "@faro-lotv/app-component-toolbox";
import { parseImageFromFile } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import { IElementType, IElementTypeHint } from "@faro-lotv/ielement-types";
import {
  createMutationSetElementThumbnailUri,
  mutationAddImgSheet,
} from "@faro-lotv/service-wires";
import { UpdateProjectParam } from "./use-upload-element";

/**
 * Function that sends the MutationAddImgSheet mutation to the project api and updates the local copy of the project
 */
export async function updateAreaWithImgSheet({
  appStore,
  dispatch,
  projectApi,
  areaId,
  file,
  name,
  createdAt,
  downloadUrl,
  additionalUrls,
  md5Hash,
}: UpdateProjectParam): Promise<void> {
  assert(areaId, "AreaId to update image sheet is not specified.");

  const sectionArea = selectIElement(areaId)(appStore.getState());
  assert(sectionArea, `No area found with ID ${areaId}`);

  const { width, height } = await parseImageFromFile(file);

  // TODO: use new ProjectAPI endpoint https://faro01.atlassian.net/browse/SWEB-2599
  await projectApi.applyMutations([
    mutationAddImgSheet({
      rootId: sectionArea.rootId,
      sectionId: sectionArea.id,
      name,
      createdAt: createdAt.toISOString(),
      fileName: file.name,
      fileSize: file.size,
      md5Hash,
      uri: downloadUrl,
      thumbnailUri: additionalUrls[0],
      pixelWidth: width,
      pixelHeight: height,
      refCoordSystemMatrix: computeReferenceSystemProperties(
        { type: IElementType.imgSheet, typeHint: IElementTypeHint.area },
        false,
      ),
    }),
    // Setting the thumbnail on the section makes it sync correctly to the HB slide container
    createMutationSetElementThumbnailUri(sectionArea.id, additionalUrls[0]),
  ]);

  // Fetch the changed sub-tree and update the local copy of the project
  await dispatch(
    fetchProjectIElements({
      // eslint-disable-next-line require-await -- FIXME
      fetcher: async () =>
        projectApi.getAllIElements({
          // We need to refresh from the area
          ancestorIds: [areaId],
        }),
    }),
  );
}
