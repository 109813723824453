import { PointCloudSubscene } from "@/components/r3f/effects/point-cloud-subscene";
import { CadSubscene } from "@/modes/overview-mode/cad-subscene";
import { RenderDispatch } from "@/modes/overview-mode/render-dispatch";
import { CadModelObject, PointCloudObject } from "@/object-cache";
import {
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
} from "@faro-lotv/app-component-toolbox";
import { Object3D, Points } from "three";

export type SplitscreenModePipelineProps = {
  /** The point cloud to render */
  pointCloud: PointCloudObject | null;

  /** The optional CAD model to render or to overlay */
  cadModel: CadModelObject | null;

  /** True to enable this effect pipeline */
  enabled: boolean;
};

/**
 * @returns A simple effect pipeline to render the point cloud in a separate subscene with gap-filling
 * and everything else on top.
 */
export function SplitscreenModePipeline({
  pointCloud,
  cadModel,
  enabled,
}: SplitscreenModePipelineProps): JSX.Element {
  return (
    <EffectPipelineWithSubScenes enabled={enabled}>
      {pointCloud && <PointCloudSubscene pointCloud={pointCloud} />}
      <CadSubscene cadModel={cadModel} enabled={!!cadModel} />
      <FilteredRenderPass
        filter={(o: Object3D) =>
          !(o instanceof Points) && o.userData.type !== RenderDispatch.CadModel
        }
        clear={false}
        clearDepth={false}
      />
      <CopyToScreenPass />
    </EffectPipelineWithSubScenes>
  );
}
