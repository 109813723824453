import { FaroSwitch } from "@faro-lotv/flat-ui";
import { Box, Stack, ThemeOptions } from "@mui/material";
import { MouseEvent } from "react";

type ViewOptionSwitchProps = {
  /** Label to show next to the switch */
  label: string;

  /** Value of the switch */
  isChecked: boolean;

  /** Button to show next to the switch */
  helpButton?: JSX.Element;

  /** Callback after the option has been toggled */
  onToggled?(visible: boolean): void;
};

/** @returns a switch to select one out of two view options. The switch also contains a help menu. */
export function ViewOptionSwitch({
  label,
  isChecked,
  onToggled,
  helpButton,
}: ViewOptionSwitchProps): JSX.Element {
  return (
    <FaroSwitch
      // Put the text and the help button next to each others and before the actual switch
      label={
        <Stack direction="row" justifyContent="space-between">
          {label}
          {/** This box is used to stop the click from propagating and changing the switch value */}
          <Box
            component="div"
            sx={{
              // The zIndex is set so that it's always on top of the switch selection area
              zIndex: (theme: ThemeOptions) => theme.zIndex?.tooltip,
            }}
            onClick={(e: MouseEvent) => e.stopPropagation()}
          >
            {helpButton}
          </Box>
        </Stack>
      }
      dark
      fullWidth={true}
      checked={isChecked}
      onToggle={() => onToggled?.(!isChecked)}
    />
  );
}
