import { PointCloudObject } from "@/object-cache";
import { PointCloudAnalysis } from "@/store/point-cloud-analysis-tool-slice";
import { ColormapAnalysisRenderer } from "./colormap-analysis-renderer";

type AnalysesRendererProps = {
  /** The current active point cloud */
  pointCloud: PointCloudObject;

  /** The list of analyses to render */
  analyses: PointCloudAnalysis[];
};

/** @returns The renderer of the analyses stored in the app store */
export function AnalysesRenderer({
  analyses,
  pointCloud,
}: AnalysesRendererProps): JSX.Element {
  return (
    <>
      {analyses.map((analysis) => {
        if (analysis.parentId !== pointCloud.iElement.id) {
          return null;
        }
        return (
          <ColormapAnalysisRenderer
            key={analysis.id}
            pointCloud={pointCloud}
            analysis={analysis}
          />
        );
      })}
    </>
  );
}
