import { GUID } from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type DataPreparationUiState = {
  /** The selected entity id in the scan-tree */
  selectedEntityId?: GUID;

  /** The hovered entity id in the scan-tree */
  hoveredEntityId?: GUID;
};

export const DATA_PREPARATION_UI_INITIAL_STATE: Readonly<DataPreparationUiState> =
  Object.freeze({});

export const dataPreparationUiSlice = createSlice({
  initialState: DATA_PREPARATION_UI_INITIAL_STATE,
  name: "data-preparation-ui",

  reducers: {
    /**
     * @param state The current application state
     * @param action The entity id to select
     */
    setSelectedEntityId(state, action: PayloadAction<GUID | undefined>) {
      state.selectedEntityId = action.payload;
    },

    /**
     * @param state The current application state
     * @param action The entity id that is currently hovered
     */
    setHoveredEntityId(state, action: PayloadAction<GUID | undefined>) {
      state.hoveredEntityId = action.payload;
    },

    /**
     * @param state The current application state
     * @param action The entity id that is should be unset if it is currently hovered
     */
    unsetHoveredEntityId(state, action: PayloadAction<GUID | undefined>) {
      if (state.hoveredEntityId === action.payload) {
        state.hoveredEntityId = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => DATA_PREPARATION_UI_INITIAL_STATE);
  },
});

export const { setSelectedEntityId, setHoveredEntityId, unsetHoveredEntityId } =
  dataPreparationUiSlice.actions;

export const dataPreparationUiReducer = dataPreparationUiSlice.reducer;
