import { EventType } from "@/analytics/analytics-events";
import { CustomOrthoCamera } from "@/components/r3f/cameras/custom-ortho-camera";
import { Mode } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import {
  selectActiveAreaOrThrow,
  selectActiveElement,
} from "@/store/selections-selectors";
import { RootState } from "@/store/store";
import { deactivateTool } from "@/store/ui/ui-slice";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  addIElements,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectIElementProjectApiLocalPose,
  selectIElementWorldTransform,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert, exponentialBackOff, retry } from "@faro-lotv/foundation";
import {
  IElementImg360,
  IElementSection,
  isIElementDataSetVideoWalk,
  isIElementImg360,
  isIElementOdometryPath,
  isIElementSectionDataSession,
  isIElementVideoRecording,
} from "@faro-lotv/ielement-types";
import {
  createBaseMutation,
  createMutationSetElementPosition,
  createMutationSetElementRotation,
  createMutationSetElementScale,
  Mutation,
  MutationTypes,
  TaskResultStatus,
} from "@faro-lotv/service-wires";
import { Matrix4, Vector3 } from "three";
import { usePathAlignmentContext } from "./path-alignment-mode-context";
import { PathAlignmentModeOverlay } from "./path-alignment-mode-overlay";
import { PathAlignmentModeScene } from "./path-alignment-mode-scene";
import { PathAlignmentModeTransition } from "./path-alignment-mode-transition";
import { PathAlignmentQuickHelp } from "./path-alignment-quick-help";

export const pathAlignmentMode: Mode = {
  name: "pathAlignment",
  ModeScene: PathAlignmentModeScene,
  customCamera: CustomOrthoCamera,
  ModeOverlay: PathAlignmentModeOverlay,
  ModeTransition: PathAlignmentModeTransition,
  ModeQuickHelpDrawer: PathAlignmentQuickHelp,
  exclusive: {
    title: "Adjust Trajectory Tool",
    // eslint-disable-next-line require-await -- FIXME
    async onBack({ dispatch }) {
      Analytics.track(EventType.discardVideoModeEditorChanges);
      dispatch(deactivateTool());
      dispatch(changeMode("sheet"));
    },
    async onApply({ store, dispatch, projectApi }) {
      Analytics.track(EventType.saveVideoModeEditorChanges);
      // Extract all needed data from the app and mode state
      const { pose, positions } = usePathAlignmentContext.getState();

      if (hasOverlappingNeighbors(positions)) {
        throw new Error(
          "Anchor points overlap or zoom factor is too small. Increase the zoom factor and then re-adjust the anchor points. ",
        );
      }

      const state = store.getState();
      const videoRecording = selectVideoRecordingEditingTarget(state);

      assert(
        videoRecording,
        "Unable to update a Video Recording without an active VideoRecordings or DataSetVideoRecording node",
      );
      assert(
        videoRecording.parentId,
        "Unable to update a Video Recording without a valid Video Recording parent node",
      );

      const activeArea = selectActiveAreaOrThrow(videoRecording)(state);

      const panos = selectChildrenDepthFirst(
        videoRecording,
        isIElementImg360,
      )(state);
      if (panos.length !== positions.length) {
        throw new Error("Trajectory images do not match edited positions");
      }

      // Build all the required mutations
      const mutations: Mutation[] = [];

      // Compute the new local pose of the videoRecording node
      const videoRecordingParentTransform = selectIElementWorldTransform(
        videoRecording.parentId,
      )(state);
      const newPose = new Matrix4()
        .fromArray(pose)
        .premultiply(
          new Matrix4()
            .fromArray(videoRecordingParentTransform.worldMatrix)
            .invert(),
        );
      const { pos, rot, scale } = selectIElementProjectApiLocalPose(
        videoRecording,
        newPose,
      )(state);

      // Add the mutations to update the videoRecording pose
      mutations.push(
        createMutationSetElementPosition(videoRecording.id, pos),
        createMutationSetElementRotation(videoRecording.id, rot),
        createMutationSetElementScale(videoRecording.id, scale),
      );

      // Update the positions of all the single 360 inside the path
      const panoParent = selectChildDepthFirst(
        videoRecording,
        isIElementOdometryPath,
      )(state);
      assert(!!panoParent, "Invalid trajectory");
      const panoParentWorldMatrix = selectIElementWorldMatrix4(panoParent.id)(
        state,
      );
      const TEMP_MATRIX4 = new Matrix4();
      const TEMP_VECTOR3 = new Vector3();
      for (const [index, newPosition] of positions.entries()) {
        const pano = panos[index];

        const panoTransform = selectIElementWorldTransform(pano.id)(state);
        const localPose = TEMP_MATRIX4.fromArray(
          panoTransform.worldMatrix,
        ).premultiply(panoParentWorldMatrix);
        localPose.elements[12] = newPosition.x;
        localPose.elements[13] = newPosition.y;
        localPose.elements[14] = newPosition.z;
        const { pos } = selectIElementProjectApiLocalPose(
          pano,
          localPose,
        )(state);

        const target = TEMP_VECTOR3.set(pos.x, pos.y, pos.z);
        if (hasPositionChanged(pano, target)) {
          mutations.push(
            createMutationSetElementPosition(pano.id, {
              x: target.x,
              y: target.y,
              z: target.z,
            }),
          );
        }
      }

      // Notify the backend we updated the video mode alignment for this videoRecording
      mutations.push(
        createBaseMutation(
          MutationTypes.MutationConfirmVideoModeAlignment,
          videoRecording.id,
        ),
      );

      // Commit the new mutations using the async mutations endpoint
      // as paths may be very long the mutation can take a lot of time
      // and go over the gateway timeout
      // using the async version of the call will prevent this problem as each query will return
      // immediately
      await retry(
        async () => {
          const POLLING_DELAY_MS = 250;
          const taskId = await projectApi.applyMutationsAsync(mutations);
          let done = false;
          while (!done) {
            await new Promise((resolve) =>
              setTimeout(resolve, POLLING_DELAY_MS),
            );
            const lastStatus = await projectApi.getTaskStatus(taskId);
            if (lastStatus.status === TaskResultStatus.Failure) {
              throw new Error(
                "Saving the path to the backend failed, please try again later",
              );
            }
            done = lastStatus.status !== TaskResultStatus.Running;
          }
        },
        {
          delay: exponentialBackOff,
          max: 5,
        },
      );
      // Pull the updated area section data
      const updateArea = await projectApi.getAllIElements({
        ancestorIds: [activeArea.id],
      });

      // Update the app state
      dispatch(addIElements(updateArea));
      dispatch(deactivateTool());
      dispatch(changeMode("sheet"));
      return "Changes to trajectory correctly applied";
    },
  },
};

/**
 * @returns true if the computed new position of an img360 is different from the current one
 * @param pano the current description of the img360
 * @param newPosition the new position after user edited it
 */
function hasPositionChanged(
  pano: IElementImg360,
  newPosition: Vector3,
): boolean {
  const originalPosition = pano.pose?.pos ?? { x: 0, y: 0, z: 0 };
  return (
    newPosition.x !== originalPosition.x ||
    newPosition.y !== originalPosition.y ||
    newPosition.z !== originalPosition.z
  );
}

/**
 * @returns true if two neighbor positions of the path are exactly the same
 * @param positions of all the path points
 */
function hasOverlappingNeighbors(positions: Vector3[]): boolean {
  for (const [index, point] of positions.entries()) {
    if (index === positions.length - 1) break;
    if (point.distanceTo(positions[index + 1]) === 0) return true;
  }
  return false;
}

/**
 * Compute what element we need to store the video recording changes depending on the project structure
 *
 * For old projects we need to store the new pose to the Section(VideoRecordings) node
 * For new projects we need to store the new pose to the Section(DataSetVideoWalk) node
 *
 * @param state current application state
 * @returns the proper IElement to store the new video recording pose
 */
function selectVideoRecordingEditingTarget(
  state: RootState,
): IElementSection | undefined {
  const videoRecording = selectActiveElement(state);

  if (!videoRecording) return;

  // Handle old project structure where VideoRecording had their own elements
  if (isIElementVideoRecording(videoRecording)) {
    return videoRecording;
  }

  if (isIElementSectionDataSession(videoRecording)) {
    return selectChildDepthFirst(
      videoRecording,
      isIElementDataSetVideoWalk,
    )(state);
  }
}
