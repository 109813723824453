import { GUID } from "@faro-lotv/ielement-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

/** Define how to report the measurement between 2 points. */
export enum ComponentsToDisplay {
  /** display the 3D distance between points */
  single3d = "3D",

  /** display the height and horizontal distance component between points */
  heightAndHorizontal = "heightAndHorizontal",
}

export type Measurement = {
  /** The unique id of this measurement */
  id: GUID;

  /** The sequence of points for this multipoint measurement */
  points: Vector3Tuple[];

  /** Additional details related to the measurement */
  metadata: {
    /** Name of the measurement */
    name: string;

    /** True, if the measurement is a loop */
    isLoop: boolean;
  };

  /** The id of the iElement this measurement originated from */
  parentId: GUID;

  /** Which components to display */
  componentsToDisplay: ComponentsToDisplay;
};

type MeasurementToolState = {
  /** For each specific element, identified by its GUID, we store the list of measurements associated to it */
  measurements: Record<GUID, Measurement[]>;

  /** The id of the active (selected) measurement or undefined if none*/
  activeMeasurement?: Measurement;

  /** True if an active measurement is being carried out */
  isMeasurementBeingTaken: boolean;

  /** Number of measurement taken this session */
  sessionMeasurements: number;
};

const initialState: MeasurementToolState = {
  measurements: {},
  isMeasurementBeingTaken: false,
  sessionMeasurements: 0,
};

const measurementToolSlice = createSlice({
  name: "measurementTool",
  initialState,
  reducers: {
    addMeasurement(
      state,
      action: PayloadAction<{ elementID: GUID; measurement: Measurement }>,
    ) {
      state.sessionMeasurements++;
      if (!(action.payload.elementID in state.measurements)) {
        state.measurements[action.payload.elementID] = [];
      }

      state.measurements[action.payload.elementID].push(
        action.payload.measurement,
      );
    },
    removeMeasurement(
      state,
      action: PayloadAction<{ elementID: GUID; measurementID: GUID }>,
    ) {
      const { elementID, measurementID } = action.payload;

      if (!(elementID in state.measurements)) return;
      const measurements = state.measurements[elementID];

      state.measurements[elementID] = measurements.filter(
        (m) => m.id !== measurementID,
      );
    },
    setActiveMeasurement(
      state,
      action: PayloadAction<Measurement | undefined>,
    ) {
      state.activeMeasurement = action.payload;
    },
    setIsMeasurementBeingTaken(state, action: PayloadAction<boolean>) {
      state.isMeasurementBeingTaken = action.payload;
    },
    setIsMeasurementLoop(
      state,
      action: PayloadAction<{
        elementID: GUID;
        measurementID: GUID;
        isLoop: boolean;
      }>,
    ) {
      const { elementID, measurementID, isLoop } = action.payload;

      if (!(elementID in state.measurements)) return;

      const measurements = state.measurements[elementID];
      const measurement = measurements.find((m) => m.id === measurementID);
      if (!measurement) return;

      measurement.metadata.isLoop = isLoop;
    },
    setMeasurementComponentsToDisplay(
      state,
      action: PayloadAction<{
        elementID: GUID;
        measurementID: GUID;
        componentsToDisplay: ComponentsToDisplay;
      }>,
    ) {
      const { elementID, measurementID, componentsToDisplay } = action.payload;

      if (!(elementID in state.measurements)) return;

      const measurements = state.measurements[elementID];
      const measurement = measurements.find((m) => m.id === measurementID);
      if (!measurement) return;

      measurement.componentsToDisplay = componentsToDisplay;
    },
  },
});

export const measurementToolReducer = measurementToolSlice.reducer;

export const {
  addMeasurement,
  removeMeasurement,
  setActiveMeasurement,
  setIsMeasurementBeingTaken,
  setIsMeasurementLoop,
  setMeasurementComponentsToDisplay,
} = measurementToolSlice.actions;
