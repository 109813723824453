import {
  AlignIcon,
  AlignToModelIcon,
  AlignToPointCloudIcon,
  AlignToSheetIcon,
  CadSmallIcon,
  CameraVideoTrajectorySmallIcon,
  ClockSmallIcon,
  DeleteIcon,
  DisabledIcon,
  DownloadIcon,
  EditIcon,
  FindCheckmarkIcon,
  FolderSmallIcon,
  GeoReferenceIcon,
  Img360SmallIcon,
  InfoIcon,
  LayoutSheetSmallIcon,
  MoveElementIcon,
  NonVisibleIcon,
  PointCloudIcon,
  PointCloudImageSmallIcon,
  PointCloudSmallIcon,
  PointCloudTrajectorySmallIcon,
  RegistrationInteractiveIcon,
  ResetScaleIcon,
  ToolIcon,
  VideoCameraIcon,
  VisibleIcon,
  WorldCoordinateIcon,
} from "@faro-lotv/flat-ui";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CloudIcon from "@mui/icons-material/Cloud";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PanoramaPhotosphereSelectIcon from "@mui/icons-material/PanoramaPhotosphereSelect";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import StraightenIcon from "@mui/icons-material/Straighten";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { SxProps, Theme } from "@mui/material";
import { ElementIconType } from "./element-icon-type";

type ElementIconProps = {
  /** Type of icon to render */
  icon: ElementIconType;

  /** Custom style properties */
  sx?: SxProps<Theme>;
};

/**
 * @returns the proper Icon component for a ElementIconType
 */
export function ElementIcon({ icon, sx }: ElementIconProps): JSX.Element {
  switch (icon) {
    case ElementIconType.DisabledIcon:
      return <DisabledIcon sx={sx} />;
    case ElementIconType.ClockIcon:
      return <ClockSmallIcon sx={sx} />;
    case ElementIconType.MapIcon:
      return <LayoutSheetSmallIcon sx={sx} />;
    case ElementIconType.PanoramaIcon:
      return <Img360SmallIcon sx={sx} />;
    case ElementIconType.FolderIcon:
      return <FolderSmallIcon sx={sx} />;
    case ElementIconType.LocationOnIcon:
      return <LocationOnIcon sx={sx} />;
    case ElementIconType.PanoramaPhotosphereSelectIcon:
      return <PanoramaPhotosphereSelectIcon sx={sx} />;
    case ElementIconType.ImageIcon:
      return <ImageIcon sx={sx} />;
    case ElementIconType.ViewInArIcon:
      return <ViewInArIcon sx={sx} />;
    case ElementIconType.AccessTimeFilledIcon:
      return <AccessTimeFilledIcon sx={sx} />;
    case ElementIconType.LinkIcon:
      return <LinkIcon sx={sx} />;
    case ElementIconType.InfoIcon:
      return <InfoIcon sx={sx} />;
    case ElementIconType.VolumeUpIcon:
      return <VolumeUpIcon sx={sx} />;
    case ElementIconType.AttachmentIcon:
      return <AttachmentIcon sx={sx} />;
    case ElementIconType.SquareFootIcon:
      return <SquareFootIcon sx={sx} />;
    case ElementIconType.StraightenIcon:
      return <StraightenIcon sx={sx} />;
    case ElementIconType.CloudIcon:
      return <CloudIcon sx={sx} />;
    case ElementIconType.PointCloudIcon:
      return <PointCloudIcon sx={sx} />;
    case ElementIconType.DeleteIcon:
      return <DeleteIcon sx={sx} />;
    case ElementIconType.DownloadIcon:
      return <DownloadIcon sx={sx} />;
    case ElementIconType.AlignToPointCloudIcon:
      return <AlignToPointCloudIcon sx={sx} />;
    case ElementIconType.RegistrationInteractiveIcon:
      return <RegistrationInteractiveIcon sx={sx} />;
    case ElementIconType.AlignToSheetIcon:
      return <AlignToSheetIcon sx={sx} />;
    case ElementIconType.ToolIcon:
      return <ToolIcon sx={sx} />;
    case ElementIconType.VideoCameraIcon:
      return <VideoCameraIcon sx={sx} />;
    case ElementIconType.ResetScaleIcon:
      return <ResetScaleIcon sx={sx} />;
    case ElementIconType.DataSession:
      return <PointCloudSmallIcon sx={sx} />;
    case ElementIconType.DataSessionVideoRecording:
      return <CameraVideoTrajectorySmallIcon sx={sx} />;
    case ElementIconType.DataSessionWithPano:
      return <PointCloudImageSmallIcon sx={sx} />;
    case ElementIconType.DataSessionWithTrajectory:
      return <PointCloudTrajectorySmallIcon sx={sx} />;
    case ElementIconType.CadModel:
      return <CadSmallIcon sx={sx} />;
    case ElementIconType.NonVisible:
      return <NonVisibleIcon sx={sx} />;
    case ElementIconType.Visible:
      return <VisibleIcon sx={sx} />;
    case ElementIconType.AlignToModelIcon:
      return <AlignToModelIcon sx={sx} />;
    case ElementIconType.GeoReferenceIcon:
      return <GeoReferenceIcon sx={sx} />;
    case ElementIconType.FindCheckmarkIcon:
      return <FindCheckmarkIcon sx={sx} />;
    case ElementIconType.WorldCoordinateIcon:
      return <WorldCoordinateIcon sx={sx} />;
    case ElementIconType.EditIcon:
      return <EditIcon sx={sx} />;
    case ElementIconType.MoveElement:
      return <MoveElementIcon sx={sx} />;
    case ElementIconType.AlignIcon:
      return <AlignIcon sx={sx} />;
  }
}
