import {
  EventType,
  ToggleWaypointColoringProperties,
  ToggleWaypointHeightProperties,
  ToggleWaypointsVisibilityProperties,
} from "@/analytics/analytics-events";
import {
  useCurrentScene,
  useWaypointAltitudeRange,
} from "@/modes/mode-data-context";
import { Features } from "@/store/features/features";
import { selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  selectObjectsVisibility,
  selectShouldColorWaypoints,
  selectShouldShowWaypointsOnFloors,
} from "@/store/view-options/view-options-selectors";
import {
  ViewObjectTypes,
  setObjectVisibility,
  setShouldColorWaypoints,
  setShouldShowWaypointsOnFloors,
} from "@/store/view-options/view-options-slice";
import { FaroMenu, FaroMenuProps, FaroText, neutral } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { Stack } from "@mui/material";
import { isEqual } from "lodash";
import { useCallback } from "react";
import { PointCloudProfilesSlider } from "./point-cloud-profiles-slider";
import {
  HelpPopover,
  ShowWaypointsOnFloorsHelp,
  WaypointsElevationHelp,
} from "./view-options-popovers";
import { ViewOptionSwitch } from "./view-options-switch";
import { VisibilityDistanceRadio } from "./visibility-distance-radio";

type ViewOptionsMenuProps = Pick<
  FaroMenuProps,
  "open" | "anchorEl" | "onClose"
>;

/** @returns a PopOver to open from a menu to control the app view options */
export function ViewOptionsMenu({
  open,
  anchorEl,
  onClose,
}: ViewOptionsMenuProps): JSX.Element {
  const dispatch = useAppDispatch();

  const objectsVisibility = useAppSelector(selectObjectsVisibility, isEqual);

  const shouldColorWaypoints = useAppSelector(selectShouldColorWaypoints);
  const shouldShowWaypointsOnFloors = useAppSelector(
    selectShouldShowWaypointsOnFloors,
  );

  const updateObjectVisibility = useCallback(
    (visible: boolean, type: ViewObjectTypes, eventToTrack: EventType) => {
      Analytics.track<ToggleWaypointsVisibilityProperties>(eventToTrack, {
        visible,
      });

      dispatch(setObjectVisibility({ type, visibility: visible }));
    },
    [dispatch],
  );

  const toggleWaypointColoring = useCallback(
    (enabling: boolean) => {
      Analytics.track<ToggleWaypointColoringProperties>(
        EventType.toggleWaypointColoring,
        {
          enabling,
        },
      );

      dispatch(setShouldColorWaypoints(enabling));
    },
    [dispatch],
  );

  const toggleShouldShowWaypointsOnFloors = useCallback(
    (enabling: boolean) => {
      Analytics.track<ToggleWaypointHeightProperties>(
        EventType.toggleWaypointHeight,
        {
          enabling,
        },
      );

      dispatch(setShouldShowWaypointsOnFloors(enabling));
    },
    [dispatch],
  );

  const waypointsAltitudeRange = useWaypointAltitudeRange();

  const { panos } = useCurrentScene();
  const hasPanos = panos.length > 0;
  const shouldShowWaypointsSection = waypointsAltitudeRange ?? hasPanos;

  const hasColorMapAnalysisFeature = useAppSelector(
    selectHasFeature(Features.ColorMapAnalysis),
  );

  return (
    <FaroMenu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: -2,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      dark
    >
      <Stack gap={1} p={1} sx={{ minWidth: "291px" }}>
        <FaroText
          variant="heading12"
          color={neutral[100]}
          sx={{ textTransform: "uppercase", fontSize: "10px" }}
        >
          General View Setting
        </FaroText>
        <ViewOptionSwitch
          label="Waypoints"
          isChecked={objectsVisibility[ViewObjectTypes.waypoints]}
          onToggled={(visible) => {
            updateObjectVisibility(
              visible,
              ViewObjectTypes.waypoints,
              EventType.toggleWaypointsVisibility,
            );
          }}
        />
        <ViewOptionSwitch
          label="Annotations"
          isChecked={objectsVisibility[ViewObjectTypes.annotations]}
          onToggled={(visible) => {
            updateObjectVisibility(
              visible,
              ViewObjectTypes.annotations,
              EventType.toggleAnnotationsVisibility,
            );
          }}
        />
        <ViewOptionSwitch
          label="Measurements"
          isChecked={objectsVisibility[ViewObjectTypes.measurements]}
          onToggled={(visible) => {
            updateObjectVisibility(
              visible,
              ViewObjectTypes.measurements,
              EventType.toggleMeasurementsVisibility,
            );
          }}
        />
        <ViewOptionSwitch
          label="Trajectories"
          isChecked={objectsVisibility[ViewObjectTypes.trajectories]}
          onToggled={(visible) => {
            updateObjectVisibility(
              visible,
              ViewObjectTypes.trajectories,
              EventType.toggleTrajectoriesVisibility,
            );
          }}
        />
        {hasColorMapAnalysisFeature && (
          <ViewOptionSwitch
            label="Analyses"
            isChecked={objectsVisibility[ViewObjectTypes.analyses]}
            onToggled={(visible) => {
              updateObjectVisibility(
                visible,
                ViewObjectTypes.analyses,
                EventType.toggleAnalysesVisibility,
              );
            }}
          />
        )}
        {shouldShowWaypointsSection && (
          <>
            <FaroText variant="heading12" color={neutral[100]}>
              Waypoints elevation
            </FaroText>
            {waypointsAltitudeRange && (
              <ViewOptionSwitch
                label="Color waypoints by elevation"
                isChecked={shouldColorWaypoints}
                onToggled={toggleWaypointColoring}
                helpButton={
                  <WaypointsElevationHelp range={waypointsAltitudeRange} />
                }
              />
            )}
            {hasPanos && (
              <ViewOptionSwitch
                label="Show waypoints on floors"
                isChecked={shouldShowWaypointsOnFloors}
                onToggled={toggleShouldShowWaypointsOnFloors}
                helpButton={<ShowWaypointsOnFloorsHelp />}
              />
            )}
          </>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <FaroText variant="heading12" color={neutral[100]}>
            Object visibility
          </FaroText>

          <HelpPopover
            title="Object Visibility"
            description="Configure the distance at which objects become visible in the current 3D scene"
            onClick={() => {
              Analytics.track(EventType.openObjectVisibilityHelp);
            }}
          />
        </Stack>
        <VisibilityDistanceRadio />
        <PointCloudProfilesSlider />
      </Stack>
    </FaroMenu>
  );
}
