import { MultiPointRenderer } from "@/components/r3f/renderers/measurements/multi-point-measure-renderer";
import { TwoPointMeasureSegment } from "@/components/r3f/renderers/measurements/two-point-segment-renderer";
import { useProjectUnitOfMeasure } from "@/hooks/use-unit-of-measure";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { PointCloudObject } from "@/object-cache";
import {
  addAnalysis,
  setActiveAnalysis,
  setIsAnalysisBeingCreated,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { deactivateTool, ToolName } from "@/store/ui/ui-slice";
import { assert, generateGUID } from "@faro-lotv/foundation";
import { ThreeEvent } from "@react-three/fiber";
import { forwardRef, useCallback, useRef, useState } from "react";
import { MOUSE, Vector3 } from "three";
import {
  MultiPointMeasureControls,
  MultiPointMeasureControlsActions,
} from "./multi-point-measures/multi-point-measures-controls";
import { ToolControlsRef } from "./tool-controls-interface";

type PointCloudAnalysisToolProps = {
  /** The active point cloud object */
  activePointCloud: PointCloudObject;
};

export const PointCloudAnalysisTool = forwardRef<
  ToolControlsRef,
  PointCloudAnalysisToolProps
>(function PointCloudAnalysisTool(
  { activePointCloud }: PointCloudAnalysisToolProps,
  ref,
): JSX.Element {
  const dispatch = useAppDispatch();

  const [points, setPoints] = useState<Vector3[]>();
  const onPointsChanged = useCallback((points: Vector3[] | undefined) => {
    setPoints(points);
  }, []);

  const [currentPoint, setCurrentPoint] = useState<Vector3>();
  const onCurrentPointChanged = useCallback((point: Vector3 | undefined) => {
    setCurrentPoint(point ? point.clone() : undefined);
  }, []);

  const onAnalysisCompleted = useCallback(
    (isClosed: boolean, iElementId: string) => {
      assert(isClosed, "PointCloudAnalysisTool only accept closed polygon");
      if (!points) return;
      if (activePointCloud.iElement.id !== iElementId) return;

      // create new analysis
      const analysis = {
        id: generateGUID(),
        polygonSelection: points.map((p) => p.toArray()),
        parentId: iElementId,
      };
      dispatch(
        addAnalysis({
          pointCloudID: iElementId,
          analysis,
        }),
      );

      setPoints(undefined);

      // set the new analysis as active
      dispatch(setActiveAnalysis(analysis));
      dispatch(setIsAnalysisBeingCreated(false));

      // Deactivate the tool after the analysis is completed.
      dispatch(deactivateTool());
    },
    [activePointCloud.iElement.id, dispatch, points],
  );

  const onAnalysisStarted = useCallback(() => {
    dispatch(setIsAnalysisBeingCreated(true));
  }, [dispatch]);

  const onAnalysisCanceled = useCallback(() => {
    setPoints(undefined);
    dispatch(setIsAnalysisBeingCreated(false));
  }, [dispatch]);

  const { unitOfMeasure } = useProjectUnitOfMeasure();
  const controlActions = useRef<MultiPointMeasureControlsActions>(null);
  const onHandlerClicked = useCallback(
    (ev: ThreeEvent<MouseEvent>, index: number) => {
      if (!points) return;
      if (index < 0 || index >= points.length) return;
      if (points.length < 3) return;
      if (ev.button !== MOUSE.LEFT) return;

      if (index === 0) {
        controlActions.current?.completeMeasurement(true);
        ev.stopPropagation();
      }
    },
    [points],
  );

  const labelContainer = useViewOverlayRef();

  const dashLine = (start: Vector3, end: Vector3): JSX.Element => {
    return (
      <TwoPointMeasureSegment
        visible={true}
        start={start}
        end={end}
        labelPosition={start}
        length={start.distanceTo(end)}
        index={0}
        main={true}
        live={false}
        isMeasurementActive={undefined}
        isLabelActive={true}
        labelContainer={labelContainer}
        unitOfMeasure={unitOfMeasure}
        onClick={() => {}}
        dashed={true}
        labelsPointerEvents="none"
        isLabelVisible={false}
      />
    );
  };
  const analysisActive = useAppSelector(selectActiveTool) === ToolName.analysis;

  return (
    <>
      {points &&
        points.length >= 1 &&
        currentPoint &&
        dashLine(currentPoint, points[0])}
      {points &&
        points.length >= 2 &&
        currentPoint &&
        dashLine(currentPoint, points[points.length - 1])}
      {points && (
        <MultiPointRenderer
          points={points}
          live={true}
          isClosed={false}
          isLabelActive={false}
          visible={true}
          unitOfMeasure={unitOfMeasure}
          onToggleUnitOfMeasure={() => {}}
          onHandlerClicked={onHandlerClicked}
          showActionBar={false}
          isMeasurementActive={undefined}
          labelContainer={labelContainer}
          labelsPointerEvents={undefined}
          isLabelVisible={false}
        />
      )}
      <MultiPointMeasureControls
        onPointsChanged={onPointsChanged}
        onCurrentPointChanged={onCurrentPointChanged}
        onMeasurementCompleted={onAnalysisCompleted}
        onDeleteActiveMeasurement={() => {}}
        onMeasurementStarted={onAnalysisStarted}
        onEscPressed={onAnalysisCanceled}
        onMeasurementCanceled={onAnalysisCanceled}
        ref={ref}
        actions={controlActions}
        active={analysisActive}
      />
    </>
  );
});
