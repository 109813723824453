import { EventType } from "@/analytics/analytics-events";
import { CustomOrthoCamera } from "@/components/r3f/cameras/custom-ortho-camera";
import { Mode } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import { deactivateTool } from "@/store/ui/ui-slice";
import { addIElements } from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { createMutationSetElementScale } from "@faro-lotv/service-wires";
import { useFloorScaleContext } from "./floor-scale-mode-context";
import { FloorScaleModeOverlay } from "./floor-scale-mode-overlay";
import { FloorScaleModeScene } from "./floor-scale-mode-scene";
import { FloorScaleModeTransition } from "./floor-scale-mode-transition";

export const floorScaleMode: Mode = {
  name: "floorscale",
  ModeScene: FloorScaleModeScene,
  ModeOverlay: FloorScaleModeOverlay,
  ModeTransition: FloorScaleModeTransition,
  customCamera: CustomOrthoCamera,
  exclusive: {
    title: "Set sheet scale",
    // eslint-disable-next-line require-await -- FIXME
    async onBack({ dispatch }) {
      Analytics.track(EventType.discardAreaScale);
      dispatch(changeMode("sheet"));
      dispatch(deactivateTool());
    },
    async onApply({ projectApi, dispatch }) {
      Analytics.track(EventType.applyAreaScale);
      const { sheet, area, measuredDistance, userDefinedDistance } =
        useFloorScaleContext.getState();
      if (
        !sheet ||
        !area ||
        measuredDistance === undefined ||
        userDefinedDistance === undefined
      ) {
        return;
      }

      const scale = userDefinedDistance / measuredDistance;
      const areaScale = area.pose?.scale ?? { x: 1, y: 1, z: 1 };
      const mutation = createMutationSetElementScale(area.id, {
        x: areaScale.x * scale,
        y: areaScale.y * scale,
        z: areaScale.z * scale,
      });
      const result = (await projectApi.applyMutations([mutation]))[0];

      if (result.status === "failure") {
        throw new Error("Unable to set area scale");
      }

      // Pull the updated area section data
      const updateArea = await projectApi.getAllIElements({
        ancestorIds: [area.id],
      });

      // Update the app state
      dispatch(addIElements(updateArea));
      dispatch(deactivateTool());
      dispatch(changeMode("sheet"));

      return `Scale of ${sheet.name} applied successfully`;
    },
  },
};
