import { ComponentsToDisplay } from "@/store/measurement-tool-slice";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { HtmlProps } from "@react-three/drei/web/Html";
import { MutableRefObject, useMemo, useState } from "react";
import { Material, Matrix3, Matrix4, Vector3 } from "three";
import { SegmentData } from "./measure-types";
import { computeMeasurementInWorld } from "./measure-utils";
import { TwoPointMeasureSegment } from "./two-point-segment-renderer";

type MultiSegmentRendererProps = {
  /** The list of points describing the polyline local to the reference system defined by the `worldMatrix` */
  points: Vector3[];
  /** A flag specifying if the polyline is closed */
  isClosed: boolean;
  /** A flag specifying if the measurement is the current active one */
  isActive: boolean;
  /** A ref to the parent HTML element to which all labels will be attached */
  labelContainer: MutableRefObject<HTMLElement>;
  /** The HTML property for the pointer events on the HTML labels */
  labelsPointerEvents: HtmlProps["pointerEvents"];
  /** The unit of measure used to display the measurement */
  unitOfMeasure: SupportedUnitsOfMeasure;
  /** The world matrix of the whole measurement */
  worldMatrix: Matrix4;
  /** A flag for making dashed lines */
  dashed?: boolean;
  /** Whether depth testing should be used to render the segment */
  depthTest?: Material["depthTest"];
  /** Callback executed when a label is clicked */
  onClick?(): void;
  /** True if the label should be rendered */
  isLabelVisible: boolean;
  /** Component to display for 2-points line */
  componentsToDisplay?: ComponentsToDisplay;
};

/** @returns A component to render a polyline that, optionally, could be closed, where each segment have a label showing the length */
export function MultiSegmentRenderer({
  points,
  isClosed,
  isActive,
  labelContainer,
  labelsPointerEvents,
  unitOfMeasure,
  worldMatrix,
  dashed,
  depthTest,
  onClick,
  isLabelVisible,
  componentsToDisplay,
}: MultiSegmentRendererProps): JSX.Element {
  // Matrix to convert from points local coordinates system to world
  const matrix = useMemo(
    () => new Matrix3().setFromMatrix4(worldMatrix),
    [worldMatrix],
  );

  const [TEMP_VEC3] = useState(() => new Vector3());

  let listSegments: SegmentData[] = [];
  if (
    points.length !== 2 ||
    componentsToDisplay !== ComponentsToDisplay.heightAndHorizontal
  ) {
    // get one single measurement (for 3D distance) for each segment in the polyline
    listSegments = points.flatMap((p1, i) => {
      if (!isClosed && i === points.length - 1) return [];
      const nextIndex = (i + 1) % points.length;
      const p2 = points[nextIndex];
      return [
        {
          start: p1,
          end: p2,
          length: TEMP_VEC3.subVectors(p2, p1).applyMatrix3(matrix).length(),
          labelPosition: new Vector3().addVectors(p1, p2).multiplyScalar(0.5),
          visible: true,
        },
      ];
    });
  } else {
    // get 2 measurements (one for each components) for the single line
    const p1 = points[0];
    const p2 = points[1];
    const labelPosition = new Vector3().addVectors(p1, p2).multiplyScalar(0.5);
    const measurementsInWorld = computeMeasurementInWorld(
      p1,
      p2,
      labelPosition,
    );
    listSegments = [
      measurementsInWorld.components.vertical,
      measurementsInWorld.components.horizontal,
    ];
  }

  return (
    <>
      {listSegments.map((segment, i) => {
        return (
          <TwoPointMeasureSegment
            key={i}
            main={true}
            start={segment.start}
            end={segment.end}
            length={segment.length}
            index={0}
            live={false}
            visible={true}
            isMeasurementActive={isActive}
            isLabelActive={isActive}
            labelContainer={labelContainer}
            labelsPointerEvents={labelsPointerEvents}
            unitOfMeasure={unitOfMeasure}
            onClick={() => onClick?.()}
            dashed={dashed}
            depthTest={depthTest}
            labelPosition={segment.labelPosition}
            prefix={segment.prefix}
            isLabelVisible={isLabelVisible}
          />
        );
      })}
    </>
  );
}
