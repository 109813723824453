import { useObjectView } from "@/hooks/use-object-view";
import { PanoObject, useCached3DObject } from "@/object-cache";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import { usePanoCameraTransform } from "@/utils/camera-transform";
import {
  Img360PanoBase,
  UPDATE_CONTROLS_PRIORITY,
  selectIElementChildren,
  selectIElementWorldPosition,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import {
  IElement,
  IElementGenericImgSheet,
  IElementImg360,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import {
  DEFAULT_PERSP_FOV,
  ObjectPropertyAnimation,
  SequenceAnimation,
  VoidAnimation,
} from "@faro-lotv/lotv";
import { useFrame, useThree } from "@react-three/fiber";
import { useMemo, useReducer, useRef } from "react";
import { Group, Matrix4, Quaternion, Vector3 } from "three";
import { SnapshotRenderer } from "../renderers/snapshot-renderer";
import { CameraAnimation } from "./camera-animation";

/** A reusable rotation Matrix4 */
const ROTATION_MATRIX = new Matrix4();

/** A Vector3 indicating y-up */
const Y_UP = new Vector3(0, 1, 0);

/** A reuseable vector used to hold the target position */
const TARGET_POSITION = new Vector3();

/** A reusable vector which indicates where the camera should look at */
const TARGET_LOOK_AT = new Vector3();

/** A reusable quaternion used to hold final rotation of the pano */
const ROTATION = new Quaternion();

/** A reusable vector which holds the camera world position */
const CAMERA_WORLD_POSITION = new Vector3();

/**
 * @returns the rotation of the given Img360 element only if it's in the odometric path else undefined
 * @param pano Img360 element whose rotation needs to be calculated if it is inside the odometric path
 */
function selectCameraRotationForOdometricPathPano(pano: IElementImg360) {
  return (state: RootState): Quaternion | undefined => {
    if (pano.typeHint !== IElementTypeHint.odometryPath || !pano.parentId) {
      return;
    }
    // All the panos in the odometric path
    const panos = selectIElementChildren(pano.parentId)(state);

    const panoIndex = panos.findIndex((elem) => elem.id === pano.id);
    if (panoIndex === -1) return;

    const nextPanoIndex = Math.min(panos.length - 1, panoIndex + 1);
    const prevPanoIndex = Math.max(0, panoIndex - 1);

    const isLastPano = panoIndex === nextPanoIndex;

    TARGET_POSITION.fromArray(
      selectIElementWorldPosition(
        isLastPano ? panos[prevPanoIndex].id : pano.id,
      )(state),
    );

    TARGET_LOOK_AT.fromArray(
      selectIElementWorldPosition(
        isLastPano ? pano.id : panos[nextPanoIndex].id,
      )(state),
    );

    return ROTATION.setFromRotationMatrix(
      ROTATION_MATRIX.lookAt(TARGET_POSITION, TARGET_LOOK_AT, Y_UP),
    );
  };
}

export type ToPanoAnimationProps = {
  /** The id of the pano we want to navigate to */
  panoElement: IElementImg360;

  /** Current active img sheet */
  sheet?: IElementGenericImgSheet;

  /** Set to true to allow the animation to rotate the camera */
  shouldRotateCamera?: boolean;

  /** The move duration in seconds*/
  duration: number;

  /**
   * Define to true to register this as a secondary view for split screen rendering
   *
   * @default false
   */
  isSecondaryView?: boolean;

  /** Callback to signal this animation is completed */
  onAnimationFinished?(pano: PanoObject): void;

  /** Optional element to orient the camera towards */
  lookAt?: IElement;
};

/**
 * @returns a transition to go from a 3d scene to a panorama image
 */
export function ToPanoAnimation({
  panoElement,
  sheet,
  shouldRotateCamera = true,
  duration,
  isSecondaryView = false,
  onAnimationFinished,
  lookAt,
}: ToPanoAnimationProps): JSX.Element {
  const camera = useThree((s) => s.camera);
  const pano = useCached3DObject(panoElement);
  const targetPose = usePanoCameraTransform(panoElement, sheet);
  // Use a pano view for the animation so we don't steal it if it's used
  // in a different view in split screen
  const targetView = useObjectView(pano);
  const targetToUse = isSecondaryView ? targetView : pano;

  // Selecting the pano original position, that is different from
  // the position in 'targetPose', since the latter has a fixed height
  // from the floorplan
  const panoOrigPos = useAppSelector(
    selectIElementWorldPosition(panoElement.id),
  );

  // Selecting the original position of the annotation to look at, if it exists
  const lookAtPos = useAppSelector(selectIElementWorldPosition(lookAt?.id));

  const cameraRotationForOdometricPathPano = useAppSelector(
    selectCameraRotationForOdometricPathPano(panoElement),
  );

  const rotation = useMemo(() => {
    if (!shouldRotateCamera) {
      return camera.quaternion;
    }
    if (cameraRotationForOdometricPathPano) {
      return cameraRotationForOdometricPathPano;
    }

    TARGET_POSITION.fromArray(targetPose.position);

    if (lookAt) {
      const diff = new Vector3(
        lookAtPos[0] - panoOrigPos[0],
        lookAtPos[1] - panoOrigPos[1],
        lookAtPos[2] - panoOrigPos[2],
      );
      TARGET_LOOK_AT.addVectors(diff, TARGET_POSITION);
    } else {
      TARGET_LOOK_AT.subVectors(
        TARGET_POSITION,
        camera.getWorldPosition(CAMERA_WORLD_POSITION),
      );
      TARGET_LOOK_AT.y = 0;
      TARGET_LOOK_AT.add(TARGET_POSITION);
    }
    return ROTATION.setFromRotationMatrix(
      ROTATION_MATRIX.lookAt(TARGET_POSITION, TARGET_LOOK_AT, Y_UP),
    );
  }, [
    cameraRotationForOdometricPathPano,
    targetPose.position,
    shouldRotateCamera,
    camera,
    lookAt,
    lookAtPos,
    panoOrigPos,
  ]);

  const [isPanoAnimationFinished, onPanoAnimationFinished] = useReducer(
    () => true,
    false,
  );

  const [isCameraAnimationFinished, onCameraAnimationFinished] = useReducer(
    () => true,
    false,
  );

  const group = useRef<Group>(null);
  const animation = useMemo(() => {
    const OPACITY_ANIMATION_DURATION = 0.25;
    targetToUse.opacity = 0;
    const panoAnimation = new SequenceAnimation([
      new VoidAnimation(Math.max(0, duration - OPACITY_ANIMATION_DURATION)),
      new ObjectPropertyAnimation(targetToUse, "opacity", 0, 1, {
        duration: OPACITY_ANIMATION_DURATION,
      }),
    ]);
    panoAnimation.completed.on(onPanoAnimationFinished);
    return panoAnimation;
  }, [targetToUse, duration]);

  // use non-exhaustive effect to make sure the callback only runs once
  useNonExhaustiveEffect(() => {
    if (isPanoAnimationFinished && isCameraAnimationFinished) {
      onAnimationFinished?.(pano);
    }
  }, [isCameraAnimationFinished, isPanoAnimationFinished]);

  useFrame((_, delta) => {
    animation.startIfNeeded();
    animation.update(delta);
    if (!group.current) return;
    group.current.position.copy(camera.position);
    // The animation is moving the camera, let's do it with UPDATE_CONTROLS_PRIORITY
    // so that camera monitoring and LOD visibility computations go after this.
  }, UPDATE_CONTROLS_PRIORITY);

  return (
    <>
      {!isCameraAnimationFinished && <SnapshotRenderer />}
      <group ref={group}>
        <Img360PanoBase
          {...{ quaternion: targetPose.quaternion }}
          pano={targetToUse}
        />
      </group>
      <CameraAnimation
        position={targetPose.position}
        quaternion={rotation}
        fov={DEFAULT_PERSP_FOV}
        duration={duration}
        onAnimationFinished={onCameraAnimationFinished}
      />
    </>
  );
}
