import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import {
  FaroDialog,
  FaroRadio,
  FaroRadioGroup,
  FaroSlider,
  FaroText,
} from "@faro-lotv/app-component-toolbox";
import { PointCloudFormat } from "@faro-lotv/service-wires";
import {
  ButtonBaseActions,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useRef, useState } from "react";

/**
 * List of possible point cloud densities for the export.
 * The value is the distance between points in mm.
 * The values are ordered from the lowest to the highest density.
 */
const POINTCLOUD_DENSITY = [
  {
    value: 2,
  },
  {
    value: 4,
  },
  {
    value: 8,
  },
  {
    value: 16,
  },
  {
    value: 32,
  },
  {
    value: 64,
  },
];

export type ClippingBoxEportDialogProps = {
  /** True to open the dialog */
  open: boolean;

  /** True to disable interaction on the dialog elements */
  disabled: boolean;

  /** True to show a spinner inside the accept button */
  showSpinner: boolean;

  /** List of PointCloudFormat the user can choose from */
  formats: PointCloudFormat[];

  /** Current volume to export in cubic meters */
  exportVolume: number;

  /** Callback called when the user cancel the dialog */
  onClose(): void;

  /** Callback called when the user confirm the export selection */
  onConfirm(format: string): void;
};

/** @returns the dialog to select what format to use to export a PointCloud SubVolume */
export function ClippingBoxExportDialog({
  open,
  disabled,
  showSpinner,
  formats,
  exportVolume,
  onClose,
  onConfirm,
}: ClippingBoxEportDialogProps): JSX.Element | null {
  const [chosenFormat, setChosenFormat] = useState<string>();
  const cancelButtonActions = useRef<ButtonBaseActions>(null);
  const handleClose = useCallback(
    (_: unknown, reason: string) => {
      if (cancelButtonActions.current && reason === "backdropClick") {
        cancelButtonActions.current.focusVisible();
      } else {
        onClose();
      }
    },
    [onClose],
  );

  const shouldShowResolutionSelector = useAppSelector(
    selectHasFeature(Features.ExportResolution),
  );

  return (
    <FaroDialog
      title="Export Volume Format"
      open={open}
      onConfirm={() => {
        if (chosenFormat) {
          onConfirm(chosenFormat);
        }
      }}
      onCancel={onClose}
      onClose={handleClose}
      showSpinner={showSpinner}
      disabled={disabled}
      cancelButtonActions={cancelButtonActions}
      isConfirmDisabled={!chosenFormat}
      confirmText="Export"
      dark
      sx={{
        // Override the overflow of the dialog to allow the slider to be fully visible without a scrollbar
        // and not be cropped by the dialog.
        "> .MuiDialog-container > .MuiPaper-root > *": {
          overflow: "visible",
          "> .MuiDialogContent-root": {
            overflow: "visible",
          },
        },
      }}
    >
      <Stack gap={3}>
        <Typography>Select the file format to export your volume</Typography>
        <FaroRadioGroup onChange={(v) => setChosenFormat(v.target.value)}>
          {formats.map((format) => (
            <FormControlLabel
              key={format.name}
              value={format.name}
              disabled={
                !!format.volumeLimit && format.volumeLimit < exportVolume
              }
              control={<FaroRadio dark />}
              label={
                <FaroText variant="bodyL" dark>
                  {computeExportLabel(format, exportVolume)}
                </FaroText>
              }
              aria-label={format.displayName}
              sx={{ m: 0 }}
            />
          ))}
        </FaroRadioGroup>
      </Stack>
      {shouldShowResolutionSelector && (
        <Stack marginTop={3}>
          <FaroText dark variant="heading14">
            Maximum distance between points
          </FaroText>
          <FaroSlider
            dark
            marks={POINTCLOUD_DENSITY}
            step={null}
            min={POINTCLOUD_DENSITY[0].value}
            max={POINTCLOUD_DENSITY[POINTCLOUD_DENSITY.length - 1].value}
            valueLabelDisplay="auto"
          />
          <Stack direction="row" justifyContent="space-between">
            <FaroText dark variant="placeholder">
              2 mm
            </FaroText>
            <FaroText dark variant="placeholder">
              64 mm
            </FaroText>
          </Stack>
        </Stack>
      )}
    </FaroDialog>
  );
}

/**
 * Compute the label for an export taking into account possible limits of the exports
 *
 * @param format for the export
 * @param exportVolume selected by the user
 * @returns the label for the radio selection, with an additional warning message if needed
 */
function computeExportLabel(
  format: PointCloudFormat,
  exportVolume: number,
): string {
  if (!!format.volumeLimit && format.volumeLimit < exportVolume) {
    const current = Math.ceil(exportVolume);
    const limit = Math.floor(format.volumeLimit);
    return `${format.displayName} unavailable - the current selected volume of ${current} m\u00B3 is higher than the exportable volume of ${limit} m\u00B3`;
  }
  return format.displayName;
}
