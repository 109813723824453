import { SheetModeRenderOrders } from "@/modes/sheet-mode/sheet-mode-render-orders";
import { useAppSelector } from "@/store/store-hooks";
import {
  MapUserMarker as MapUserMarkerRenderer,
  selectIElementWorldTransform,
  UserLocation,
  UserLocationNoFov,
  useSvg,
} from "@faro-lotv/app-component-toolbox";
import { IElementGenericImgSheet } from "@faro-lotv/ielement-types";
import { Camera } from "three";

export type MapUserRendererProps = {
  /** the camera that represents the user */
  userCamera: Camera;

  /** Current sheet rendered that contains this pano */
  sheet?: IElementGenericImgSheet;

  /** True if the view cone of the user marker should be visible */
  shouldShowViewCone: boolean;
};

/**
 * @returns A renderer for placeholders for floorplans and panorama images
 */
export function MapUserMarker({
  userCamera,
  sheet,
  shouldShowViewCone,
}: MapUserRendererProps): JSX.Element {
  const pos = useAppSelector(selectIElementWorldTransform(sheet?.id));

  const texture = useSvg(
    shouldShowViewCone ? UserLocation : UserLocationNoFov,
    512,
    512,
  );

  return (
    <MapUserMarkerRenderer
      userCamera={userCamera}
      sheetPosition={pos.position}
      texture={texture}
      renderOrder={SheetModeRenderOrders.MinimapUserMarker}
    />
  );
}
