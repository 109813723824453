import {
  CopyMeasurementToClipboardEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { useSvg } from "@faro-lotv/app-component-toolbox";
import { LineHandler } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { AnimationManager, ObjectPropertyAnimation } from "@faro-lotv/lotv";
import { useTheme } from "@mui/material";
import { Vector3 as Vector3Prop, useFrame } from "@react-three/fiber";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Group, Vector3 } from "three";
import { MeasureActionBar } from "./measure-action-bar";
import {
  BIG_HANDLER_SIZE,
  MEASURE_ANIMATION_LENGTH,
  SMALL_HANDLER_SIZE,
} from "./measure-constants";
import { HandlerRenderer } from "./measure-handler";
import { Measurement } from "./measure-types";
import {
  computeMeasurement,
  copyMeasurementToClipboard,
  updateComponentsVisibility,
  useCameraSpaceTopMostPoint,
  useLabelScreenPositionComputer,
} from "./measure-utils";
import { TwoPointMeasureSegment } from "./two-point-segment-renderer";

export type ExpandedMeasureRendererProps = {
  /** True to show the expanded measurement, will animate on change */
  visible: boolean;

  /** Reference position in space for this measure, where it will collapse on hiding */
  position: Vector3;

  /** The measurement starting point in world space */
  firstPoint: Vector3Prop;

  /** The measurement ending point in world space */
  secondPoint: Vector3Prop;

  /** True if the measurement tool is active */
  isToolActive: boolean;

  /** Element where to place the measurement labels */
  labelContainer: MutableRefObject<HTMLElement>;

  /** Make the segment dashed */
  dashed?: boolean;

  /** True if this is the active measurement */
  active?: boolean;

  /** True if the user is live editing this measurement */
  live?: boolean;

  /** True if the label of the main axis should be visible */
  isMainLabelVisible?: boolean;

  /** True if the labels of the decomposition axes should be visible */
  areXYZLabelsVisible?: boolean;

  /** Unit of measure to use for the labels */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** True if the pointer events should be disabled for this measurement */
  disablePointerEvents?: boolean;

  /** Callback called when the user wants to toggle the unit of measure */
  onToggleUnitOfMeasure(): void;

  /** Callback called when the user wants to delete this measurement */
  onDeleteActiveMeasurement?(): void;

  /** Callback called when the user switch between 3D and components for this measurement */
  onToggleMeasurementComponentsToDisplay?(): void;

  /** Callback called when one of this measurements labels is clicked */
  onClick?(): void;
};

/** @returns the group of elements to represent a measurement when expanded */
export function ExpandedMeasureRenderer({
  visible,
  position,
  firstPoint,
  secondPoint,
  labelContainer,
  dashed,
  active,
  unitOfMeasure,
  disablePointerEvents,
  live,
  isMainLabelVisible,
  areXYZLabelsVisible,
  onToggleUnitOfMeasure,
  onDeleteActiveMeasurement,
  onToggleMeasurementComponentsToDisplay,
  onClick,
}: ExpandedMeasureRendererProps): JSX.Element | null {
  const theme = useTheme();

  const labelsPointerEvents = disablePointerEvents ? "none" : "auto";

  const [measurement, setMeasurement] = useState<Measurement>();
  /**
   * This 'useEffect' hook guarantees that whenever the start or end points
   * of the measurement change, the start and end points of the X, Y, and Z
   * components are correctly updated, along with the positions of the distance
   * labels and the distance values themselves.
   */
  useEffect(() => {
    const measurements = computeMeasurement(firstPoint, secondPoint, position);
    updateComponentsVisibility(measurements);
    setMeasurement(measurements);
  }, [firstPoint, secondPoint, position]);

  const topMostPoint = useCameraSpaceTopMostPoint(measurement);

  const labelsScreenPositionsComputer = useLabelScreenPositionComputer(
    measurement,
    labelContainer.current,
    !!live,
    topMostPoint,
  );

  const [activeLabel, setActiveLabel] = useState<number>();

  useEffect(() => {
    if (!active) setActiveLabel(undefined);
  }, [active]);

  const onLabelClick = useCallback(
    (id: number) => {
      onClick?.();
      setActiveLabel(id);
    },
    [onClick],
  );

  /** Callback to use when the copy to clipboard action is triggered in the action bar. */
  const onCopyToClipboard = useCallback(() => {
    if (!measurement) return;
    Analytics.track<CopyMeasurementToClipboardEventProperties>(
      EventType.copyMeasurementToClipboard,
      {
        via: "action bar",
      },
    );
    copyMeasurementToClipboard(measurement, unitOfMeasure);
  }, [measurement, unitOfMeasure]);

  // The following code handles the expand/collapse animation for the measurement 3d objects
  // they're all placed inside a single group positioned in the measurement reference position
  // the animation will work on this group scale to give the expand/collapse effect
  const groupRef = useRef<Group>(null);
  const animationManager = useMemo(() => new AnimationManager(), []);
  useFrame((_, delta) => {
    animationManager.update(delta);
  }, 0);
  useEffect(() => {
    if (!groupRef.current) return;
    const anim = new ObjectPropertyAnimation(
      groupRef.current,
      "scale",
      groupRef.current.scale,
      visible ? new Vector3(1, 1, 1) : new Vector3(0, 0, 0),
      {
        duration: MEASURE_ANIMATION_LENGTH,
      },
    );
    animationManager.run(anim);

    return () => {
      anim.cancel();
    };
  }, [animationManager, visible]);

  const handlerTexture = useSvg(LineHandler);

  if (!measurement) {
    return null;
  }

  return (
    <group position={position} ref={groupRef}>
      <HandlerRenderer
        position={measurement.main.end}
        size={active ? BIG_HANDLER_SIZE : SMALL_HANDLER_SIZE}
        color={active ? theme.palette.magenta : theme.palette.white}
        texture={handlerTexture}
      />
      {topMostPoint && (
        <MeasureActionBar
          anchorPoint={topMostPoint}
          isActive={active ?? false}
          parentRef={labelContainer}
          unitOfMeasure={unitOfMeasure}
          onDeleteMeasurement={onDeleteActiveMeasurement}
          onCopyToClipboard={onCopyToClipboard}
          onToggleUnitOfMeasure={onToggleUnitOfMeasure}
          onToggleMeasurementComponentsToDisplay={
            onToggleMeasurementComponentsToDisplay
          }
        />
      )}
      <TwoPointMeasureSegment
        {...measurement.main}
        main
        index={0}
        live={live}
        visible={measurement.main.visible && visible}
        isMeasurementActive={active}
        isLabelActive={activeLabel === 0}
        labelContainer={labelContainer}
        isLabelVisible={isMainLabelVisible}
        dashed={dashed}
        labelsScreenPositionsComputer={labelsScreenPositionsComputer}
        labelsPointerEvents={labelsPointerEvents}
        unitOfMeasure={unitOfMeasure}
        onClick={onLabelClick}
      />
      {Object.values(measurement.components).map((component, index) => {
        const isVisible = (!!active || !!live) && component.visible && visible;
        return (
          <TwoPointMeasureSegment
            {...component}
            key={index + 1}
            index={index + 1}
            live={live}
            visible={isVisible}
            isMeasurementActive={active}
            isLabelActive={activeLabel === index + 1}
            isLabelVisible={areXYZLabelsVisible}
            labelContainer={labelContainer}
            labelsScreenPositionsComputer={labelsScreenPositionsComputer}
            labelsPointerEvents={labelsPointerEvents}
            unitOfMeasure={unitOfMeasure}
            onClick={onLabelClick}
          />
        );
      })}
    </group>
  );
}
