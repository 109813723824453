import {
  PointCloudRenderingProfile,
  useRenderingSettings,
} from "@/components/common/rendering-settings-context";
import { FaroSlider, FaroText, neutral } from "@faro-lotv/flat-ui";
import { Box, Stack } from "@mui/material";
import { useMemo } from "react";
import { HelpPopover } from "./view-options-popovers";

/** @returns a slider to select one out of four point cloud rendering profiles */
export function PointCloudProfilesSlider(): JSX.Element {
  const { pointCloudRenderingProfile, setPointCloudRenderingProfile } =
    useRenderingSettings();

  const sliderValue = useMemo(() => {
    switch (pointCloudRenderingProfile) {
      case PointCloudRenderingProfile.SmoothSilk:
        return 1;
      case PointCloudRenderingProfile.Smooth:
        return 2;
      case PointCloudRenderingProfile.Sharp:
        return 3;
      case PointCloudRenderingProfile.Sharpest:
        return 4;
    }
  }, [pointCloudRenderingProfile]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <FaroText variant="heading12" color={neutral[100]}>
          Point cloud rendering
        </FaroText>
        <HelpPopover
          title="POINT CLOUD RENDERING OPTIONS"
          description={<ProfilesHelpText />}
          // TODO: SWEB-5338 add analytics for the profiles slider
          // onClick={() => {
          //   Analytics.track(EventType.openObjectVisibilityHelp);
          // }}
        />
      </Stack>
      <FaroSlider
        dark
        marks={RENDERING_PROFILE_MARKS}
        step={null}
        min={RENDERING_PROFILE_MARKS[0].value}
        max={RENDERING_PROFILE_MARKS[RENDERING_PROFILE_MARKS.length - 1].value}
        valueLabelDisplay="auto"
        onChange={(_, value: number | number[]) => {
          if (typeof value === "number") {
            setPointCloudRenderingProfile(INDEXED_PROFILES[value - 1]);
          }
        }}
        value={sliderValue}
      />
      <Stack direction="row" justifyContent="space-between">
        <FaroText dark variant="bodyS">
          Smooth
        </FaroText>
        <FaroText dark variant="bodyS">
          Sharp
        </FaroText>
      </Stack>
    </>
  );
}

const RENDERING_PROFILE_MARKS = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
];

const INDEXED_PROFILES = [
  PointCloudRenderingProfile.SmoothSilk,
  PointCloudRenderingProfile.Smooth,
  PointCloudRenderingProfile.Sharp,
  PointCloudRenderingProfile.Sharpest,
];

/** @returns a text panel with four help messages, one for each point cloud rendering profile. */
function ProfilesHelpText(): JSX.Element {
  // The interplay between <Stack> and <Box> components below is needed to ensure
  // that the text is divided into four correctly spaced paragraphs, and at the
  // same time the first word only of each paragraph is bold.
  return (
    <Stack spacing=".5em">
      <Box component="div">
        <FaroText dark variant="bodyS" fontWeight="Bold">
          Smooth:{" "}
        </FaroText>
        <FaroText dark variant="bodyS">
          Optimized for smooth and flowing fly-through experience. Details may
          appear blurred and flattened out, especially on SLAM data. Good for
          Sitescape data.
        </FaroText>
      </Box>
      <Box component="div">
        <FaroText dark variant="bodyS" fontWeight="Bold">
          Balanced:{" "}
        </FaroText>
        <FaroText dark variant="bodyS">
          Good fly-through experience, details appear sharper especially when
          more points are loaded.
        </FaroText>
      </Box>
      <Box component="div">
        <FaroText dark variant="bodyS" fontWeight="Bold">
          Sharp:{" "}
        </FaroText>
        <FaroText dark variant="bodyS">
          Navigation is fairly smooth, details are enhanced via outlining. Good
          especially for Slam data, and for obtaining a "dollhouse view" of the
          point cloud.
        </FaroText>
      </Box>
      <Box component="div">
        <FaroText dark variant="bodyS" fontWeight="Bold">
          Sharpest:{" "}
        </FaroText>
        <FaroText dark variant="bodyS">
          Maximum sharpness of detail, noisy fly-through experience. Good for
          detailed annotation/measurement when the camera is not moving.
        </FaroText>
      </Box>
    </Stack>
  );
}
