import {
  HelpAnimation,
  HelpDivider,
  HelpDrawer,
  HelpHeader,
  HelpParagraph,
  HelpSection,
} from "@/components/ui/quick-help-components";
import { QuickHelpHeader } from "@/components/ui/quick-help-header";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectIsQuickHelpOpen } from "@/store/ui/ui-selectors";
import { setIsQuickHelpOpen } from "@/store/ui/ui-slice";
import { StaticResource, getStaticResourceUrl } from "@/utils/static-resources";
import { FaroText, FontWeights } from "@faro-lotv/app-component-toolbox";
import { Box } from "@mui/material";
import { useCallback } from "react";

/**
 * @returns A drawer showing help for the trajectory alignment of odometry paths
 */
export function PathAlignmentQuickHelp(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const isQuickHelpOpen = useAppSelector(selectIsQuickHelpOpen);

  const onQuickHelpClose = useCallback(
    () => dispatch(setIsQuickHelpOpen(false)),
    [dispatch],
  );

  if (!isQuickHelpOpen) return null;

  return (
    <HelpDrawer>
      <QuickHelpHeader title="Help Panel" onClose={onQuickHelpClose} />

      <Box
        component="div"
        sx={{
          height: "100%",
          overflow: "auto",
        }}
      >
        <HelpSection>
          <HelpHeader>
            <FaroText variant="heading16">Align Trajectory</FaroText>
          </HelpHeader>

          <HelpParagraph sx={{ marginTop: "6px" }}>
            <FaroText variant="bodyM">
              Add and move the anchor points to align your trajectory to the
              sheet.
            </FaroText>
            <HelpAnimation
              src={getStaticResourceUrl(StaticResource.alignTrajectoryVideo)}
            />
          </HelpParagraph>

          <HelpParagraph header="CONTROLS" headerSx={{ color: "gray600" }} />
          <HelpParagraph header="Add and Remove Anchor Points">
            <FaroText variant="bodyS">
              Left-click to add up to two anchor points. Left-click any of the
              anchor points again to remove it.
              <br />
              Alternative: right-click an anchor point to remove it.
            </FaroText>
          </HelpParagraph>

          <HelpParagraph header="Scale Trajectory">
            <FaroText variant="bodyS">
              With two anchor points added, drag one to scale the trajectory to
              the sheet.
            </FaroText>
          </HelpParagraph>

          <HelpParagraph header="Translate Trajectory">
            <FaroText variant="bodyS">
              Click an anchor point and drag the trajectory to the position you
              want.
              <br />
              Alternative: press and hold the
              <FaroText
                variant="heading12"
                sx={{ color: "gray700", fontWeight: FontWeights.SemiBold }}
              >
                {" "}
                SHIFT{" "}
              </FaroText>
              key, then left-click on the trajectory and drag it to the position
              you want.
            </FaroText>
          </HelpParagraph>

          <HelpParagraph header="Rotate Trajectory">
            <FaroText variant="bodyS">
              With two anchor points added, click an anchor point to rotate and
              scale the trajectory.
              <br />
              Alternative: with only one anchor point added, click any position
              on the trajectory or on the screen to rotate the trajectory
              without scaling.
            </FaroText>
          </HelpParagraph>
        </HelpSection>

        <HelpDivider />

        <HelpSection>
          <HelpHeader>
            <FaroText variant="heading16">Adjust Trajectory</FaroText>
          </HelpHeader>
          <HelpParagraph sx={{ marginTop: "6px" }}>
            <FaroText variant="bodyM">
              Change the trajectory using waypoints
            </FaroText>
          </HelpParagraph>

          <HelpParagraph header="CONTROLS" headerSx={{ color: "gray600" }} />
          <HelpParagraph header="Move Waypoint">
            <FaroText variant="bodyS">
              Click a waypoint and drag to adjust the entire path. (Locked
              waypoints will not move.)
            </FaroText>
          </HelpParagraph>
          <HelpParagraph header="Lock Waypoint Position">
            <FaroText variant="bodyS">
              Click on a waypoint to lock its position. Click on it again to
              unlock it.
            </FaroText>
          </HelpParagraph>
        </HelpSection>

        <HelpDivider />

        <HelpSection>
          <HelpHeader>
            <FaroText variant="heading16">General Controls</FaroText>
          </HelpHeader>

          <HelpParagraph header="Preview Waypoint">
            <FaroText variant="bodyS">
              Hover on a waypoint to see its preview.
            </FaroText>
          </HelpParagraph>

          <HelpParagraph header="Translate camera (sheet and trajectory)">
            <FaroText variant="bodyS">
              With left and right mouse button pressed, click anywhere in the
              sheet. Then drag the sheet and trajectory to the position you
              want.
              <br />
              Alternative: click and hold the middle mouse button, then drag the
              sheet and trajectory to the position you want.
            </FaroText>
          </HelpParagraph>
        </HelpSection>
      </Box>
    </HelpDrawer>
  );
}
