import { neutral, ViewDiv } from "@faro-lotv/app-component-toolbox";
import { Stack } from "@mui/material";
import { useOverlayElements, useOverlayRef } from "../overlay-elements-context";
import { ReferenceSelectorTool } from "./align-wizard-reference-selector-tool";

/**
 * @returns Renderer of split screen for Alignment Wizard
 *
 * This component sets up a side-by-side view with two `ViewDiv` components, each managed by `useOverlayRef`
 * to handle references for interactions. It occupies the full viewport using MUI's `Stack` component.
 *
 * For developers:
 * - `useOverlayElements` retrieves the context to manage screen overlays.
 * - `useOverlayRef` creates and registers the references for the two screens.
 * - Use `ViewDiv` to utilize full-screen space for content.
 *
 * For end users:
 * - Provides a seamless side-by-side views of aligned and reference elements
 * - Facilitates selection of appropriate elements for alignment
 */
export function AlignWizardSplitScreen(): JSX.Element {
  const { setFirstScreen, setSecondScreen } = useOverlayElements();
  const firstScreenRef = useOverlayRef(setFirstScreen);
  const secondScreenRef = useOverlayRef(setSecondScreen);

  return (
    <Stack
      direction="row"
      justifyItems="stretch"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <ViewDiv
        eventDivRef={firstScreenRef}
        sx={{
          height: "100%",
          width: "50%",
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      />

      <ViewDiv
        eventDivRef={secondScreenRef}
        sx={{
          height: "100%",
          width: "50%",
          flexGrow: 1,
          overflow: "hidden",
          border: `1px solid ${neutral[100]}`,
        }}
      />

      <ReferenceSelectorTool />
    </Stack>
  );
}
