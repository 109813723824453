import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import {
  IElementSection,
  IElementType,
  IElementTypeHint,
  isIElementWithTypeAndHint,
  WithHint,
} from "@faro-lotv/ielement-types";
import {
  selectAllIElementsOfType,
  selectIElement,
} from "@faro-lotv/project-source";

/**
 * @returns true if the project contains a capture tree, which contains data sets containing anything
 * Capture tree
 * └ Data sets
 *   └ anything
 * @param state App state
 *
 * HOTFIX for https://faro01.atlassian.net/browse/SWEB-5087
 */
export function selectDisableCaptureTreeAlignment(state: RootState): boolean {
  const captureTree = selectAllIElementsOfType(
    (
      element,
    ): element is WithHint<IElementSection, IElementTypeHint.captureTree> =>
      isIElementWithTypeAndHint(
        element,
        IElementType.section,
        IElementTypeHint.captureTree,
      ),
  )(state);

  const dataSets = selectIElement(captureTree[0]?.childrenIds?.[0])(state);

  return !!dataSets?.childrenIds?.length;
}

/**
 * @returns the hook version of selectDisableCaptureTreeAlignment
 *
 * HOTFIX for https://faro01.atlassian.net/browse/SWEB-5087
 */
export function useDisableCaptureTreeAlignment(): boolean {
  return useAppSelector(selectDisableCaptureTreeAlignment);
}

export const compatibilityMessage =
  "Alignment/scaling is not yet available for SCENE Data.";
