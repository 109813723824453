import { ElementIconType } from "@/components/ui/icons";
import { FaroText, FaroTextVariant } from "@faro-lotv/flat-ui";
import { isGeoReferencedElement } from "@faro-lotv/ielement-types";
import { setGeoReferenced } from "@faro-lotv/project-source";
import { createMutationSetDataSessionWorldPose } from "@faro-lotv/service-wires";
import {
  ContextMenuAction,
  ContextMenuActionHandlerArgs,
  ContextMenuActionType,
} from "../action-types";

// ContextMenuAction to convert a non-geo-referenced cloud to a geo-referenced one
// Will be disabled when the cloud is already geo-referenced
export const SET_AS_GEO_REFERENCED_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.setCloudAsGeoReferenced,
  label: "Treat as georeferenced",
  icon: ElementIconType.GeoReferenceIcon,
  handler: setCloudAsGeoReferenced,
  disabledMessageForNode: (iElement) => {
    if (isGeoReferencedElement(iElement)) {
      return "This cloud is already georeferenced.";
    }
  },
  tooltipMessage:
    "Expresses the point cloud in the coordinate system of the 3D Model",
};

/**
 * Set the cloud as geo-referenced (same POSE as the CAD)
 */
async function setCloudAsGeoReferenced({
  elementID,
  apiClients,
  createDialog,
  dispatch,
}: ContextMenuActionHandlerArgs): Promise<void> {
  // ask user to confirm alignment
  const hasConfirmed = await createDialog({
    title: "Treat the point cloud as georeferenced",
    confirmText: "Treat as georeferenced",
    content: (
      <FaroText variant={FaroTextVariant.bodyM}>
        The point cloud will be expressed in the coordinates system of the 3D
        Model. <br />
        You can revert this operation by re-aligning the point cloud to the
        sheet.
      </FaroText>
    ),
  });

  if (!hasConfirmed) return;

  // apply new alignment (identify in world)
  const alignMutation = createMutationSetDataSessionWorldPose(
    elementID,
    1,
    { x: 0, y: 0, z: 0, w: 1 },
    { x: 0, y: 0, z: 0 },
  );
  const resultMutation = (
    await apiClients.projectApiClient.applyMutations([alignMutation])
  )[0];
  if (resultMutation.status === "failure") {
    throw new Error("Unable to set area scale");
  }

  // update store so the store does not diverge from the backend
  dispatch(setGeoReferenced(elementID));
}
