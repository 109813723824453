import {
  GapFillingOptions,
  useRenderingSettings,
} from "@/components/common/rendering-settings-context";
import { useLodCloudDynamicRenderer } from "@/hooks/use-lod-cloud-dynamic-renderer";
import { PointCloudObject } from "@/object-cache";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import { SubScene } from "@faro-lotv/app-component-toolbox";
import { SceneFilterFunction } from "@faro-lotv/lotv";
import { useEffect } from "react";
import { PointCloudDefaultEffects } from "../renderers/pointcloud-renderer";

type PointCloudSubsceneProps = {
  /** The point cloud to be rendered */
  pointCloud: PointCloudObject | null;

  /** Whether this subscene should be rendered on demand */
  renderOnDemand?: boolean;

  /** Function to select from the scene graph only the elements to be rendered in this subscene. */
  filter?: SceneFilterFunction;

  /** Whether the subscene is enabled */
  enabled?: boolean;
};

/**
 * @returns a subscene suitable to render an LOD point cloud,
 * taking into account rendering profiles, post-processing effects,
 * and dynamic performance optimization.
 */
export function PointCloudSubscene({
  pointCloud,
  renderOnDemand = true,
  filter = isObjPointCloudPoint,
  enabled = true,
}: PointCloudSubsceneProps): JSX.Element {
  const dynamicRenderer = useLodCloudDynamicRenderer(pointCloud);

  useEffect(() => {
    if (renderOnDemand) {
      dynamicRenderer.onCameraStoppedMoving();
    } else {
      dynamicRenderer.onCameraStartedMoving();
    }
  }, [dynamicRenderer, renderOnDemand]);

  const { gapFilling, pointCloudRenderingProfile, eyeDomeOn } =
    useRenderingSettings();

  // Signaling the dynamicRender object when the postprocessing effects are enabled or disabled by the user.
  useEffect(() => {
    dynamicRenderer.gapFillingEnabled =
      gapFilling !== GapFillingOptions.Disabled;
    dynamicRenderer.eyeDomeEnabled = eyeDomeOn || !!pointCloud?.monochrome;
  }, [gapFilling, eyeDomeOn, dynamicRenderer, pointCloud]);

  // Invalidating the point cloud subscene when the rendering profile changes
  useEffect(() => {
    dynamicRenderer.subScene?.invalidate();
  }, [pointCloudRenderingProfile, dynamicRenderer]);

  return (
    <SubScene
      filter={filter}
      renderingPolicy={dynamicRenderer}
      enabled={!!pointCloud && enabled}
    >
      <PointCloudDefaultEffects pointCloud={pointCloud} />
    </SubScene>
  );
}
