import { selectClippingBox } from "@/store/clipping-box-selectors";
import { setClippingBox } from "@/store/clipping-box-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  AutoClipCurrentViewIcon,
  ChangeIndicatorBadge,
  ClippingBoxIcon,
  FaroTooltip,
  ResetIcon,
  ToolButton,
  ToolGroup,
  Toolbar,
} from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import { useClipboxEvents } from "../common/clipbox-events-context";

/** @returns the icon for the clip scene tool, with an orange dot when the clipping box is defined. */
export function ClipSceneIcon(): JSX.Element {
  const isClippingBoxDefined = useAppSelector(selectClippingBox) !== undefined;

  return (
    <ChangeIndicatorBadge invisible={!isClippingBoxDefined}>
      <ClippingBoxIcon />
    </ChangeIndicatorBadge>
  );
}

interface ClipSceneSubmenuProps {
  /** Whether the auto-clip functionality should be allowed (needs support in the rendering pipeline) @default true */
  allowAutoClip?: boolean;

  /** Callback executed after the clipping box is reset */
  onReset?(): void;

  /** Callback executed after the clipping box was auto-clipped */
  onAutoClipped?(): void;
}

/** @returns the submenu for the clip scene tool */
export function ClipSceneSubmenu({
  allowAutoClip = true,
  onReset,
  onAutoClipped,
}: ClipSceneSubmenuProps): JSX.Element {
  const dispatch = useAppDispatch();

  const clipboxEvents = useClipboxEvents();
  assert(
    clipboxEvents,
    "ClipboxEventsContext should be defined in the ClipSceneSubmenu.",
  );

  return (
    <Toolbar sx={{ width: "max-content" }}>
      <ToolGroup orientation="horizontal">
        <FaroTooltip title="Reset the clipping box">
          <ToolButton
            onClick={() => {
              dispatch(setClippingBox(undefined));

              onReset?.();
            }}
          >
            <ResetIcon />
          </ToolButton>
        </FaroTooltip>
        {allowAutoClip && (
          <FaroTooltip title="Auto-clip to current view">
            <ToolButton
              onClick={() => {
                clipboxEvents.autoClipBox.emit();

                onAutoClipped?.();
              }}
            >
              <AutoClipCurrentViewIcon />
            </ToolButton>
          </FaroTooltip>
        )}
      </ToolGroup>
    </Toolbar>
  );
}
