import { HorizontalResizeContainer } from "@/components/ui/resize-container";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { MultiCloudRegistrationReportView } from "@/registration-tools/common/registration-report/multicloud-registration-report";
import { THRESHOLD_SET_STATIONARY } from "@/registration-tools/common/registration-report/registration-thresholds";
import { ThresholdSetProvider } from "@/registration-tools/common/registration-report/threshold-set-context";
import { useAppSelector } from "@/store/store-hooks";
import { redirectToViewer } from "@/utils/redirects";
import {
  Banner,
  CloseIcon,
  FaroButton,
  FaroIconButton,
  FaroText,
  neutral,
} from "@faro-lotv/flat-ui";
import {
  RegistrationRevision,
  RegistrationState,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/material";
import { isEqual } from "lodash";
import { useCallback, useState } from "react";
import { useLoadRegistrationReport } from "../loading/use-load-registration-report";
import { RevisionScansScene } from "../rendering/revision-scans-scene";
import {
  selectPointCloudStreamForHoveredEntity,
  selectPointCloudStreamForSelectedEntity,
} from "../store/data-preparation-ui/data-preparation-ui-selectors";
import { selectRevisionScans } from "../store/revision-selectors";
import { DataPreparationSidebar } from "../ui/data-preparation-sidebar";

type InspectAndPublishViewProps = {
  /** The revision to show the inspect & publish view for. */
  revision: RegistrationRevision;
};

/**
 * @returns The inspect & publish step of the data preparation workflow.
 */
export function InspectAndPublishView({
  revision,
}: InspectAndPublishViewProps): JSX.Element {
  const registrationReport = useLoadRegistrationReport(revision.reportUri);
  const [isReportOpen, setIsReportOpen] = useState(false);

  const scanEntities = useAppSelector(selectRevisionScans, isEqual);

  const hoveredPointCloudId = useAppSelector(
    selectPointCloudStreamForHoveredEntity,
  );
  const selectedPointCloud = useAppSelector(
    selectPointCloudStreamForSelectedEntity,
  );

  return (
    <ThresholdSetProvider defaultThresholdSet={THRESHOLD_SET_STATIONARY}>
      <Stack sx={{ height: "100%", width: "100%" }}>
        <InspectAndPublishStatusBanner revisionState={revision.state} />
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <DataPreparationSidebar
            title="Registered scans"
            description="Publish the registration result as a merged project point to
                your SphereXG project."
            buttons={
              <>
                <FaroButton
                  variant="secondary"
                  isLoading={!registrationReport}
                  onClick={() => setIsReportOpen(!isReportOpen)}
                >
                  {registrationReport && isReportOpen ? "Hide" : "Show"} quality
                  report
                </FaroButton>
                <PublishButton revision={revision} />
              </>
            }
          />

          {/* 3D scene */}
          <RevisionScansScene
            scanEntities={scanEntities}
            registrationReport={registrationReport}
            hoveredPointCloudId={hoveredPointCloudId?.id}
            selectedPointCloudId={selectedPointCloud?.id}
          />

          {/* Quality report */}
          {isReportOpen && !!registrationReport && (
            <Box
              component="div"
              sx={{
                width: 0,
                height: "100%",
                position: "relative",
              }}
            >
              <HorizontalResizeContainer
                initialWidth={600}
                minWidth={350}
                maxWidth={600}
                handleSide="left"
                sx={{
                  position: "absolute",
                  right: 0,
                  height: "100%",
                }}
              >
                <Stack
                  sx={{
                    p: 1,
                    overflowX: "auto",
                    width: "100%",
                    height: "100%",
                    backgroundColor: neutral[0],
                  }}
                  gap={1}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FaroText variant="heading16">Quality Report</FaroText>
                    <FaroIconButton onClick={() => setIsReportOpen(false)}>
                      <CloseIcon />
                    </FaroIconButton>
                  </Stack>
                  <MultiCloudRegistrationReportView {...registrationReport} />
                </Stack>
              </HorizontalResizeContainer>
            </Box>
          )}
        </Stack>
      </Stack>
    </ThresholdSetProvider>
  );
}

type PublishButtonProps = {
  /** The revision to publish. */
  revision: RegistrationRevision;
};

/** @returns A button to publish a revision, i.e. merge it into the main revision. */
function PublishButton({ revision }: PublishButtonProps): JSX.Element {
  const [isPublishLoading, setIsPublishLoading] = useState(false);

  const { handleErrorWithToast } = useErrorHandlers();
  const { projectApiClient } = useApiClientContext();

  // Only registered revisions can be published
  const isDisabled = revision.state !== RegistrationState.registered;

  const publishRevision = useCallback(async () => {
    setIsPublishLoading(true);

    try {
      await projectApiClient.applyRegistrationRevisionToMain(revision.id);
      redirectToViewer(projectApiClient.projectId);
    } catch (error) {
      handleErrorWithToast({
        error,
        title: "Failed to publish data",
      });
    }

    setIsPublishLoading(false);
  }, [projectApiClient, revision.id, handleErrorWithToast]);

  return (
    <FaroButton
      variant="primary"
      disabled={isDisabled}
      isLoading={isPublishLoading}
      onClick={publishRevision}
    >
      Publish
    </FaroButton>
  );
}

type InspectAndPublishStatusProps = {
  /** The current state of the registration. */
  revisionState: RegistrationState;
};

/** @returns A banner showing the current state of the workflow to the user. */
function InspectAndPublishStatusBanner({
  revisionState,
}: InspectAndPublishStatusProps): JSX.Element | null {
  switch (revisionState) {
    case RegistrationState.merged:
      return (
        <Banner variant="success" title="Dataset published">
          Your data set has been published, you can now view it in the Sphere
          Viewer.
        </Banner>
      );
    case RegistrationState.canceled:
      return (
        <Banner variant="error" title="Registration canceled">
          The registration has been canceled. Please restart the workflow.
        </Banner>
      );
    default:
      // Nothing special, just show the normal view
      return null;
  }
}
