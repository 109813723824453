import { PointCloudObject } from "@/object-cache";
import { GUID } from "@faro-lotv/ielement-types";
import { useFrame } from "@react-three/fiber";
import { useEffect, useRef } from "react";

/**
 * The maximum number of points for all point clouds combined.
 *
 * Value determined experimentally.
 */
const MAX_TOTAL_POINTS = 1_000_000;

/**
 * The default maximum number of points to download at the same time for both point clouds combined.
 *
 * Value determined experimentally.
 */
const MAX_NODES_TO_DOWNLOAD_AT_ONCE = 6;

/**
 * Handler to manage multiple point clouds for efficient rendering.
 * It takes a maximum point budget and then distributes it among the point clouds
 * depending on how much of them is visible in the camera view.
 *
 * @param pointCloudObjects The point cloud objects to manage the point budget for.
 * @param maxPointsInGpu The maximum number of points to store in the GPU for each cloud.
 *  We will have `maxPointsInGpu * pointCloudObjects.length` points in the GPU in total,
 *  but only `maxPointsInGpu` points will be rendered in total.
 * @param maxNodesToDownloadAtOnce The maximum number of nodes to download at the same time for all point clouds.
 */
export function useMultiCloudPointBudgetManager(
  pointCloudObjects: PointCloudObject[],
  maxPointsInGpu: number = MAX_TOTAL_POINTS,
  maxNodesToDownloadAtOnce: number = MAX_NODES_TO_DOWNLOAD_AT_ONCE,
): void {
  const previousPointCloudSettings = useRef(
    new Map<GUID, PointCloudSettings>(),
  );
  const cachedVisibleNodeCount = useRef(new Map<GUID, number>());

  // Set the maximum points per GPU on each point cloud
  useEffect(() => {
    // Save the previous settings
    for (const pointCloudObject of pointCloudObjects) {
      const { id } = pointCloudObject.iElement;

      // Do not overwrite, we want to restore the earliest settings
      if (!previousPointCloudSettings.current.has(id)) {
        previousPointCloudSettings.current.set(id, {
          maxPointsInGpu: pointCloudObject.visibleNodesStrategy.maxPointsInGpu,
          subsampledRenderingOn: pointCloudObject.getSubsampledRenderingOn(),
          subsampledRenderingFraction:
            pointCloudObject.getSubsampledRenderingFraction(),
          maxNodesToDownloadAtOnce:
            pointCloudObject.lodTreeFetcher.maxNodesToDownloadAtOnce,
        });
      }
    }

    // Configure the settings we need
    for (const pointCloudObject of pointCloudObjects) {
      pointCloudObject.visibleNodesStrategy.maxPointsInGpu = maxPointsInGpu;
      pointCloudObject.setSubsampledRenderingOn(true);
    }

    // Restore previous settings
    return () => {
      for (const pointCloudObject of pointCloudObjects) {
        // The ref doesn't point to a node, so we can safely use `current` here again
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const previousSettings = previousPointCloudSettings.current.get(
          pointCloudObject.iElement.id,
        );

        if (previousSettings) {
          pointCloudObject.visibleNodesStrategy.maxPointsInGpu =
            previousSettings.maxPointsInGpu;
          pointCloudObject.setSubsampledRenderingOn(
            previousSettings.subsampledRenderingOn,
          );
          pointCloudObject.setSubsampledRenderingFraction(
            previousSettings.subsampledRenderingFraction,
          );
          pointCloudObject.lodTreeFetcher.maxNodesToDownloadAtOnce =
            previousSettings.maxNodesToDownloadAtOnce;
        }
      }
    };
  }, [pointCloudObjects, maxPointsInGpu]);

  // Calculate how many points to render of each cloud, based on how much is visible on screen
  useFrame(() => {
    // Determine how many nodes are visible for each point cloud
    // A higher node count means a bigger portion of the cloud is visible on screen
    for (const pointCloud of pointCloudObjects) {
      cachedVisibleNodeCount.current.set(
        pointCloud.iElement.id,
        pointCloud.visibleNodesCount,
      );
    }

    const totalVisibleNodes = Array.from(
      cachedVisibleNodeCount.current.values(),
    ).reduce((prev, cur) => prev + cur, 0);

    for (const pointCloudObject of pointCloudObjects.values()) {
      // Estimate how much of the point cloud is visible on the screen,
      // relative to the other point clouds
      const visibleNodes =
        cachedVisibleNodeCount.current.get(pointCloudObject.iElement.id) ?? 0;
      const weight =
        totalVisibleNodes > 0 ? visibleNodes / totalVisibleNodes : 0;

      // Use subsampled rendering to only render that fraction of points
      pointCloudObject.setSubsampledRenderingFraction(weight);

      // Limit number of nodes fetched at the same time
      pointCloudObject.lodTreeFetcher.maxNodesToDownloadAtOnce = Math.max(
        Math.floor(maxNodesToDownloadAtOnce * weight),
        // It should always be possible to download a node
        1,
      );
    }
  });
}

/** The point cloud settings that we overwrite in the hook. */
type PointCloudSettings = {
  maxPointsInGpu: number;
  subsampledRenderingOn: boolean;
  subsampledRenderingFraction: number;
  maxNodesToDownloadAtOnce: number;
};
