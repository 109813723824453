import { selectAlignmentArea } from "@/alignment-tool/store/alignment-selectors";
import { assert } from "@faro-lotv/foundation";
import {
  GUID,
  IElement,
  IElementAreaSection,
  IElementGenericImgSheet,
  IElementImg360,
  IElementMarkup,
  IElementSection,
  IElementType,
  IElementTypeHint,
  WithHint,
  isIElementAreaSection,
  isIElementGenericAnnotation,
  isIElementGenericDataset,
  isIElementGenericImgSheet,
  isIElementImg360,
  isIElementMarkup,
  isIElementOdometryPath,
  isIElementPanoInOdometryPath,
  isIElementSection,
} from "@faro-lotv/ielement-types";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectIElement,
  selectIElementWorldPosition,
} from "@faro-lotv/project-source";
import { Vector3 } from "three";
import { RootState } from "./store";

/**
 * @param state Current state
 * @returns The IElement of the current selection
 */
export function selectActiveElement(state: RootState): IElement | undefined {
  if (!state.selections.activeElement) {
    return;
  }
  return selectIElement(state.selections.activeElement)(state);
}

/**
 * @param state Current state
 * @returns The IElement of the current selection
 */
export function selectActiveElementId(state: RootState): GUID | undefined {
  return state.selections.activeElement;
}

/**
 * @param state Current state
 * @returns true if the project is empty (does not contain an Area and an ImgSheet)
 */
export function selectIsProjectEmpty(state: RootState): boolean {
  return !selectActiveArea()(state);
}

/**
 * @param id An Img360 ID or a Section ID that contains an Img360
 * @returns The relevant Img360 and section for the passed id
 */
export function selectPanoAndSection(id: GUID | undefined) {
  return (
    state: RootState,
  ): {
    section: IElementSection | undefined;
    img360: IElementImg360 | undefined;
  } => {
    if (!id) {
      return { section: undefined, img360: undefined };
    }

    const startingElement = selectIElement(id)(state);
    const section = selectAncestor(startingElement, isIElementSection)(state);
    if (!section) {
      throw Error(
        "Passed ID is not an Img360 or a Section containing an Img360",
      );
    }

    const img360 = selectChildDepthFirst(section, isIElementImg360, 1)(state);
    if (!img360) {
      throw Error(
        "Passed ID is not an Img360 or a Section containing an Img360",
      );
    }

    return { section, img360 };
  };
}

/**
 * @returns The closest pano amongst the children of the provided reference element
 * @param referenceId Reference element of the children to check for
 * @param referencePos Position to use as reference
 */
export function selectClosestPanoInSpaceAmongstChildren(
  referenceId: GUID,
  referencePos: Vector3,
) {
  return (state: RootState): IElementImg360 | undefined => {
    const referenceElement = selectIElement(referenceId)(state);

    const panos = selectChildrenDepthFirst(
      referenceElement,
      isIElementImg360,
    )(state);

    return selectClosestPanoToPosition(panos, referencePos)(state);
  };
}

/**
 * @returns The closest pano to the provided position (in space)
 * @param panos The pano elements to check
 * @param position The position to find the closes pano for
 */
export function selectClosestPanoToPosition(
  panos: IElementImg360[],
  position: Vector3,
) {
  return (state: RootState): IElementImg360 | undefined => {
    let closestPano: IElementImg360 | undefined;
    let closestDistance: number = Infinity;

    const pos = new Vector3();

    for (const pano of panos) {
      pos.fromArray(selectIElementWorldPosition(pano.id)(state));
      const distance = pos.distanceTo(position);
      if (distance < closestDistance) {
        closestDistance = distance;
        closestPano = pano;
      }
    }

    return closestPano;
  };
}

/**
 *
 * @param reference The element whose area section we are looking for, or the active element
 * @returns The Area (child or parent) related to this element or undefined if no area is available
 */
export function selectActiveArea(reference?: IElement) {
  return (state: RootState): IElementSection | undefined => {
    return selectAreaFor(reference ?? selectActiveElement(state))(state);
  };
}

/**
 * @param element to select the area for
 * @returns the area linked to an element, or undefined if the element is not linked to an area
 */
export function selectAreaFor(element?: IElement) {
  return (state: RootState): IElementAreaSection | undefined => {
    // Pick the parent area of the element if the element is in the BI tree
    const area = selectAncestor(element, isIElementAreaSection)(state);
    if (area) {
      return area;
    }

    // Pick the area from the first alignment edge for elements in the Capture Tree
    const dataSet = selectAncestor(element, isIElementGenericDataset)(state);
    const targetAreaId =
      dataSet &&
      Object.entries(state.iElements.areaDataSets).find(([, dataSets]) =>
        dataSets?.some((value) => value.elementId === dataSet.id),
      )?.[0];
    const targetArea = targetAreaId
      ? selectIElement(targetAreaId)(state)
      : undefined;

    assert(
      !targetArea || isIElementAreaSection(targetArea),
      "A dataset should be linked to a Section(Area)",
    );
    return targetArea;
  };
}

/**
 *
 * @param reference The element whose area section we are looking for, or the active element
 * @returns The Area Section (child or parent) related to this element, OR the area currently used for alignment
 * @throws { Error } if no area section is available
 */
export function selectActiveAreaOrThrow(reference?: IElement) {
  return (state: RootState): IElementSection => {
    const alignmentArea = selectAlignmentArea(state);
    const areaSection = alignmentArea ?? selectActiveArea(reference)(state);

    if (!areaSection) {
      throw new Error("No area section is available");
    }
    return areaSection;
  };
}

/**
 * @returns the list of markups for the given iElement
 * @param reference An IElement whose children are going to be searched for markups,
 * it can also be an Img360, in that case its sibling tree is checked for markups
 */
export function selectMarkups(reference?: IElement) {
  return (state: RootState): IElementMarkup[] | undefined => {
    if (!reference) return;
    const parent = isIElementImg360(reference)
      ? selectIElement(reference.parentId ?? "")(state)
      : reference;
    return selectChildrenDepthFirst(parent, isIElementMarkup)(state);
  };
}

/**
 * @param pano reference pano inside an OdometryPath Section
 * @returns The panos in video mode trajectory relative to the reference element
 */
export function selectAllPanosInOdometryPath(
  pano: WithHint<IElementImg360, IElementTypeHint.odometryPath>,
) {
  return (state: RootState): IElementImg360[] => {
    if (!pano.parentId) {
      return [];
    }
    const path = selectAncestor(pano, isIElementOdometryPath)(state);

    if (!path) return [];

    return selectChildrenDepthFirst(path, isIElementPanoInOdometryPath)(state);
  };
}

/**
 * @param pano to get the section containing its annotation
 * @returns the project Section node containing the annotations for this Pano
 */
export function selectPanoAnnotationSection(pano: IElementImg360) {
  return (state: RootState): IElement | undefined =>
    pano.typeHint === IElementTypeHint.odometryPath && pano.targetId
      ? selectIElement(pano.targetId)(state)
      : selectAncestor(pano, isIElementSection)(state);
}

/**
 * @returns the project Section node containing the annotations for this Pano
 * @param element element where the annotation was taken on by the user
 * @param area the current area that is used as a fallback if no other section is found
 */
export function selectAnnotationSection(
  element: IElement,
  area: IElementSection,
) {
  return (state: RootState): IElement | undefined => {
    // Get the section that is the direct parent of the group containing the annotations:
    // it's a Pano Section if the target element is a 360, otherwise it's either the containing dataset or the area
    if (isIElementImg360(element)) {
      return selectPanoAnnotationSection(element)(state);
    }
    // If the element is in a dataset, the annotation should be saved there
    return selectAncestor(element, isIElementGenericDataset)(state) ?? area;
  };
}

/**
 * @param markup markup to whose attachments are to be returned
 * @returns list of attachments for the given markup
 */
export function selectMarkupAttachments(markup: IElementMarkup) {
  return (state: RootState): IElement[] => {
    const model3d = selectAncestor(markup, isIElementGenericAnnotation)(state);
    return selectChildrenDepthFirst(
      model3d,
      (el: IElement) =>
        el.typeHint === IElementTypeHint.command ||
        el.type === IElementType.attachment,
    )(state);
  };
}

/**
 * @returns the active sheet
 * @param state the app state
 */
export function selectActiveSheetIfAvailable(
  state: RootState,
): IElementGenericImgSheet | undefined {
  if (state.selections.activeSheet !== undefined) {
    const element = state.iElements.iElements[state.selections.activeSheet];
    if (isIElementGenericImgSheet(element)) return element;
  }
  return undefined;
}

/**
 * @returns the active sheet or throw an error if not available
 * @param state the app state
 */
export function selectActiveSheet(state: RootState): IElementGenericImgSheet {
  assert(
    state.selections.activeSheet !== undefined,
    "An active sheet should exist",
  );

  const element = state.iElements.iElements[state.selections.activeSheet];
  assert(
    isIElementGenericImgSheet(element),
    "The active sheet is an unsupported format",
  );

  return element;
}
