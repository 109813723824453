import { getStep } from "@/alignment-tool/alignment-steps";
import { StepNames } from "@/alignment-tool/alignment-steps/steps";
import { EventType } from "@/alignment-tool/analytics/analytics-events";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { runtimeConfig } from "@/runtime-config";
import { AppDispatch, RootState } from "@/store/store";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { appId } from "@/utils/appid";
import { selectProjectId } from "@faro-lotv/app-component-toolbox";
import { FaroButton } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { ProjectApi, useProjectApiClient } from "@faro-lotv/service-wires";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import {
  selectIsActiveStepComplete,
  selectIsLastStep,
  selectPreviousStep,
} from "../store/alignment-selectors";
import { gotToNextStep, gotToPreviousStep } from "../store/alignment-slice";
import { AlignmentProgress } from "./alignment-progress";

interface TopBarProps {
  /** Method to call when the user confirms the alignment */
  onFinishAlignment(): void;

  /** Name of the active step */
  activeStep: StepNames;

  /** Id of the active step */
  activeStepId: number;
}

/**
 * Finalize the current step and if that is successful move to the next step
 *
 * @param currentStepName The current step name
 * @param client The ProjectApi client to work on the current project
 * @param state The current app state
 * @param dispatch The function to dispatch action to update the current state
 * @param onFinishAlignment A function to call if the entire workflow is completed
 */
async function toNextStep(
  currentStepName: StepNames,
  client: ProjectApi,
  state: RootState,
  dispatch: AppDispatch,
  onFinishAlignment: () => void,
): Promise<void> {
  const currentStep = getStep(currentStepName);
  const isLastStep = selectIsLastStep(state);
  await currentStep.onBeforeNext?.(client, state, dispatch);
  if (isLastStep) {
    Analytics.track(EventType.alignmentConfirmed);
    onFinishAlignment();
  } else {
    Analytics.track(EventType.alignmentNextStep, {
      currentStep: currentStepName,
    });
    dispatch(gotToNextStep());
  }
}

/**
 * Go back to the previous step and allow the previous step to do some cleanup logic if needed
 *
 * @param currentStepName The current step name
 * @param previousStepName The name of the previous step
 * @param client The ProjectApi client to work on the current project
 * @param state The current app state
 * @param dispatch The function to dispatch action to update the current state
 */
async function toPreviousStep(
  currentStepName: StepNames,
  previousStepName: StepNames,
  client: ProjectApi,
  state: RootState,
  dispatch: AppDispatch,
): Promise<void> {
  Analytics.track(EventType.alignmentPreviousStep, {
    currentStep: currentStepName,
  });
  const previousStep = getStep(previousStepName);
  dispatch(gotToPreviousStep());
  await previousStep.onStartingFromBack?.(client, state, dispatch);
}

/**
 * @returns The bar that is shown in the top of the app
 *  Allows user to see where there are currently in the alignment process, and switch between those steps
 */
export function AlignmentProgressBar({
  onFinishAlignment,
  activeStep,
  activeStepId,
}: TopBarProps): JSX.Element {
  const store = useAppStore();
  const dispatch = useAppDispatch();
  const previousStep = useAppSelector(selectPreviousStep);
  const shouldDisableNextButton = !useAppSelector(selectIsActiveStepComplete);
  const isLastStep = useAppSelector(selectIsLastStep);
  const projectId = useAppSelector(selectProjectId);

  if (!projectId) {
    throw new Error("An active project is required for the Alignment Tool");
  }
  const client = useProjectApiClient(
    runtimeConfig.backendEndpoints.projectApiUrl,
    runtimeConfig.backendEndpoints.coreApiUrl,
    projectId,
    appId(),
  );
  const { handleErrorWithDialog } = useErrorHandlers();

  const onNext = useCallback(() => {
    toNextStep(
      activeStep,
      client,
      store.getState(),
      dispatch,
      onFinishAlignment,
    ).catch((reason) =>
      handleErrorWithDialog({
        title: "Alignment Tool",
        error: reason,
      }),
    );
  }, [
    activeStep,
    client,
    dispatch,
    handleErrorWithDialog,
    onFinishAlignment,
    store,
  ]);

  const onPrev = useCallback(() => {
    if (!previousStep) return;
    toPreviousStep(
      activeStep,
      previousStep,
      client,
      store.getState(),
      dispatch,
    ).catch((reason) =>
      handleErrorWithDialog({
        title: "Alignment Tool",
        error: reason,
      }),
    );
  }, [
    activeStep,
    previousStep,
    client,
    dispatch,
    handleErrorWithDialog,
    store,
  ]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={({ palette }) => ({
        p: 1,
        backgroundColor: palette.gray100,
      })}
    >
      <Typography variant="overline" sx={{ fontWeight: "bold" }}>
        Align point cloud
      </Typography>

      <AlignmentProgress
        activeStep={activeStepId}
        sx={{
          height: "100%",
          width: "25%",
        }}
        firstStepLabel="Align"
        secondStepLabel="Elevation"
      />

      <Stack
        direction="row"
        justifyContent="end"
        spacing={2}
        sx={{ width: "20%", mr: 2 }}
      >
        {previousStep && (
          <FaroButton variant="secondary" onClick={onPrev} aria-label="back">
            Back
          </FaroButton>
        )}

        <FaroButton
          disabled={shouldDisableNextButton}
          onClick={onNext}
          aria-label="next"
        >
          {isLastStep ? "Confirm Alignment" : "Next"}
        </FaroButton>
      </Stack>
    </Stack>
  );
}
