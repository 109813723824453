import {
  EventType,
  ShowPairwiseRegistrationReportEventProperties,
} from "@/analytics/analytics-events";
import { useAlignmentOverlay } from "@/registration-tools/common/alignment-overlay-context";
import { useRegistrationContext } from "@/registration-tools/common/registration-context";
import { Perspective } from "@/registration-tools/common/store/registration-datatypes";
import { getMetricScaleIcon } from "@/registration-tools/utils/metrics";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  FaroSvgIcon,
  ResetIcon,
  ToolButton,
  Toolbar,
  ZoomAllIcon,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { Divider, Stack, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useCallback } from "react";
import {
  FrontViewIcon,
  SideViewIcon,
  TopViewIcon,
} from "../icons/manual-alignment-icons";
import { ReactComponent as MetricsScaleUnknown } from "../icons/metrics-scale-unknown.svg";
import {
  selectCurrentPerspective,
  selectLastRegistrationPoseUsed,
} from "./store/registration-selectors";
import { setCurrentPerspective } from "./store/registration-slice";

type AlignmentCanvasOverlayProps = AlignmentToolBarProps;

/**
 * @param props - The properties for the alignment workflow overlay
 * @returns The overlay for the alignment workflow
 */
export function AlignmentCanvasOverlay(
  props: AlignmentCanvasOverlayProps,
): JSX.Element {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        width: "100%",
        height: "100%",
        p: 2,
      }}
    >
      <Stack direction="column" justifyContent="end">
        <AlignmentViewToggle />
      </Stack>

      <Stack direction="column" justifyContent="center">
        <AlignmentToolBar {...props} />
      </Stack>
    </Stack>
  );
}

/**
 * @returns A toggle button group to change the camera view between top view, front view and side view
 */
export function AlignmentViewToggle(): JSX.Element | null {
  const perspective = useAppSelector(selectCurrentPerspective);
  const dispatch = useAppDispatch();
  const { centerCameraOnPerspective } = useAlignmentOverlay();

  const onHandleChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newPerspective?: Perspective) => {
      if (newPerspective) {
        Analytics.track(EventType.changePairwiseRegistrationView, {
          perspective: newPerspective,
        });
        dispatch(setCurrentPerspective(newPerspective));
        centerCameraOnPerspective.emit(newPerspective);
      }
    },
    [centerCameraOnPerspective, dispatch],
  );

  return (
    <Toolbar>
      <ToggleButtonGroup
        value={perspective}
        orientation="vertical"
        exclusive
        onChange={onHandleChange}
      >
        <Tooltip title="Top View" placement="right">
          <ToolButton
            value={Perspective.topView}
            selected={perspective === Perspective.topView}
          >
            <TopViewIcon />
          </ToolButton>
        </Tooltip>
        <Tooltip title="Front View" placement="right">
          <ToolButton
            value={Perspective.frontView}
            selected={perspective === Perspective.frontView}
          >
            <FrontViewIcon />
          </ToolButton>
        </Tooltip>
        <Tooltip title="Side View" placement="right">
          <ToolButton
            value={Perspective.sideView}
            selected={perspective === Perspective.sideView}
          >
            <SideViewIcon />
          </ToolButton>
        </Tooltip>
      </ToggleButtonGroup>
    </Toolbar>
  );
}

type AlignmentToolBarProps = {
  showResetTransformation: boolean;
};
/**
 * @returns A toolbar that allows to
 *           - reset the camera view
 *           - transform of the model point cloud
 *           - display registration metrics
 */
function AlignmentToolBar({
  showResetTransformation,
}: AlignmentToolBarProps): JSX.Element | null {
  const {
    modelCloudTransformReset,
    centerCameraOnPerspective,
    showRegistrationResultsDialog,
  } = useAlignmentOverlay();
  const perspective = useAppSelector(selectCurrentPerspective);
  const { registrationQuality } = useRegistrationContext();
  const metricScaleIcon = getMetricScaleIcon(registrationQuality);
  const lastRegistrationPoseUsed = useAppSelector(
    selectLastRegistrationPoseUsed,
  );

  return (
    <Toolbar>
      <ToggleButtonGroup orientation="vertical">
        {metricScaleIcon !== MetricsScaleUnknown && (
          <Tooltip
            title={
              lastRegistrationPoseUsed
                ? "Automatic Registration Quality"
                : "Registration Metrics are no longer valid because manual adjustments were made."
            }
            placement="left"
          >
            <ToolButton
              onClick={() => {
                Analytics.track<ShowPairwiseRegistrationReportEventProperties>(
                  EventType.showPairwiseRegistrationReport,
                  {
                    registrationQuality,
                  },
                );
                showRegistrationResultsDialog.emit();
              }}
              disabled={!lastRegistrationPoseUsed}
            >
              <FaroSvgIcon
                inheritViewBox={true}
                sx={{ width: "24px", height: "24px" }}
                source={metricScaleIcon}
              />
            </ToolButton>
          </Tooltip>
        )}
        {showResetTransformation && <AlignToolDivider />}
        {showResetTransformation && (
          <Tooltip title="Reset Transformation" placement="left">
            <ToolButton
              onClick={() => {
                modelCloudTransformReset.emit();
              }}
            >
              <ResetIcon sx={{ width: "24px", height: "24px" }} />
            </ToolButton>
          </Tooltip>
        )}
        <AlignToolDivider />
        <Tooltip title="Recenter View" placement="left">
          <ToolButton
            onClick={() => {
              Analytics.track(EventType.resetPairwiseRegistrationCamera);
              centerCameraOnPerspective.emit(perspective ?? null);
            }}
          >
            <ZoomAllIcon sx={{ width: "24px", height: "24px" }} />
          </ToolButton>
        </Tooltip>
      </ToggleButtonGroup>
    </Toolbar>
  );
}

/**
 * @returns a divider line for the alignment tool bar
 */
export function AlignToolDivider(): JSX.Element {
  return (
    <Divider
      orientation="horizontal"
      sx={{
        bgcolor: "white",
        opacity: 0.3,
        mx: 0.5,
      }}
    />
  );
}
