import { EventType } from "@/analytics/analytics-events";
import { useUpdateProjectMetadata } from "@/components/common/project-provider/use-project-metadata";
import { ToolBreadcrumbs } from "@/components/ui/tool-breadcrumbs";
import { useOpenAccountAndSecurity } from "@/hooks/use-open-account-and-security";
import { RegistrationContextProvider } from "@/registration-tools/common/registration-context";
import { selectRegistrationAlgorithmSettings } from "@/registration-tools/common/store/registration-parameters/registration-parameters-selectors";
import {
  selectPointCloudTransform,
  selectRegistrationJobId,
} from "@/registration-tools/common/store/registration-selectors";
import {
  initiateRegistrationJob,
  requestRegistrationJob,
  resetRegistrationJob,
} from "@/registration-tools/common/store/registration-slice";
import { computeInitialTransformationFormatted } from "@/registration-tools/utils/registration";
import { runtimeConfig } from "@/runtime-config";
import { selectActiveArea } from "@/store/selections-selectors";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  redirectToDashboardProjectPage,
  redirectToViewer,
} from "@/utils/redirects";
import {
  FaroDialog,
  HeaderBar,
  selectChildDepthFirst,
  selectDashboardUrl,
  selectIElementWorldTransform,
  useLocalStorage,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import { HelpBanner } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import { useAuthContext } from "@faro-lotv/gate-keepers";
import {
  IElementSectionDataSession,
  isIElementGenericDataset,
} from "@faro-lotv/ielement-types";
import {
  RegisteredUserInfo,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MergeAndPublishDialog } from "../common/merge-and-publish-dialog";
import { selectDefaultThresholdSet } from "../common/registration-report/registration-thresholds";
import { ThresholdSetProvider } from "../common/registration-report/threshold-set-context";
import { selectMainPointCloudFromDataSession } from "../common/select-main-point-clouds";
import { applyPairwiseRegistration } from "../utils/apply-pairwise-registration";
import { PairwiseRegistrationBar } from "./pairwise-registration-bar";
import { PairwiseRegistrationCanvas } from "./pairwise-registration-canvas";
import { PairwiseRegistrationQuickHelp } from "./pairwise-registration-quick-help";
import { PairwiseRegistrationTaskTracker } from "./pairwise-registration-task-tracker";
import { useAlignmentOverlay } from "@/registration-tools/common/alignment-overlay-context";
import { isEqual } from "lodash";

export type PairwiseRegistrationUiProps = {
  /** The Section.DataSession element which serves as reference (already aligned). */
  refCloudDataSession: IElementSectionDataSession;

  /** The Section.DataSession element which needs to be adjusted. */
  modelCloudDataSession: IElementSectionDataSession;

  /**
   * Optional, Enables redirect to the dashboard after the workflow finishes if not null.
   * If not null, the string is used to call redirectToDashboardProjectPage which allows
   * to define the target tab of the project details in the dashboard.
   * If null or not assigned the viewer will be opened after the alignment is saved.
   */
  dashboardRedirect?: string;

  /** The currently logged in user. */
  currentUser: RegisteredUserInfo;
};

/**
 * @returns UI with Canvas and Utility Bars
 */
export function PairwiseRegistrationUi({
  refCloudDataSession,
  modelCloudDataSession,
  dashboardRedirect,
  currentUser,
}: PairwiseRegistrationUiProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const { openToast } = useToast();
  const { requestLogin, logout } = useAuthContext();

  const openAccountAndSecurity = useOpenAccountAndSecurity();

  const [quickHelpFirstVisit, setQuickHelpFirstVisit] = useLocalStorage(
    "isPairwiseFirstVisit",
    true,
  );

  const [isQuickHelpOpen, setIsQuickHelpOpen] = useState(quickHelpFirstVisit);

  const [isHelpBannerOpen, setIsHelpBannerOpen] = useState(true);
  const [isMergeDialogOpen, setIsMergeDialogOpen] = useState(false);

  const { initialModelTransform } = useAlignmentOverlay();

  const refCloudId = refCloudDataSession.id;
  const modelCloudId = modelCloudDataSession.id;

  const { projectApiClient: projectApi } = useApiClientContext();

  // Non-blocking: Load project meta-data, e.g. to get the project name for the breadcrumbs
  useUpdateProjectMetadata(projectApi.projectId);

  const area = useAppSelector(selectActiveArea(refCloudDataSession));

  const refCloudDataSetElement = useAppSelector(
    selectChildDepthFirst(refCloudDataSession, isIElementGenericDataset, 1),
  );
  assert(
    refCloudDataSetElement,
    `reference data session ${refCloudDataSession.id} does not have a dataset as a child`,
  );
  const modelCloudDataSetElement = useAppSelector(
    selectChildDepthFirst(modelCloudDataSession, isIElementGenericDataset, 1),
  );
  assert(
    modelCloudDataSetElement,
    `model data session ${modelCloudDataSession.id} does not have a dataset as a child`,
  );

  const pointCloudNames = useMemo(() => {
    return [refCloudDataSession.name, modelCloudDataSession.name];
  }, [refCloudDataSession.name, modelCloudDataSession.name]);

  const pointCloudLabels = ["Reference", "Adjustable"];

  const regJobId = useAppSelector(selectRegistrationJobId);

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const pcTransformArray = useAppSelector(selectPointCloudTransform);
  const modelPointCloudStream = useAppSelector(
    selectMainPointCloudFromDataSession(modelCloudDataSession),
  );
  assert(modelPointCloudStream, "The model point cloud stream must exist");

  const activeRefPointCloud = useAppSelector(
    selectMainPointCloudFromDataSession(refCloudDataSession),
  );
  assert(activeRefPointCloud, "The reference point cloud stream must exist");
  const registrationAlgorithmSettings = useAppSelector(
    selectRegistrationAlgorithmSettings,
  );

  /**
   * Redirects the user to the viewer or the dashboard project page
   *
   * @param dashboardUrl optional url that is forwarded to the
   *  redirectToDashboardProjectPage function, if dashboardRedirect is active
   */
  const redirect = useCallback(
    (dashboardUrl?: string): void => {
      if (dashboardRedirect === undefined) {
        redirectToViewer(projectApi.projectId);
      } else {
        redirectToDashboardProjectPage(
          projectApi.projectId,
          dashboardRedirect,
          dashboardUrl,
        );
      }
    },
    [dashboardRedirect, projectApi],
  );

  const { registrationApiClient: registrationClient } = useApiClientContext();
  assert(
    registrationClient,
    "The pairwise registration can only be used when the registration URL is defined",
  );

  // Start the registration job and pass parameters
  const startRegistration = useCallback(async (): Promise<
    string | undefined
  > => {
    dispatch(requestRegistrationJob());

    try {
      if (!pcTransformArray) {
        return;
      }

      const pcRefArray = selectIElementWorldTransform(activeRefPointCloud.id)(
        store.getState(),
      ).worldMatrix;
      const matrix2darray = computeInitialTransformationFormatted(
        pcRefArray,
        pcTransformArray,
      );

      const response = await registrationClient.startRegistration({
        referenceCloudIds: [refCloudId],
        modelCloudIds: [modelCloudId],
        algorithmSettings: registrationAlgorithmSettings,
        modelToRefWorldTransform: matrix2darray,
      });
      dispatch(initiateRegistrationJob(response.jobId));
      return response.jobId;
    } catch (e) {
      if (e instanceof Error) {
        dispatch(resetRegistrationJob());
        openToast({
          title: "Registration failed",
          message: e.message,
          variant: "error",
        });
      }
    }
  }, [
    dispatch,
    activeRefPointCloud,
    modelCloudId,
    openToast,
    pcTransformArray,
    refCloudId,
    registrationAlgorithmSettings,
    store,
    registrationClient,
  ]);

  // Cancel the currently running registration job
  const cancelRegistration = useCallback(async (): Promise<
    string | undefined
  > => {
    if (!regJobId) {
      return;
    }

    try {
      const response = await registrationClient.cancelRegistration({
        jobId: regJobId,
        referenceCloudIds: [refCloudId],
        modelCloudIds: [modelCloudId],
      });
      return response.terminatedJobId;
    } catch (e) {
      if (e instanceof Error) {
        openToast({
          title: e.message,
          variant: "error",
        });
      }
    }
  }, [refCloudId, modelCloudId, openToast, regJobId, registrationClient]);
  const handlePageHideEvent = (): void => {
    cancelRegistration();
  };

  // Kills running registration tasks when users closes tab or browser.
  useEffect(() => {
    window.addEventListener("pagehide", handlePageHideEvent);
    window.addEventListener("beforeunload", handlePageHideEvent);

    return () => {
      window.removeEventListener("beforeunload", handlePageHideEvent);
      window.removeEventListener("pagehide", handlePageHideEvent);
    };
  });

  const dashboardUrl = useAppSelector(selectDashboardUrl);

  const defaultThresholdSet = useAppSelector(
    selectDefaultThresholdSet([activeRefPointCloud, modelPointCloudStream]),
  );

  const redirectToDashboardOrViewer = useCallback(() => {
    cancelRegistration().then(() => {
      if (dashboardRedirect === undefined) {
        redirectToViewer(projectApi.projectId);
      } else {
        redirectToDashboardProjectPage(
          projectApi.projectId,
          dashboardRedirect,
          selectDashboardUrl(store.getState()),
        );
      }
    });
  }, [dashboardRedirect, projectApi, store, cancelRegistration]);

  return (
    <>
      <FaroDialog
        open={isCancelDialogOpen}
        title="Leave the pairwise registration?"
        onConfirm={redirectToDashboardOrViewer}
        onCancel={() => setIsCancelDialogOpen(false)}
      >
        Any changes made will be lost. Running automatic registrations will be
        canceled.
      </FaroDialog>
      <RegistrationContextProvider>
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <HeaderBar
            showProfileButton={true}
            content={
              <ToolBreadcrumbs
                toolName="Pairwise Registration"
                onRedirectToViewerClicked={() => {
                  Analytics.track(EventType.abortPairwiseRegistrationWorkflow);
                  if (isEqual(initialModelTransform, pcTransformArray)) {
                    redirectToDashboardOrViewer();
                  } else {
                    setIsCancelDialogOpen(true);
                  }
                }}
              />
            }
            userDisplayInfo={currentUser}
            links={runtimeConfig.externalLinks}
            onLogInClick={requestLogin}
            isQuickHelpOpen={isQuickHelpOpen}
            onQuickHelpClick={() => {
              setQuickHelpFirstVisit(false);
              setIsQuickHelpOpen(!isQuickHelpOpen);
            }}
            onLogOutClick={logout}
            onAccountAndSecurityClick={openAccountAndSecurity}
          />
          <PairwiseRegistrationBar
            areaName={area?.name}
            pointCloudNames={pointCloudNames}
            pointCloudLabels={pointCloudLabels}
            onRegistrationStartClick={startRegistration}
            onRegistrationCancelClick={cancelRegistration}
            onConfirmClick={() => {
              // Alignment was saved, redirect user back
              if (dashboardRedirect === undefined) {
                if (!pcTransformArray) {
                  throw Error("No transform found for model cloud.");
                }
                applyPairwiseRegistration(
                  projectApi,
                  modelPointCloudStream,
                  pcTransformArray,
                ).then(() => {
                  Analytics.track(EventType.finishPairwiseRegistrationWorkflow);
                  redirectToViewer(projectApi.projectId);
                });
              } else {
                setIsMergeDialogOpen(true);
              }
            }}
          />
          {dashboardRedirect !== undefined && (
            <MergeAndPublishDialog
              isOpen={isMergeDialogOpen}
              onClose={() => setIsMergeDialogOpen(false)}
              onBeforePublish={async () => {
                if (!pcTransformArray) {
                  throw Error("No transform found for model cloud.");
                }
                await applyPairwiseRegistration(
                  projectApi,
                  modelPointCloudStream,
                  pcTransformArray,
                );
              }}
              onPublish={() => {
                Analytics.track(EventType.finishPairwiseRegistrationWorkflow);
                redirect(dashboardUrl);
              }}
              pointCloudDatasets={[
                refCloudDataSetElement,
                modelCloudDataSetElement,
              ]}
            />
          )}
          <Stack
            sx={{
              height: "inherit",
              width: "100%",
              overflow: "hidden",
              position: "relative",
            }}
            direction="row"
          >
            {isHelpBannerOpen && (
              <Box
                component="div"
                display="flex"
                justifyContent="center"
                sx={{ position: "absolute", zIndex: 1, width: "100%" }}
              >
                <HelpBanner
                  sx={{
                    display: "inline-block",
                    margin: 1,
                  }}
                >
                  Do a visual registration first, then press "Run automatic
                  registration" to improve the overlay
                </HelpBanner>
              </Box>
            )}
            <ThresholdSetProvider defaultThresholdSet={defaultThresholdSet}>
              <PairwiseRegistrationTaskTracker
                activeRefPointCloud={activeRefPointCloud}
              />
            </ThresholdSetProvider>
            <PairwiseRegistrationCanvas
              activeModelPointCloud={modelPointCloudStream}
              activeRefPointCloud={activeRefPointCloud}
              onVisualRegistration={() => setIsHelpBannerOpen(false)}
            />
            {/* Drawer does not resize canvas*/}
            {isQuickHelpOpen && (
              <PairwiseRegistrationQuickHelp
                onClose={() => {
                  setQuickHelpFirstVisit(false);
                  setIsQuickHelpOpen(false);
                }}
              />
            )}
          </Stack>
        </Stack>
      </RegistrationContextProvider>
    </>
  );
}
