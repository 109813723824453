import { useActionBarAnchorPoint } from "@/hooks/use-actionbar-anchor-point";
import { PointCloudObject } from "@/object-cache";
import { selectActiveAnalysis } from "@/store/point-cloud-analysis-tool-selector";
import {
  PointCloudAnalysis,
  setActiveAnalysis,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  ColormapPoints,
  getLotvMath,
  selectPointsByPolygon,
} from "@faro-lotv/lotv";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Color, Plane, Vector3 } from "three";
import { AnalysisActionBar } from "./analysis-action-bar";

type ColorMapAnalysisRendererProps = {
  /** Point cloud object used by analysis */
  pointCloud: PointCloudObject;

  /** The point cloud analysis to be rendered */
  analysis: PointCloudAnalysis;
};

/** @returns The renderer of a colormap analysis */
export function ColormapAnalysisRenderer({
  pointCloud,
  analysis,
}: ColorMapAnalysisRendererProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  const [colormapAnalysis, setColormapAnalysis] = useState<ColormapPoints>();

  const polygon = useMemo(
    () => analysis.polygonSelection.map((p) => new Vector3().fromArray(p)),
    [analysis.polygonSelection],
  );

  useEffect(() => {
    // define async colormap analysis creation function
    const create = async (): Promise<void> => {
      const colormapPoints = await createColormapPoints(polygon, pointCloud);
      setColormapAnalysis(colormapPoints);
    };
    // create colormap analysis
    create().catch(console.error);
  }, [pointCloud, polygon]);

  // Anchor point for the action bar
  const anchorPoint = useActionBarAnchorPoint(polygon);
  const activeAnalysis = useAppSelector(selectActiveAnalysis);

  const onClickAnalysis = useCallback(
    (e: PointerEvent) => {
      e.stopPropagation();
      if (activeAnalysis?.id === analysis.id) {
        dispatch(setActiveAnalysis(undefined));
      } else {
        dispatch(setActiveAnalysis(analysis));
      }
    },
    [activeAnalysis?.id, analysis, dispatch],
  );

  if (!colormapAnalysis) return null;

  return (
    <>
      <primitive object={colormapAnalysis} onClick={onClickAnalysis} />
      {activeAnalysis?.id === analysis.id && (
        <AnalysisActionBar anchorPoint={anchorPoint} />
      )}
    </>
  );
}

/**
 * Create a colormap analysis from a polygon selection
 *
 * @param polygon Vertices of a closed polygon, where last vertex is connected the first one
 * @param pointCloud Point cloud object
 * @returns The colormap analysis object, undefined when the selection is invalid
 */
async function createColormapPoints(
  polygon: Vector3[],
  pointCloud: PointCloudObject,
): Promise<ColormapPoints | undefined> {
  const selectedPoints = await selectPointsByPolygon(pointCloud, {
    polygon,
    planeThreshold: 0.1,
    maxNumberOfPoints: 1_000_000,
    minPointDensity: 3,
  });
  if (!selectedPoints) return;

  const lotvMath = await getLotvMath();
  const fitPlane = lotvMath.fitPlane(selectedPoints);
  if (!fitPlane) return;
  const plane = new Plane();
  plane.setFromNormalAndCoplanarPoint(
    new Vector3(fitPlane.normal.x, fitPlane.normal.y, fitPlane.normal.z),
    new Vector3(fitPlane.point.x, fitPlane.point.y, fitPlane.point.z),
  );

  // TODO: the colors used in the colormap are temporarily hardcoded, but should be configurable.
  // Will be addressed by https://faro01.atlassian.net/browse/CADBIM-681
  const colormap = [
    { value: 0.0, color: new Color("blue") },
    { value: 0.2, color: new Color("cyan") },
    { value: 0.5, color: new Color("lime") },
    { value: 0.8, color: new Color("yellow") },
    { value: 1.0, color: new Color("red") },
  ];
  const colormapPoints = new ColormapPoints(selectedPoints, plane, colormap);
  colormapPoints.pointSize = 0.04;
  return colormapPoints;
}
