import {
  CreateAnnotationEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import {
  EmbeddedToolbar,
  EmbeddedToolbarButton,
} from "@/components/ui/embedded-toolbar";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { ComponentsToDisplay } from "@/store/measurement-tool-slice";
import {
  ANNOTATION_ZINDEX_RANGE_MAP,
  AnnotationIcon,
  Checkmark2Icon,
  CopyFileIcon,
  Data3dIcon,
  DeleteIcon,
  MeasureShowAxesIcon,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { MutableRefObject, useRef, useState } from "react";
import { Vector3 } from "three";
import { AppAwareHtml } from "../app-aware-html";
import { MEASURE_ANIMATION_LENGTH } from "./measure-constants";

/** Time (ms) that the copied message box will be shown for, after which it will stop rendering */
const COPIED_POPUP_LIFE_TIME = 2000;

// Max value for z-index, since the measurements' labels created with Html from drei use a computed z-index
// that could be very big and would occlude the message.
const MAX_ZINDEX_VALUE = 2147483647;

declare type MeasureActionBarProp = {
  /** 3D point to anchor the html component to. */
  anchorPoint: Vector3;

  /** Is this measurement active. */
  isActive: boolean;

  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;

  /** Current selected unit of measure used to display the distances */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** Which components are display in a measurement. Undefined is not a measurement. */
  componentsToDisplay?: ComponentsToDisplay;

  /** Function to call when the create annotation button is clicked  */
  onCreateAnnotation?(): void;

  /** Function to call when the copy to clipboard button is clicked. */
  onCopyToClipboard(): void;

  /** Function to call when the delete measurement button is clicked. */
  onDeleteMeasurement?(): void;

  /** Function to toggle between the available unit of measures */
  onToggleUnitOfMeasure(): void;

  /** Function to toggle between the 3D or components measurements */
  onToggleMeasurementComponentsToDisplay?(): void;
};

/**
 * @returns an action bar with an option to copy the measurements and an option to delete the measurement.
 */
export function MeasureActionBar({
  anchorPoint,
  isActive,
  parentRef,
  unitOfMeasure,
  componentsToDisplay,
  onCreateAnnotation,
  onCopyToClipboard,
  onDeleteMeasurement,
  onToggleUnitOfMeasure,
  onToggleMeasurementComponentsToDisplay,
}: MeasureActionBarProp): JSX.Element | null {
  const buttonSize = "36px";
  const actionBarPointOffset = "5px";

  const theme = useTheme();

  const { canWriteAnnotations } = useAnnotationPermissions();

  // Check to know if the copied to clipboard message is rendered or not
  const [isCopiedPopupShown, setIsCopiedPopupShown] = useState(false);

  const clipboardBtnRef = useRef<HTMLButtonElement>(null);

  const pointerEvents = isActive ? "auto" : "none";

  return (
    <AppAwareHtml
      portal={parentRef}
      position={anchorPoint}
      zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
      eps={-1}
      style={{
        pointerEvents,
        // Add offset to put the container in the top left of the anchorPoint
        transform: `translate(calc(-100% - ${actionBarPointOffset}), calc(-100% - ${actionBarPointOffset}))`,
      }}
    >
      <EmbeddedToolbar
        isActive={isActive}
        sx={{
          transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
          backgroundColor: theme.palette.gray999,
        }}
      >
        {canWriteAnnotations && (
          <Tooltip title="Create annotation" placement="bottom">
            <EmbeddedToolbarButton
              aria-label="create annotation"
              buttonSize={buttonSize}
              value="Create annotation"
              onClick={(ev) => {
                if (onCreateAnnotation) {
                  ev.stopPropagation();

                  Analytics.track<CreateAnnotationEventProperties>(
                    EventType.createAnnotation,
                    { shape: "measurement" },
                  );

                  onCreateAnnotation();
                }
              }}
            >
              <AnnotationIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
        )}
        <Tooltip title="Change Unit" placement="bottom">
          <EmbeddedToolbarButton
            aria-label="change unit"
            buttonSize={buttonSize}
            value="Change Unit"
            onClick={(ev) => {
              ev.stopPropagation();
              onToggleUnitOfMeasure();
            }}
          >
            <Typography>{unitOfMeasure === "metric" ? "m" : "ft"}</Typography>
          </EmbeddedToolbarButton>
        </Tooltip>
        <Tooltip
          title={isCopiedPopupShown ? <CopiedTooltip /> : "Copy to Clipboard"}
          PopperProps={{
            sx: {
              zIndex: MAX_ZINDEX_VALUE,
            },
          }}
          placement="bottom"
          open={isCopiedPopupShown ? true : undefined}
          leaveDelay={isCopiedPopupShown ? COPIED_POPUP_LIFE_TIME / 2 : 0}
        >
          <EmbeddedToolbarButton
            aria-label="copy to clipboard"
            buttonSize={buttonSize}
            value="Copy to clipboard"
            ref={clipboardBtnRef}
            onClick={(ev) => {
              ev.stopPropagation();
              setIsCopiedPopupShown(true);

              // Set timeout to close the copied to clipboard message
              setTimeout(
                () => setIsCopiedPopupShown(false),
                COPIED_POPUP_LIFE_TIME,
              );

              onCopyToClipboard();
            }}
          >
            <CopyFileIcon />
          </EmbeddedToolbarButton>
        </Tooltip>
        <ToggleComponentsButton
          componentsToDisplay={componentsToDisplay}
          onToggleMeasurementComponentsToDisplay={
            onToggleMeasurementComponentsToDisplay
          }
          buttonSize={buttonSize}
        />
        <Tooltip
          title="Delete"
          placement="bottom"
          PopperProps={{
            sx: {
              zIndex: MAX_ZINDEX_VALUE,
            },
          }}
        >
          <EmbeddedToolbarButton
            aria-label="delete measurement"
            buttonSize={buttonSize}
            value="Delete"
            onClick={(ev) => {
              ev.stopPropagation();
              onDeleteMeasurement?.();
            }}
          >
            <DeleteIcon sx={{ color: theme.palette.white }} />
          </EmbeddedToolbarButton>
        </Tooltip>
      </EmbeddedToolbar>
    </AppAwareHtml>
  );
}

/**
 * @returns the tooltip message after the user copies the measurement to the clipboard.
 */
function CopiedTooltip(): JSX.Element {
  return (
    <Stack direction="row" gap={1}>
      <Checkmark2Icon sx={{ width: "20px", height: "20px" }} />

      <Typography color="gray100" fontSize="14px">
        Copied to clipboard
      </Typography>
    </Stack>
  );
}

/** Parameters for ToggleComponentsButton component. */
declare type ToggleComponentsButtonProps = Pick<
  MeasureActionBarProp,
  "componentsToDisplay" | "onToggleMeasurementComponentsToDisplay"
> & {
  /** Size of the button */
  buttonSize: string;
};

/**
 * @returns the button for toggling between displaying 3D and components distances.
 */
function ToggleComponentsButton({
  componentsToDisplay,
  onToggleMeasurementComponentsToDisplay,
  buttonSize,
}: ToggleComponentsButtonProps): JSX.Element {
  const theme = useTheme();

  let tooltipForToggleMeasurementComponents = "";
  if (onToggleMeasurementComponentsToDisplay) {
    tooltipForToggleMeasurementComponents =
      componentsToDisplay === ComponentsToDisplay.heightAndHorizontal
        ? "Display 3D distance"
        : "Display vertical and horizontal distances";
  } else {
    tooltipForToggleMeasurementComponents =
      "Vertical and horizontal distances are only available for 2-point measurements";
  }

  return (
    <Tooltip
      title={tooltipForToggleMeasurementComponents}
      placement="bottom"
      PopperProps={{
        sx: {
          zIndex: MAX_ZINDEX_VALUE,
        },
      }}
    >
      <EmbeddedToolbarButton
        aria-label="toggle measurements"
        buttonSize={buttonSize}
        value="Toggle measurements"
        onClick={(ev) => {
          ev.stopPropagation();
          if (onToggleMeasurementComponentsToDisplay) {
            onToggleMeasurementComponentsToDisplay();
          }
        }}
        disabled={!onToggleMeasurementComponentsToDisplay}
      >
        {componentsToDisplay === ComponentsToDisplay.heightAndHorizontal ? (
          <Data3dIcon sx={{ color: theme.palette.white }} />
        ) : (
          <MeasureShowAxesIcon sx={{ color: theme.palette.white }} />
        )}
      </EmbeddedToolbarButton>
    </Tooltip>
  );
}
