import { RootState } from "@/store/store";
import { GUID } from "@faro-lotv/foundation";
import { IElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import { isRevisionScanEntity } from "@faro-lotv/service-wires";
import {
  selectPointCloudStreamForScanEntity,
  selectRevisionEntity,
} from "../revision-selectors";

/**
 * @returns the selected entities' id in the scan tree
 * @param state current app state
 */
export function selectSelectedEntityId(state: RootState): GUID | undefined {
  return state.dataPreparationUi.selectedEntityId;
}

/**
 * @returns the point cloud stream for the selected entity
 * @param state current app state
 */
export function selectPointCloudStreamForSelectedEntity(
  state: RootState,
): IElementGenericPointCloudStream | undefined {
  if (!state.dataPreparationUi.selectedEntityId) {
    return;
  }

  const selectedEntity = selectRevisionEntity(
    state.dataPreparationUi.selectedEntityId,
  )(state);

  if (!selectedEntity || !isRevisionScanEntity(selectedEntity)) {
    return;
  }

  return selectPointCloudStreamForScanEntity(selectedEntity)(state);
}

/**
 * @returns the point cloud stream for the hovered entity
 * @param state current app state
 */
export function selectPointCloudStreamForHoveredEntity(
  state: RootState,
): IElementGenericPointCloudStream | undefined {
  if (!state.dataPreparationUi.hoveredEntityId) {
    return;
  }

  const hoveredEntity = selectRevisionEntity(
    state.dataPreparationUi.hoveredEntityId,
  )(state);

  if (!hoveredEntity || !isRevisionScanEntity(hoveredEntity)) {
    return;
  }

  return selectPointCloudStreamForScanEntity(hoveredEntity)(state);
}
