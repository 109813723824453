import {
  UseHorizontalResizeParams,
  neutral,
  useHorizontalResize,
} from "@faro-lotv/flat-ui";
import { Box, GlobalStyles, Stack, SxProps } from "@mui/material";
import { PropsWithChildren, RefObject } from "react";

type ResizeContainerProps = UseHorizontalResizeParams & {
  /** Additional styles to apply to the root container */
  sx?: SxProps;
};

/**
 * @returns a container that is horizontally resizable with a bar on one side
 */
export function HorizontalResizeContainer({
  children,
  sx,
  ...useResizeParams
}: PropsWithChildren<ResizeContainerProps>): JSX.Element {
  const { handleSide } = useResizeParams;

  const { width, isResizing, handleRef } = useHorizontalResize(useResizeParams);

  const handleBorderStyle = `1px solid ${neutral[200]}`;

  return (
    <Stack
      direction={handleSide === "right" ? "row" : "row-reverse"}
      sx={{
        width,
        flexShrink: 0,
        zIndex: 1,
        borderRight: handleSide === "right" ? handleBorderStyle : undefined,
        borderLeft: handleSide === "left" ? handleBorderStyle : undefined,
        ...sx,
      }}
    >
      {children}

      <ResizeHandle shouldShowHandle={isResizing} handleRef={handleRef} />
    </Stack>
  );
}

interface IResizeHandleProps {
  /** Indicator if the handle should be visible */
  shouldShowHandle: boolean;

  /** Ref to the handler */
  handleRef: RefObject<HTMLElement>;
}

/**
 * While being rendered, this will globally override the cursor to be a col-resize
 *
 * Putting into dedicated variable in order to avoid unnecessary re-rendering
 * As suggested on https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override
 */
const GlobalResizeCursor = (
  <GlobalStyles
    styles={{
      body: {
        cursor: "col-resize",
      },
    }}
  />
);

/**
 * @returns Handle bar that will become visible while it is being hovered and `shouldShowHandle` is `true`
 * Will invoke the onMouseDown when mouse button is pressed on it
 */
function ResizeHandle({
  shouldShowHandle,
  handleRef,
}: IResizeHandleProps): JSX.Element {
  const handleStyle = {
    backgroundColor: "black",
    opacity: 0.3,
  };

  return (
    <Box
      component="div"
      ref={handleRef}
      sx={[
        {
          minWidth: 3,
          cursor: "col-resize",

          "&:hover": handleStyle,
        },
        // Keep handle visible while dragging as the mouse can leave the handle during that action
        shouldShowHandle && handleStyle,
      ]}
    >
      {/*
        Keep the resize cursor visible while dragging as hover affect will not take affect
        while cursor moves of the element
      */}
      {shouldShowHandle && GlobalResizeCursor}
    </Box>
  );
}
