import { EventType } from "@/analytics/analytics-events";
import { ElementIconType } from "@/components/ui/icons";
import {
  selectActiveArea,
  selectActiveAreaOrThrow,
} from "@/store/selections-selectors";
import { RootState } from "@/store/store";
import { redirectToRegistrationTool } from "@/utils/redirects";
import {
  selectAncestor,
  selectChildrenDepthFirst,
  selectIElement,
  selectIsPointCloudAligned,
  selectNumberOfPointCloudsOnFloor,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  GUID,
  IElement,
  isIElementSectionDataSession,
  isValidPose,
} from "@faro-lotv/ielement-types";
import {
  ContextMenuAction,
  ContextMenuActionType,
  NestedMenuHandlerFnArgs,
  NestedMenuObject,
} from "../action-types";

export const REGISTER_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.register,
  label: "Register to...",
  icon: ElementIconType.AlignToPointCloudIcon,
  nestedMenuProps: constructNestedMenu,
  disabledMessageForNode: (iElement, state) => {
    if (selectIsRegisterActionDisabled(iElement)(state)) {
      return "Align at least one other point cloud to the sheet first.";
    }
  },
};

/**
 * @param elementId id of the element that is clicked for context menu
 * @returns potential reference pointclouds for manual alignment or null
 */
function selectReferencePointClouds(elementId: GUID) {
  return (state: RootState): IElement[] | undefined => {
    const pointCloud = selectIElement(elementId)(state);
    const floor = selectActiveArea(pointCloud)(state);
    const alignedPCs = selectChildrenDepthFirst(
      floor,
      (pointCloud) =>
        isIElementSectionDataSession(pointCloud) &&
        selectIsPointCloudAligned(pointCloud)(state),
    )(state).filter((pc) => pc.id !== elementId);

    if (!alignedPCs.length) return;
    return alignedPCs;
  };
}

/**
 * @param iElement The section to determine if the section should be disabled.
 * @returns True if the register action needs to be disabled for the section, else false.
 */
function selectIsRegisterActionDisabled(iElement: IElement) {
  return (state: RootState): boolean => {
    // If any of the other point clouds in the section has a pose(=> if already aligned) then the option can be enabled
    const area = selectActiveAreaOrThrow(iElement)(state);
    const alignedPointClouds = selectNumberOfPointCloudsOnFloor(
      area,
      true,
    )(state);
    const cloudSection = selectAncestor(
      iElement,
      isIElementSectionDataSession,
    )(state);

    // the registration option is disabled if none of the points clouds are aligned
    // => atleast one of the point clouds should be aligned to the sheet
    // (OR)
    // if there is only one aligned point cloud and you click on the same point cloud
    return (
      alignedPointClouds === 0 ||
      (alignedPointClouds === 1 && isValidPose(cloudSection?.pose))
    );
  };
}

function constructNestedMenu(
  elementId: GUID,
  state: RootState,
): NestedMenuObject {
  const refPCList = selectReferencePointClouds(elementId)(state);

  const options = refPCList
    ? refPCList.map((refPC) => ({
        id: refPC.id,
        label: refPC.name,
        icon: ElementIconType.DataSession,
        onClick({ nestedMenuItemId, projectApi }: NestedMenuHandlerFnArgs) {
          if (!nestedMenuItemId) return;
          Analytics.track(EventType.startPairwiseRegistrationWorkflow);
          redirectToRegistrationTool({
            projectId: projectApi.projectId,
            pointCloudId1: nestedMenuItemId,
            pointCloudId2: elementId,
          });
        },
      }))
    : [];

  return {
    title: "Select Reference point cloud",
    options,
    shouldShowNestedMenu(elementID: GUID, state: RootState) {
      return !!selectReferencePointClouds(elementId)(state);
    },
  };
}
