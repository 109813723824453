import { selectIsProjectEmpty } from "@/store/selections-selectors";
import { useAppStore } from "@/store/store-hooks";
import { compareDateTimes } from "@faro-lotv/foundation";
import {
  IElementType,
  IElementTypeHint,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  BackgroundTaskState,
  isBackgroundTaskActive,
  isBackgroundTaskInfoProgress,
  ProgressApiSupportedTaskTypes,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";

const DEFAULT_INTERVAL = 5000;

export enum SceneConversionState {
  Failed = "failed",
  Running = "running",
  Ok = "ok",
}

/**
 * @returns The current state of a scene conversion, polled every [DEFAULT_INTERVAL] milliseconds
 */
export function useSceneConversionState(): SceneConversionState | undefined {
  const [sceneConversionState, setSceneConversionState] = useState<
    SceneConversionState | undefined
  >(undefined);
  const { progressApiClient: progressApi, projectApiClient: projectApi } =
    useApiClientContext();

  const store = useAppStore();

  useEffect(() => {
    const controller = new AbortController();
    let timeoutId: number | undefined;
    async function checkConversionProgress(): Promise<void> {
      const appState = store.getState();
      const isProjectEmpty = selectIsProjectEmpty(appState);

      const root = await projectApi.getRootIElement().catch(() => undefined);
      if (controller.signal.aborted) {
        return;
      }

      const sceneConversionTasks = await progressApi
        .requestAllProgress({
          signal: controller.signal,
          direction: "lastToFirst",
          taskType: ProgressApiSupportedTaskTypes.sceneConversion,
        })
        .catch(() => []);

      // The signal could have been aborted while waiting for the progress API response
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (controller.signal.aborted) {
        return;
      }

      // Sort from the newest to the latest, because lastToFirst sorts the pages but not the tasks
      sceneConversionTasks.sort((a, b) =>
        compareDateTimes(b.createdAt, a.createdAt),
      );
      // Take latest scene conversion
      const sceneConversionTask = sceneConversionTasks.at(0);

      // Collect all sceneConversions that already succeded
      const succededSceneConversionTasks = sceneConversionTasks.filter(
        (t) => t.status.state === BackgroundTaskState.succeeded,
      );
      const hasAlreadySucceded = succededSceneConversionTasks.length > 0;
      const canShowTheProject = hasAlreadySucceded || !isProjectEmpty;

      // If the scene conversion is running and we already succeded once,
      // then this is an update and we go to the app
      const isCurrentSceneConversionRunning =
        !!(
          sceneConversionTask?.status.state &&
          isBackgroundTaskActive(sceneConversionTask.status.state)
        ) || isBackgroundTaskInfoProgress(sceneConversionTask?.status);
      if (isCurrentSceneConversionRunning) {
        setSceneConversionState(
          canShowTheProject
            ? SceneConversionState.Ok
            : SceneConversionState.Running,
        );
        return;
      }

      // If the flag is defined, but we already succeded in the past, this is an update
      const isUploading = !!root?.labels?.find(
        (label) =>
          label.name === "SceneOperation" && label.labelType === "initializing",
      );
      if (isUploading) {
        setSceneConversionState(
          canShowTheProject
            ? SceneConversionState.Ok
            : SceneConversionState.Running,
        );
        return;
      }

      // If the current task failed and we already succeded in the past, this was a failed update
      if (sceneConversionTask?.status.state === BackgroundTaskState.failed) {
        setSceneConversionState(
          canShowTheProject
            ? SceneConversionState.Ok
            : SceneConversionState.Failed,
        );
        return;
      }

      // Here our current scene conversion task already succeded
      // If there's more than one succeeded conversion, this is an update, we can go to the app
      if (succededSceneConversionTasks.length > 1) {
        setSceneConversionState(SceneConversionState.Ok);
        return;
      }

      // Check the running pc conversion tasks
      const runningTasks = await progressApi
        .requestAllProgress({
          signal: controller.signal,
          direction: "lastToFirst",
          taskType: ProgressApiSupportedTaskTypes.pointCloudLazToPotree,
        })
        .then((tasks) =>
          tasks.filter(
            (t) =>
              (t.status.state && isBackgroundTaskActive(t.status.state)) ??
              isBackgroundTaskInfoProgress(t.status),
          ),
        )
        .catch(() => []);

      // Sort from the newest to the latest, because lastToFirst sorts the pages but not the tasks
      runningTasks.sort((a, b) => compareDateTimes(b.createdAt, a.createdAt));

      const descendantIds = runningTasks
        .map((data) => data.context.elementId ?? data.context.correlationId)
        .filter(isValid);

      const focusDatasets =
        descendantIds.length === 0
          ? []
          : await projectApi
              .getAllIElements({
                typeHints: [IElementTypeHint.dataSetFocus],
                types: [IElementType.section],
                descendantIds,
              })
              .catch(() => []);

      setSceneConversionState(
        focusDatasets.length > 0 && isProjectEmpty
          ? SceneConversionState.Running
          : SceneConversionState.Ok,
      );
    }

    // Check for the conversion state every [DEFAULT_INTERVAL] milliseconds
    async function pollConversionProgress(): Promise<void> {
      await checkConversionProgress();
      if (!controller.signal.aborted) {
        timeoutId = window.setTimeout(pollConversionProgress, DEFAULT_INTERVAL);
      }
    }

    pollConversionProgress();

    return () => {
      if (timeoutId !== undefined) {
        window.clearTimeout(timeoutId);
      }
      controller.abort();
    };
  }, [progressApi, projectApi, store]);

  return sceneConversionState;
}
