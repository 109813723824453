import { HelpBanner } from "@faro-lotv/flat-ui";
import { SxProps, Theme } from "@mui/material";
import { AvailableAnnotationTools } from "../../r3f/renderers/annotations/annotations-types";
import {
  AnalysisHelpBanner,
  CreateAnnotationHelpBanner,
  MeasurementHelpBanner,
} from "./help-banners";

type BannerProps = {
  /** True if the tool help banner should be enabled */
  enabled?: boolean;

  /** Additional style to the help banner */
  sx?: SxProps<Theme>;
};

interface MeasureHelpBannerProps extends BannerProps {
  /** True if the current scene cannot be measured */
  cannotMeasure?: boolean;
}

interface AnnotationHelpBannerProps extends BannerProps {
  /** Currently available type of annotation tool */
  availableAnnotationTool?: AvailableAnnotationTools;
}

type Props = {
  /** The props which controls the behavior of measure help banner */
  measureHelpBanner?: MeasureHelpBannerProps;

  /** The props which controls the behavior of annotation help banner */
  annotationHelpBanner?: AnnotationHelpBannerProps;
};

/** @returns the help banners required for the tools in the current mode */
export function ToolsHelpBanners({
  measureHelpBanner = {},
  annotationHelpBanner = {},
}: Props): JSX.Element {
  const { enabled: measureEnabled = true, cannotMeasure = false } =
    measureHelpBanner;

  const {
    enabled: annotationEnabled = true,
    availableAnnotationTool = AvailableAnnotationTools.singlePoint,
  } = annotationHelpBanner;

  return (
    <>
      {cannotMeasure && (
        <HelpBanner sx={{ justifyContent: "center", alignSelf: "center" }}>
          Measure tool cannot be used on 360 data without depth information
        </HelpBanner>
      )}
      {measureEnabled && (
        <MeasurementHelpBanner
          sx={{
            justifyContent: "center",
            alignSelf: "center",
            ...measureHelpBanner.sx,
          }}
        />
      )}

      {annotationEnabled && (
        <CreateAnnotationHelpBanner
          availableTool={availableAnnotationTool}
          sx={{
            justifyContent: "center",
            alignSelf: "center",
            ...annotationHelpBanner.sx,
          }}
        />
      )}

      <AnalysisHelpBanner
        sx={{
          justifyContent: "center",
          alignSelf: "center",
        }}
      />
    </>
  );
}
