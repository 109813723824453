import { TREE_NODE_HEIGHT } from "@/components/ui/tree/tree-node";
import { selectAllCadModels } from "@/store/cad/cad-selectors";
import { setActiveCad } from "@/store/cad/cad-slice";
import {
  selectWizardElementToAlignId,
  selectWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import { setWizardReferenceElementId } from "@/store/modes/alignment-wizard-mode-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { AnchorIcon, FaroText, FaroTooltip, neutral } from "@faro-lotv/flat-ui";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementAreaSection,
  isIElementModel3dStream,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import {
  selectAllIElementsOfType,
  selectAncestor,
  selectIElement,
  TreeData,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/system";
import { useCallback, useMemo, useRef, useState } from "react";
import { NodeApi, Tree, TreeApi } from "react-arborist";
import { OpenMap } from "react-arborist/dist/module/state/open-slice";
import { AlignmentWizardReferenceTreeNode } from "./align-wizard-reference-tree-node";

export enum ReferenceTreeFolderIds {
  /** string to use as an ID of 3D models folder in references selector tree of Alignment Wizard   */
  modelFolderId = "Models references root folder",

  /** string to use as an ID of clouds folder in references selector tree of Alignment Wizard   */
  cloudsFolderId = "Clouds references root folder",

  /** string to use as an ID of sheets folder in references selector tree of Alignment Wizard   */
  sheetsFolderId = "Sheets references root folder",
}

/**
 * @returns control to select alignment reference element categorized by element type
 */
export function AlignmentWizardReferenceSelector(): JSX.Element {
  const treeRef = useRef<TreeApi<TreeData>>();
  const store = useAppStore();
  const dispatch = useAppDispatch();

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const elementToAlign = useAppSelector(selectIElement(elementToAlignId));
  assert(elementToAlign, "invalid element selected for alignment");

  const referenceElementId = useAppSelector(selectWizardReferenceElementId);

  const referenceTree = useMemo(() => {
    const referenceTree: TreeData[] = [];

    // all 3D models in the project can be used for alignment of any sheet or cloud
    const cadModels = selectAllCadModels(store.getState());

    // sort models in alphabetical order of names
    const sortedCadModels = cadModels.sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    const cads: TreeData[] = sortedCadModels.map((cad) => ({
      id: cad.id,
      label: cad.name,
      children: null,
      element: cad,
    }));

    // "Sheets" folder fill be filled up only in case if selected element is a cloud
    // folder will contain in that case only one area (direct ancestor of the cloud)
    let area = selectAncestor(
      elementToAlign,
      isIElementAreaSection,
    )(store.getState());
    if (!isIElementSectionDataSession(elementToAlign)) {
      area = undefined;
    }

    // list of point clouds will be filled up if area is defined on previous step (eg. selected element is a cloud)
    // for that case we populate list of available references by all other clouds belonging to the same area (excluding selected element)
    const pointClouds = area
      ? selectAllIElementsOfType(
          isIElementSectionDataSession,
          area.id,
        )(store.getState())
      : undefined;

    referenceTree.push({
      id: ReferenceTreeFolderIds.modelFolderId,
      label: "Models",
      children: cads.length > 0 ? cads : null,
    });

    const sheet = area
      ? [
          {
            id: area.id,
            label: area.name,
            children: null,
            element: area,
          },
        ]
      : null;

    referenceTree.push({
      id: ReferenceTreeFolderIds.sheetsFolderId,
      label: "Sheets",
      children: sheet,
    });

    let clouds: TreeData[] = [];
    if (pointClouds) {
      clouds = pointClouds.flatMap((cloud) =>
        cloud.id === elementToAlignId
          ? []
          : [
              {
                id: cloud.id,
                label: cloud.name,
                children: null,
                element: cloud,
              },
            ],
      );
    }

    referenceTree.push({
      id: ReferenceTreeFolderIds.cloudsFolderId,
      label: "Point Clouds",
      children:
        clouds.length > 0 && isIElementSectionDataSession(elementToAlign)
          ? clouds
          : null,
    });

    return referenceTree;
  }, [elementToAlign, elementToAlignId, store]);

  const [openState, setOpenState] = useState<OpenMap>({
    [referenceTree[0]?.id]: false,
  });

  const selectNode = useCallback(
    (nodes: Array<NodeApi<TreeData>>) => {
      if (nodes.length !== 1) return;

      const element = selectIElement(nodes[0].id)(store.getState());
      assert(element, "invalid element in reference tree");

      if (isIElementModel3dStream(element)) {
        dispatch(setActiveCad(element.id));
      }
      dispatch(setWizardReferenceElementId(element.id));
    },
    [dispatch, store],
  );

  const onToggle = useCallback(() => {
    if (treeRef.current?.openState) {
      setOpenState(treeRef.current.openState);
    }
  }, [setOpenState]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <FaroText variant="heading16" sx={{ paddingLeft: 3 }}>
          Align to
        </FaroText>

        <FaroTooltip title="The reference element does not move during the alignment">
          <AnchorIcon htmlColor={neutral[400]} />
        </FaroTooltip>
      </Stack>

      <Tree<TreeData>
        ref={treeRef}
        data={referenceTree}
        onSelect={selectNode}
        selection={referenceElementId}
        openByDefault={false}
        onToggle={onToggle}
        // Disabling dnd features for now
        disableDrag
        disableDrop
        width="100%"
        height={650}
        rowHeight={TREE_NODE_HEIGHT}
        indent={24}
        initialOpenState={openState}
      >
        {AlignmentWizardReferenceTreeNode}
      </Tree>
    </>
  );
}
