export enum TreeNodeDisabledReason {
  /** The IElement type can currently not be shown by the Viewer */
  notSupported = "notSupported",

  /** Item was disabled because the user requires the point cloud management add-on */
  missingPointCloudPermission = "missingPointcloudPermission",

  /** Item was disabled because it can't be used as alignment reference for given aligned element */
  notCompatibleReference = "notCompatibleReference",

  /** Item was disabled because there are no elements to be used as alignment reference for given aligned element */
  notElementAvailable = "notElementAvailable",
}
