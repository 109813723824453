import {
  ChangeObjectVisibilityProperties,
  EventType,
} from "@/analytics/analytics-events";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectVisibilityDistance } from "@/store/view-options/view-options-selectors";
import {
  setVisibilityDistance,
  VisibilityDistance,
} from "@/store/view-options/view-options-slice";
import { FaroRadio, FaroRadioGroup } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";

function isVisibilityDistance(value: string): value is VisibilityDistance {
  return Object.keys(VisibilityDistance).includes(value);
}

/** @returns a radio button group to select the visibility distance. */
export function VisibilityDistanceRadio(): JSX.Element {
  const visibilityDistance = useAppSelector(selectVisibilityDistance);
  const dispatch = useAppDispatch();

  return (
    <FaroRadioGroup
      value={visibilityDistance}
      onChange={(_, value) => {
        if (isVisibilityDistance(value)) {
          Analytics.track<ChangeObjectVisibilityProperties>(
            EventType.changeObjectVisibility,
            { value },
          );

          dispatch(setVisibilityDistance(value));
        }
      }}
    >
      <FaroRadio
        dark
        value={VisibilityDistance.nearby}
        label="Show nearby objects only"
      />
      <FaroRadio dark value={VisibilityDistance.all} label="Show all objects" />
    </FaroRadioGroup>
  );
}
