import { RootState } from "@/store/store";
import {
  convertThreeToIElementTransform,
  parseQuaternion,
  parseVector3,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGenericImgSheet,
  IElementGenericPointCloudStream,
  IElementImg360,
  IElementSection,
} from "@faro-lotv/ielement-types";
import {
  Mutation,
  MutationSetElementPose,
  MutationSetFloorSectionSize,
  MutationSetImg360Rotation,
  MutationTypes,
  createBaseMutation,
  createMutationSetAreaWorldPose,
  createMutationSetElementPose,
  createMutationSetImg360Rotation,
} from "@faro-lotv/service-wires";
import { Matrix4, Vector3, Vector3Tuple, Vector4Tuple } from "three";
import { AlignmentTransform } from "./store/alignment-slice";
import { calculateAlignmentTransform } from "./utils/alignment-transform";

/**
 * Creates the mutation payload for a complete pointcloud alignment,
 * that sets the scale of a floorplan to the pointclouds scale.
 *
 * @param sheet The sheet being aligned to the point cloud.
 * @param pointCloudSection The LaserScans Section containing the point cloud.
 *  The changes will be persisted on this element.
 * @param pointCloudStream The PointCloudStream that is being aligned.
 * @param newTransform The new world-space transform of the aligned point cloud.
 * @param state The application state.
 * @returns The mutations payload to send to the ProjectAPI
 */
export function createPointCloudAlignmentMutations(
  sheet: IElementGenericImgSheet,
  pointCloudSection: IElementSection,
  pointCloudStream: IElementGenericPointCloudStream,
  newTransform: Matrix4,
  state: RootState,
): [MutationSetFloorSectionSize, MutationSetElementPose] {
  const transformToPersistDecomposed = calculateAlignmentTransform(
    pointCloudStream,
    newTransform,
    pointCloudSection,
    state,
  );

  const { scale } = transformToPersistDecomposed;
  assert(isScaleUniform(scale), "Expected scale to be uniform");
  const uniformScale = scale[0];

  return [
    // Invert the scale in the floorplan section, so the entire sheet inherits the scale
    // from the pointcloud, while the pointcloud remains at true world scale
    createSetFloorplanScaleMutation(sheet, 1 / uniformScale),
    createSetPointCloudPoseMutation(
      pointCloudSection,
      transformToPersistDecomposed,
    ),
  ];
}

/**
 * Creates the mutation payload to set a floorplan section's scale
 *
 * @param area area that is contained in the floorplan section
 * @param uniformScale The uniform scale to apply to the floorplan section
 * @returns The mutation payload to be sent to the ProjectAPI
 */
export function createSetFloorplanScaleMutation(
  area: IElementSection | IElementGenericImgSheet,
  uniformScale: number,
): MutationSetFloorSectionSize {
  return {
    ...createBaseMutation(MutationTypes.MutationSetFloorSectionSize, area.id),
    scale: {
      x: uniformScale,
      y: uniformScale,
      z: uniformScale,
    },
  };
}

/**
 * Create a mutation to update the pose of a PointCloud IElement
 *
 * @param pcSection Section of the PointCloud to move
 * @param transform the new pose of the PointCloud
 * @returns The mutation to apply to the Project
 */
function createSetPointCloudPoseMutation(
  pcSection: IElementSection,
  transform?: AlignmentTransform,
): MutationSetElementPose {
  return createMutationSetElementPose(
    pcSection.id,
    transform
      ? {
          ...convertThreeToIElementTransform({
            position: parseVector3(transform.position),
            quaternion: parseQuaternion(transform.quaternion),
            scale: new Vector3(1, 1, 1),
          }),
          isWorldScale: true,
        }
      : null,
  );
}

/**
 * Create a mutation to align an element.
 *
 * @param alignedElement The IElement that was being aligned by the user.
 * @param newAlignedElementTransform The new transform of the element aligned by the user, in world space.
 * @param elementToApplyTransform The element where the new transform should be persisted. Must be an ancestor of `alignedElement`.
 * @param state Root state of the application.
 * @returns The mutation to persist the new transform to the Project API.
 */
export function createAlignmentMutation(
  alignedElement: IElement,
  newAlignedElementTransform: Matrix4,
  elementToApplyTransform: IElementSection,
  state: RootState,
): MutationSetElementPose {
  const newLocalTransformOfElementToApply = calculateAlignmentTransform(
    alignedElement,
    newAlignedElementTransform,
    elementToApplyTransform,
    state,
  );

  // Create a mutation for the Project API
  return createSetPointCloudPoseMutation(
    elementToApplyTransform,
    newLocalTransformOfElementToApply,
  );
}

/**
 * @param v scale vector
 * @param eps The margin of error to allow
 * @returns whether the vector is uniform within a small margin of error
 */
function isScaleUniform(v: Vector3 | Vector3Tuple, eps = 0.0001): boolean {
  v = parseVector3(v);
  return Math.abs(v.x - v.y) < eps && Math.abs(v.x - v.z) < eps;
}

/**
 * Creates the mutation payload for a alignment of the area to CAD,
 * all CADs are geo-referenced and have same transform
 *
 * @param area The area being aligned to the CAD.
 * @param newTransform The area-to-cad transform received at the end of manual alignment from alignment page
 * @returns The mutations payload to send to the ProjectAPI
 */
export function createAreaAlignmentMutations(
  area: IElementSection,
  newTransform: AlignmentTransform,
): Mutation[] {
  assert(isScaleUniform(newTransform.scale), "Expected scale to be uniform");

  const areaMutations: Mutation[] = [
    createMutationSetAreaWorldPose(
      area.id,
      newTransform.scale[0],
      {
        x: newTransform.quaternion[0],
        y: newTransform.quaternion[1],
        z: newTransform.quaternion[2],
        w: newTransform.quaternion[3],
      },
      {
        x: newTransform.position[0],
        y: newTransform.position[1],
        z: newTransform.position[2],
      },
    ),
  ];

  return areaMutations;
}

/**
 * Create a mutation to update the accurate relative rotation component of a 360 image IElement
 *
 * @param imageElement IElement of the 360 image to update the POSE
 * @param quaternion the new rotation component of the 360 image with respect to its parent
 * @returns The mutation to apply to the Project
 */
export function createSet360ImageRotationMutation(
  imageElement: IElementImg360,
  quaternion?: Vector4Tuple,
): MutationSetImg360Rotation {
  // assume this rotation is always accurate
  const isRotationAccurate = true;
  return createMutationSetImg360Rotation(
    imageElement.id,
    quaternion
      ? {
          x: quaternion[0],
          y: quaternion[1],
          z: quaternion[2],
          w: quaternion[3],
        }
      : null,
    isRotationAccurate,
  );
}
