import { useFrame } from "@react-three/fiber";
import { useState } from "react";
import { Box3, Vector3 } from "three";

/** Temporary variables to avoid re-allocations */
const TEMP_BOX = new Box3();
const TEMP_VEC3 = new Vector3();

/**
 * Compute the anchor point for an action bar floating on an 3D object. The shape
 * of the 3D object is indicated by a list of points.
 *
 * @param points points indicating the shape of 3D object
 * @returns the anchor point for the action bar
 */
export function useActionBarAnchorPoint(points: Vector3[]): Vector3 {
  // Anchor point for the action bar
  const [anchorPoint, setAnchorPoint] = useState(new Vector3());
  useFrame(({ camera }) => {
    TEMP_BOX.makeEmpty();
    for (const p of points) {
      TEMP_BOX.expandByPoint(TEMP_VEC3.copy(p).project(camera));
    }
    TEMP_BOX.getCenter(TEMP_VEC3);
    TEMP_VEC3.y = TEMP_BOX.max.y;
    const newAnchor = TEMP_VEC3.clone().unproject(camera);
    if (newAnchor.distanceToSquared(anchorPoint) > 0.01) {
      setAnchorPoint(newAnchor);
    }
  }, 0);
  return anchorPoint;
}
