import { PanoObject } from "@/object-cache";
import { usePanoCameraTransform } from "@/utils/camera-transform";
import {
  Img360PanoBase,
  Img360PanoRef,
} from "@faro-lotv/app-component-toolbox";
import { IElementGenericImgSheet } from "@faro-lotv/ielement-types";
import { EventHandlers } from "@react-three/fiber/dist/declarations/src/core/events";
import { forwardRef } from "react";

export type PanoramaRendererProps = EventHandlers & {
  /** The pano to render */
  pano: PanoObject;

  /** Current sheet, used to compute pano position if it has inaccurate height */
  sheet?: IElementGenericImgSheet;

  /** The opacity level to use [0, 1]. A value != 0 will set material.transparent = true */
  opacity?: number;

  /**
   * Define to true to register this as a secondary view for split screen rendering
   *
   * @default false
   */
  isSecondaryView?: boolean;
};

/**
 * @returns Renderer for panorama images in panorama mode
 */
export const PanoramaRenderer = forwardRef<
  Img360PanoRef,
  PanoramaRendererProps
>(function PanoramaRenderer(
  {
    pano,
    sheet,
    opacity,
    isSecondaryView = false,
    ...rest
  }: PanoramaRendererProps,
  ref,
): JSX.Element | null {
  // The pano should be rendered at "eye-height", so it is centered on the same position as the camera
  const pose = usePanoCameraTransform(pano.iElement, sheet);

  if (opacity === 0) return null;

  return (
    <Img360PanoBase
      pano={pano}
      ref={ref}
      opacity={opacity}
      isSecondaryView={isSecondaryView}
      position={pose.position}
      quaternion={pose.quaternion}
      {...rest}
    />
  );
});
