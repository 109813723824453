import { useClipboxEvents } from "@/components/common/clipbox-events-context";
import { useTransparencySettingsContext } from "@/components/common/transparency-sliders/transparency-settings-context";
import { PointCloudSubscene } from "@/components/r3f/effects/point-cloud-subscene";
import { CadSubscene } from "@/modes/overview-mode/cad-subscene";
import { RenderDispatch } from "@/modes/overview-mode/render-dispatch";
import { CadModelObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { selectActiveSettingsMenu } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { useAutoClipBox } from "@/tools/use-auto-clip-box";
import {
  ComposeFramebuffersPass,
  CopyToScreenPass,
  DEFAULT_BACKGROUND,
  EffectPipeline,
  FilteredRenderPass,
  StoreFboPass,
  StoreFboPassObj,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import {
  ComposeFramebuffersPass as LotvComposeFramebuffersPass,
  assert,
} from "@faro-lotv/lotv";
import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { Object3D, Points } from "three";
import { SplitscreenModePipelineProps } from "./spitscreen-mode-pipeline";

type WalkModePipelineProps = SplitscreenModePipelineProps & {
  /** The optional CAD model to render or to overlay */
  cadModel: CadModelObject | null;

  /** True to enable this effect pipeline */
  enabled: boolean;

  /** Whether the 3d models should be rendered on demand */
  renderOnDemand: boolean;
};

/** @returns the effect pipeline to use in walk mode, with the capability to compose a point cloud and a CAD model in overlay */
export function WalkModePipeline({
  pointCloud,
  cadModel,
  enabled,
  renderOnDemand,
}: WalkModePipelineProps): JSX.Element | null {
  const activeSettingsMenu = useAppSelector(selectActiveSettingsMenu);

  const composerRef = useRef<LotvComposeFramebuffersPass>();

  const transparency = useTransparencySettingsContext();

  const storeFboRef = useRef<StoreFboPassObj>(null);

  const clipboxEvents = useClipboxEvents();
  assert(
    clipboxEvents,
    "ClipboxEventsContext should be defined in the OverviewRenderingPipeline",
  );

  const autoClipBoxFnc = useAutoClipBox(storeFboRef);

  // When the autoClipBox event is triggered, create a clipping box by sampling the depth buffer
  useTypedEvent<void>(clipboxEvents.autoClipBox, autoClipBoxFnc);

  useFrame(() => {
    if (!composerRef.current) return;
    const { cadOpacity, pcOpacity } = transparency.objectsOpacity;
    composerRef.current.opacity2 = cadOpacity;
    composerRef.current.opacity1 = pcOpacity;
  }, 0);

  return (
    <EffectPipeline enabled={enabled}>
      <ComposeFramebuffersPass
        // TODO: pass a custom background color here when dark background available https://faro01.atlassian.net/browse/SWEB-3759
        background={DEFAULT_BACKGROUND}
        ref={composerRef}
        thermoCompare={activeSettingsMenu === ToolName.heatmap}
      >
        <PointCloudSubscene
          pointCloud={pointCloud}
          renderOnDemand={renderOnDemand}
        />
        <CadSubscene
          cadModel={cadModel}
          enabled={!!cadModel}
          renderOnDemand={renderOnDemand}
        />
      </ComposeFramebuffersPass>
      <StoreFboPass ref={storeFboRef} />
      <FilteredRenderPass
        filter={(o: Object3D) =>
          !(o instanceof Points) && o.userData.type !== RenderDispatch.CadModel
        }
        clear={false}
        clearDepth={false}
      />
      <CopyToScreenPass />
    </EffectPipeline>
  );
}
