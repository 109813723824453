import { PointCloudObject } from "@/object-cache";
import { Perspective } from "@/registration-tools/common/store/registration-datatypes";
import { Box3, OrthographicCamera, Vector3 } from "three";

const BOX_DIAG_MULTIPLIER = 0.5;

/**
 * Computes and updates the camera frustum to visualize a bounding box that contains all relevant objects
 *
 * @param camera The camera to compute the frustum and apply it to
 * @param bbox the bounding box that contains all the relevant objects
 */
function updateCameraFrustum(camera: OrthographicCamera, bbox: Box3): void {
  const diagonal = bbox.max.distanceTo(bbox.min);
  if (camera instanceof OrthographicCamera) {
    const aspectRatio =
      (camera.right - camera.left) / (camera.top - camera.bottom);
    camera.top = diagonal * BOX_DIAG_MULTIPLIER;
    camera.bottom = -camera.top;
    camera.right = camera.top * aspectRatio;
    camera.left = camera.bottom * aspectRatio;
    camera.near = 0.01;
    camera.far = 4 * diagonal;
    camera.updateProjectionMatrix();
  }
}

/**
 * Create the bounding box for the point cloud that can be used in the computation
 * of the camera position
 *
 * @param pointCloud point cloud object for which bounding box needs to be computed
 * @param bbox The bounding box to write the result into. Cache this value to avoid reallocations.
 * @returns bounding box for the point cloud, same object as `bbox`.
 */
export function computePointCloudBoundingBox(
  pointCloud: PointCloudObject,
  bbox: Box3,
): Box3 {
  return bbox.copy(pointCloud.boundingBox).applyMatrix4(pointCloud.matrixWorld);
}

/**
 * Computes a bounding box that contains both point clouds.
 *
 * @param pointClouds The pointclouds
 * @param bboxUnion The box to write the result into. Cache this value to avoid reallocations.
 * @returns bounding box that contains both pointclouds
 */
export function computeCombinedPointCloudBoundingBox(
  pointClouds: PointCloudObject[],
  bboxUnion: Box3,
): Box3 {
  // Temporary value to reuse for the bounding box of each point cloud
  const tempBbox = new Box3();

  for (const pointCloud of pointClouds) {
    bboxUnion.union(computePointCloudBoundingBox(pointCloud, tempBbox));
  }

  return bboxUnion;
}

/**
 * @param pointClouds the point clouds to compute the center of
 * @returns the center point of all point clouds
 */
export function computeCombinedPointCloudCenter(
  pointClouds: PointCloudObject[],
): Vector3 {
  const combinedBbox = computeCombinedPointCloudBoundingBox(
    pointClouds,
    new Box3(),
  );
  return combinedBbox.getCenter(new Vector3());
}

/**
 * Centers the camera on the refCloud and the modelCloud. DOES NOT CHANGE PERSPECTIVE!
 *
 * @param pointclouds The pointclouds to center the camera on
 * @param camera the camera to be centered
 * @param perspective The view type: top, front, or side.
 */
export function centerCameraOnPointClouds(
  pointclouds: PointCloudObject[],
  camera: OrthographicCamera,
  perspective: Perspective,
): void {
  // Need to add this line to control viewport ratio adjustment when resizing viewport,
  // centering pointcloud is not working correctly otherwise
  Object.assign(camera, { manual: true });
  const combinedBbox = computeCombinedPointCloudBoundingBox(
    pointclouds,
    new Box3(),
  );
  const target = combinedBbox.getCenter(new Vector3());
  const diagonal = combinedBbox.max.distanceTo(combinedBbox.min);
  updateCameraFrustum(camera, combinedBbox);

  switch (perspective) {
    default:
    case Perspective.topView:
      camera.position.set(target.x, target.y + diagonal, target.z);
      break;
    case Perspective.frontView:
      camera.position.set(target.x, target.y, target.z + diagonal);
      break;
    case Perspective.sideView:
      camera.position.set(target.x + diagonal, target.y, target.z);
      break;
  }
  camera.lookAt(target);
  camera.updateProjectionMatrix();
}
