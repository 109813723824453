import { useThreeEventTarget } from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { MutableRefObject, useRef } from "react";

/**
 * @returns a reference to the view overlay HTML element
 */
export function useViewOverlayRef(): MutableRefObject<HTMLElement> {
  const viewOverlay = useThreeEventTarget();
  assert(
    // Return of useThreeEventTarget is not correctly typed (see https://faro01.atlassian.net/browse/SWEB-3461)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    viewOverlay !== null,
    "Cannot create a reference to viewer overlay HTML element because R3F canvas has no parent HTML element.",
  );

  return useRef(viewOverlay);
}
