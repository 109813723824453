import {
  centerOrthoCamera,
  useCenterCameraOnPlaceholders,
} from "@/hooks/use-center-camera-on-placeholders";
import { ModeTransitionProps } from "@/modes/mode";
import { useCached3DObjectIfExists } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { perspCameraDistance } from "@/utils/persp-camera-distance";
import { selectIElementWorldPosition } from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { useEffect, useMemo } from "react";
import { OrthographicCamera, Vector3 } from "three";
import { SheetRenderer } from "../renderers/sheet-renderer";
import { CameraAnimation } from "./camera-animation";

interface ToSheetAnimationProps extends ModeTransitionProps {
  /** Placeholders which are being rendered on top of the sheet */
  placeholders: IElementImg360[];

  /** The sheet we are transitioning to */
  sheetElement?: IElementGenericImgSheet;
}

/**
 * @returns the transition to enter into a mode with sheet and placeholders
 */
export function ToSheetAnimation({
  defaultCamera,
  modeCamera,
  previousMode,
  onCompleted,
  placeholders,
  sheetElement,
}: ToSheetAnimationProps): JSX.Element {
  const sheet = useCached3DObjectIfExists(sheetElement);

  const activeSheetHeight = useAppSelector(
    selectIElementWorldPosition(sheetElement?.id),
  )[1];

  const centeringData = useCenterCameraOnPlaceholders({
    sheetElement,
    placeholders,
  });

  // The camera for the transition is a perspective camera, but then will be swapped to orthographic.
  // We set the target frustum for our camera in such a way that it lines up with the orthographic
  // camera exactly at the position of the sheet.
  // This avoids "jumps" when the camera is switched.
  const targetPosition = useMemo(() => {
    const orthoFrustum = centeringData.frustum;
    const distance = perspCameraDistance(
      orthoFrustum.top - orthoFrustum.bottom,
      defaultCamera.getEffectiveFOV(),
    );
    const center = orthoFrustum.bboxCenter;

    return new Vector3(center.x, activeSheetHeight + distance, center.z);
  }, [activeSheetHeight, centeringData.frustum, defaultCamera]);

  // Pre-adjust mode camera so when transition ends we don't have jumps
  useEffect(() => {
    if (!(modeCamera instanceof OrthographicCamera)) return;
    centerOrthoCamera(modeCamera, centeringData);
  }, [centeringData, modeCamera]);

  // Remove the camera animation if the app just started
  const duration = useMemo(() => {
    return previousMode === "start" ? 0 : 1;
  }, [previousMode]);

  return (
    <>
      <CameraAnimation
        position={targetPosition}
        quaternion={centeringData.quaternion}
        duration={duration}
        onAnimationFinished={onCompleted}
      />
      {sheet && <SheetRenderer sheet={sheet} />}
    </>
  );
}
