import { Mode } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import { AlignWizardModeOverlay } from "./align-wizard-mode-overlay";
import { AlignWizardModeScene } from "./align-wizard-mode-scene";

export const alignWizardMode: Mode = {
  name: "alignWizard",
  ModeScene: AlignWizardModeScene,
  ModeOverlay: AlignWizardModeOverlay,
  exclusive: {
    title: "Alignment",
    // eslint-disable-next-line require-await -- FIXME
    async onBack({ dispatch }) {
      dispatch(changeMode("overview"));
    },
  },
};
