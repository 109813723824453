import { SvgIcon as MuiSvgIcon, SxProps, Theme } from "@mui/material";
import { FunctionComponent, SVGProps } from "react";
import { ReactComponent as Parameters } from "./parameters.svg";
import { ReactComponent as ViewFront } from "./view-predefined-front.svg";
import { ReactComponent as ViewSide } from "./view-predefined-right.svg";
import { ReactComponent as ViewTop } from "./view-predefined-top.svg";

interface IProps {
  /** Styling of the icon using sx prop of mui */
  sx?: SxProps<Theme>;
}
interface IIconProps extends IProps {
  /** SVG as ReactComponent in order to be used in mui icon */
  source: FunctionComponent<SVGProps<SVGSVGElement>>;
}

function SvgIcon({ source, sx }: IIconProps): JSX.Element {
  return <MuiSvgIcon component={source} inheritViewBox sx={sx} />;
}

/** @returns top-view icon */
export function TopViewIcon({ sx }: IProps): JSX.Element {
  return <SvgIcon source={ViewTop} sx={sx} />;
}

/** @returns front-view icon */
export function FrontViewIcon({ sx }: IProps): JSX.Element {
  return <SvgIcon source={ViewFront} sx={sx} />;
}

/** @returns top-view icon */
export function SideViewIcon({ sx }: IProps): JSX.Element {
  return <SvgIcon source={ViewSide} sx={sx} />;
}

/** @returns parameter menu icon */
export function ParameterMenuIcon({ sx }: IProps): JSX.Element {
  return <SvgIcon source={Parameters} sx={sx} />;
}
