import {
  ClipSceneIcon,
  ClipSceneSubmenu,
} from "@/components/ui/clip-scene-tool-ui";
import { ToolName } from "@/store/ui/ui-slice";
import { ToolButton, Toolbar } from "@faro-lotv/flat-ui";
import { Popper } from "@mui/material";
import { useRef } from "react";

interface DataPreparationToolsToolbarProps {
  /** The currently active tool */
  activeTool?: ToolName;

  /** Callback when the user requests to toggle a tool*/
  onToggleTool(toggleTool: ToolName): void;
}

/** @returns the tools toolbar for the data-preparation tool */
export function DataPreparationToolsToolbar({
  activeTool,
  onToggleTool,
}: DataPreparationToolsToolbarProps): JSX.Element {
  const clipSceneToolButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Toolbar>
        <ToolButton
          ref={clipSceneToolButtonRef}
          onClick={() => onToggleTool(ToolName.clipScene)}
          selected={activeTool === ToolName.clipScene}
        >
          <ClipSceneIcon />
        </ToolButton>
      </Toolbar>

      <Popper
        open={activeTool === ToolName.clipScene}
        anchorEl={clipSceneToolButtonRef.current}
        placement="left"
        sx={{ p: 1 }}
      >
        <ClipSceneSubmenu
          // The auto clip requires depth to be written during rendering, but the revision scans are rendered in the transparent pass.
          allowAutoClip={false}
        />
      </Popper>
    </>
  );
}
