import {
  AlignmentTransformChangedProperties,
  EventType,
} from "@/alignment-tool/analytics/analytics-events";
import {
  selectAlignmentArea,
  selectElementToAlignTransform,
} from "@/alignment-tool/store/alignment-selectors";
import {
  completeActiveStep,
  setElementToAlignTransform,
} from "@/alignment-tool/store/alignment-slice";
import { PointCloudSubscene } from "@/components/r3f/effects/point-cloud-subscene";
import { useRealtimeRaycasting } from "@/hooks/use-real-time-raycasting";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import {
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  FilteredRenderPass,
  GridPlane,
  LodPointCloudRendererBase,
  TransformControls,
  UniformLights,
  getSheetSize,
  selectChildDepthFirst,
  selectIElementWorldTransform,
  useCenterCameraOnSheet,
  useNonExhaustiveEffect,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementGenericImgSheet,
  isIElementImgSheet,
} from "@faro-lotv/ielement-types";
import { PointCloud } from "@faro-lotv/lotv";
import { useTheme } from "@mui/material";
import { animated, useSpring } from "@react-spring/three";
import { OrbitControls } from "@react-three/drei";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Group, Object3D, Vector2, Vector2Tuple, Vector3Tuple } from "three";
import { useCloudToAlign } from "./alignment";
import { Step, StepNames } from "./steps";

const FLOOR_OVERLAP = 1.2;
const MAX_LOD_NODES_TO_RENDER_WHILE_MOVING = 80;

export const heightSettingStep: Step = {
  name: StepNames.heightSetting,
  Scene() {
    const dispatch = useAppDispatch();

    const [modelWrapperGroup, setModelWrapperGroup] = useState<Group | null>();

    const onSetModelWrapperGroup = useCallback((ref: Group | null) => {
      setModelWrapperGroup(ref);
    }, []);

    useEffect(() => {
      dispatch(completeActiveStep());
    }, [dispatch]);

    const [enableControls, setEnableControls] = useState<boolean>(true);
    const startPick = useRef<Vector2>(new Vector2(0, 0));

    const camera = useThree((s) => s.camera);

    const alignedArea = useAppSelector(selectAlignmentArea);

    const activeSheet = useAppSelector(
      selectChildDepthFirst(alignedArea, isIElementGenericImgSheet),
    );

    const sheetTransform = useAppSelector(
      selectIElementWorldTransform(activeSheet?.id),
    );

    const cloudToAlign = useCloudToAlign();

    if (!activeSheet) {
      throw Error("Expected a sheet in the project");
    }

    useRealtimeRaycasting(cloudToAlign, false);
    const transform = useAppSelector(selectElementToAlignTransform);
    assert(
      transform,
      "A valid transformation is needed for the HeightSetting step",
    );

    // This hook changes a parameter in the lod point cloud object,
    // restoring the previous value of the parameter on component unmount.
    // The effect is that, on an energy-saving GPU when the camera is moving,
    // only MAX_LOD_NODES_TO_RENDER_WHILE_MOVING are rendered per frame.
    // This parameter is convenient to achieve an acceptable framerate.
    // The default parameter works well only in perspective projection,
    // while in this component an orhographic projection is used to render
    // the point cloud.
    useEffect(() => {
      const oldMaxNodes = cloudToAlign.getSubsampledRenderingMaxNodes();
      cloudToAlign.setSubsampledRenderingMaxNodes(
        MAX_LOD_NODES_TO_RENDER_WHILE_MOVING,
      );
      return () => cloudToAlign.setSubsampledRenderingMaxNodes(oldMaxNodes);
    }, [cloudToAlign]);

    const { modelPos } = useSpring({
      modelPos: transform.position,
    });

    // Get the extents of the floor plan to use for rendering the ground plane.
    const extents = useMemo<Vector2Tuple>(() => {
      const { width, height } = getSheetSize(activeSheet);
      return [width * FLOOR_OVERLAP, height * FLOOR_OVERLAP];
    }, [activeSheet]);

    // only cache the position on mount.
    // We don't want to jump the camera when the position changes.
    const [initialPosition] = useState<Vector3Tuple>(() => [
      -transform.position[0],
      -transform.position[1],
      -transform.position[2],
    ]);

    const box = useMemo(() => {
      return cloudToAlign.tree.boundingBox.clone();
    }, [cloudToAlign]);

    const { target } = useCenterCameraOnSheet(
      activeSheet,
      sheetTransform,
      box,
      transform,
      camera,
    );

    const onPointerDown = useCallback((e?: DomEvent | undefined) => {
      // Disable the OrbitControls onMouseDown.
      setEnableControls(false);
      e?.stopPropagation();
      startPick.current.set(0, 0);
    }, []);

    const onPointerUp = useCallback(
      (e?: DomEvent | undefined) => {
        // Enable the OrbitControls onMouseUp.
        setEnableControls(true);
        e?.stopPropagation();

        // Once the user has adjusted the height, the position of the model to align is persisted in the redux store
        if (modelWrapperGroup) {
          Analytics.track(EventType.alignmentTransformChanged, {
            change: AlignmentTransformChangedProperties.heightChangedViaControl,
          });
          startPick.current.set(0, 0);
          const posArray = modelWrapperGroup.position.toArray();
          // Set the animated position, so it doesn't try to animate from the old position on next draw
          modelPos.set(posArray);
          dispatch(
            setElementToAlignTransform({
              ...transform,
              position: posArray,
            }),
          );
        }
      },
      [modelWrapperGroup, modelPos, dispatch, transform],
    );

    const handlePick = useCallback(
      (ev: ThreeEvent<MouseEvent>) => {
        const { object } = ev.intersections[0];
        if (
          object instanceof PointCloud &&
          modelWrapperGroup &&
          startPick.current.distanceTo(
            new Vector2(ev.nativeEvent.offsetX, ev.nativeEvent.offsetY),
          ) < 2
        ) {
          // We don't want to handle every point, just the one closest to the camera
          ev.stopPropagation();

          // Get the actual point picked, not the "point" element of the event
          // as that returns the point on the ray closest to the point cloud
          // not the position of the clicked point itself.
          const point = object.getLocalPoint(ev.intersections[0].index ?? 0);
          object.localToWorld(point);

          // To set the position of the model, we need to know the position
          // relative to its parent.
          modelWrapperGroup.parent?.worldToLocal(point);
          const offset = sheetTransform.position[1] - point.y;
          const newPos = modelWrapperGroup.position.clone();
          newPos.y += offset;
          Analytics.track(EventType.alignmentTransformChanged, {
            change: AlignmentTransformChangedProperties.heightChangedViaClick,
          });
          dispatch(
            setElementToAlignTransform({
              ...transform,
              position: newPos.toArray(),
            }),
          );
        }
      },
      [dispatch, transform, modelWrapperGroup, sheetTransform.position],
    );

    const theme = useTheme();

    // The GridPlane object position is the center of the grid.
    // Our FloorImages instead have the position on the top or bottom left (tiled or not tiled)
    // This position is used to move the grid plane position so it aligns to the expected floor image used
    const gridPosition = useMemo<Vector3Tuple>(() => {
      // Can't use getSheetCenter utility function because the GridPlane geometry is Z-up
      const { width, height } = getSheetSize(activeSheet);
      const signedHeight = isIElementImgSheet(activeSheet) ? height : -height;
      return [width * 0.5, signedHeight * 0.5, 0];
    }, [activeSheet]);

    return (
      <>
        <UniformLights />
        <group {...sheetTransform}>
          <GridPlane
            position={gridPosition}
            size={extents}
            backgroundColor={theme.palette.gridBackground}
            backgroundOpacity={0.8}
            lineColor={theme.palette.gridLines}
            lineOpacity={0.5}
            borderColor={theme.palette.gridLines}
            borderWidth={0.1}
          />
        </group>
        <animated.group
          {...transform}
          position={modelPos}
          ref={onSetModelWrapperGroup}
          onPointerDown={(ev) =>
            startPick.current.set(
              ev.nativeEvent.offsetX,
              ev.nativeEvent.offsetY,
            )
          }
          onClick={handlePick}
        >
          <LodPointCloudRendererBase pointCloud={cloudToAlign} />
        </animated.group>
        <OrbitControls
          camera={camera}
          enabled={enableControls}
          target={target}
        />
        {/*
          As the pcTransform is added to the Group object,
          the controls are also added to the same object, to keep the logic consistent
        */}
        {modelWrapperGroup && (
          <group position={initialPosition}>
            <TransformControls
              position={target}
              object={modelWrapperGroup}
              camera={camera}
              showX={false}
              showZ={false}
              onMouseDown={onPointerDown}
              onMouseUp={onPointerUp}
            />
          </group>
        )}
        <EffectPipelineWithSubScenes>
          <PointCloudSubscene pointCloud={cloudToAlign} />

          <FilteredRenderPass
            filter={(obj: Object3D) => !isObjPointCloudPoint(obj)}
            clear={false}
            clearDepth={false}
          />
          <CopyToScreenPass />
        </EffectPipelineWithSubScenes>
      </>
    );
  },
  Transition: ({ onCompleted }): null => {
    useNonExhaustiveEffect(() => {
      onCompleted();
    }, []);

    return null;
  },
};
