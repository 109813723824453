import { SheetModeControls } from "@/components/r3f/controls/sheet-mode-controls";
import { AnnotationsRenderer } from "@/components/r3f/renderers/annotations/annotations-renderer";
import { DesaturationPipeline } from "@/components/r3f/renderers/desaturation-pipeline";
import { MeasurementsRenderer } from "@/components/r3f/renderers/measurements/measurements-renderer";
import { OdometryPathsRenderer } from "@/components/r3f/renderers/odometry-paths/odometry-paths-renderer";
import { SheetRenderer } from "@/components/r3f/renderers/sheet-renderer";
import { PlaceholderPreview } from "@/components/r3f/utils/placeholder-preview";
import { useMapPlaceholderPositions } from "@/hooks/use-map-placeholder-positions";
import { useCached3DObjectIfExists } from "@/object-cache";
import { Measurement } from "@/store/measurement-tool-slice";
import { selectModeIsTransitioning } from "@/store/mode-selectors";
import { selectIsPanoExtractedFromData } from "@/store/project-selector";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import {
  selectObjectVisibility,
  selectShouldColorWaypoints,
} from "@/store/view-options/view-options-selectors";
import { ViewObjectTypes } from "@/store/view-options/view-options-slice";
import { PickingTools } from "@/tools/picking-tools";
import { usePickingToolsCallbacks } from "@/tools/use-picking-tools-callbacks";
import { offsetPlaceholders } from "@/utils/offset-placeholders";
import {
  INVALID_ALTITUDE_COLOR,
  MAX_ALTITUDE_COLOR,
  MIN_ALTITUDE_COLOR,
} from "@/utils/waypoints-color-gradient";
import {
  MapWaypointsRenderer,
  interpolateColor,
  selectIElementWorldPosition,
  useOnClick,
} from "@faro-lotv/app-component-toolbox";
import {
  GUID,
  IElement,
  IElementGenericAnnotation,
  IElementGenericImgSheet,
  IElementImg360,
  IElementSection,
  isIElementImg360,
} from "@faro-lotv/ielement-types";
import { selectChildrenDepthFirst } from "@faro-lotv/project-source";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { isEqual } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { Box3, Color, Vector3 } from "three";
import { useWaypointAltitudeRange } from "../mode-data-context";
import { SheetModeRenderOrders } from "./sheet-mode-render-orders";

type SheetModeSceneBaseProps = {
  /** Sheet to render */
  sheetElement?: IElementGenericImgSheet;

  /** The current active path */
  pathElement?: IElement;

  /** The list of paths to render */
  paths: IElementSection[];

  /** The list of panoramas currently showed */
  panos: IElementImg360[];

  /** The list of annotations to render on the map */
  annotations?: IElementGenericAnnotation[];

  /** The list of measurements from the store, to render on the map */
  measurements?: Measurement[];

  /** Callback when a placeholder is clicked */
  onPlaceholderClick?(target: IElementImg360): void;

  /** Callback on path activated */
  onPathActivated?(
    ev: ThreeEvent<MouseEvent>,
    path: IElementSection,
    boundingBox: Box3,
  ): void;

  /** Callback when the sheet is clicked which gives the clicked position */
  onSheetClick?(pos: Vector3): void;

  /** Boolean to show/hide the placeholder preview */
  isPlaceholderPreviewVisible?: boolean;

  /** Optional id of the annotation of interest */
  lookAtId?: GUID;
};

/**
 * @returns the basic scene to render in sheet mode
 */
export function SheetModeSceneBase({
  sheetElement,
  pathElement,
  paths,
  panos,
  annotations,
  measurements,
  onPlaceholderClick,
  onPathActivated,
  onSheetClick,
  isPlaceholderPreviewVisible = false,
  lookAtId,
}: SheetModeSceneBaseProps): JSX.Element {
  // Get sheet from the object cache or suspend loading it
  const sheet = useCached3DObjectIfExists(sheetElement);

  const camera = useThree((s) => s.camera);

  const isTransitioning = useAppSelector(selectModeIsTransitioning);

  const placeholders = useMapPlaceholderPositions(panos, sheetElement);

  const colors = useWaypointsColors(panos);

  const { placeholdersOffset, shiftedPlaceholders } = useMemo(
    () => offsetPlaceholders(placeholders),
    [placeholders],
  );

  const position = useAppSelector(
    selectIElementWorldPosition(sheetElement?.id),
  );

  const activeTool = useAppSelector(selectActiveTool);

  const [hoveredPlaceholder, setHoveredPlaceholder] =
    useState<IElementImg360>();

  const clickOnPlaceholder = useCallback(
    (el: number | IElementImg360) => {
      if (typeof el === "number") onPlaceholderClick?.(panos[el]);
      else onPlaceholderClick?.(el);
    },
    [onPlaceholderClick, panos],
  );

  const onPlaceholderHovered = useCallback(
    (el?: number | IElementImg360) => {
      if (typeof el === "number") {
        setHoveredPlaceholder(panos[el]);
      } else {
        setHoveredPlaceholder(el);
      }
    },
    [panos],
  );

  const { tools, onModelHovered, onModelClicked, onModelZoomed } =
    usePickingToolsCallbacks();

  const { onPointerDown, onClick } = useOnClick(
    (e) => onSheetClick?.(e.point),
    true,
  );

  const [isPickingToolEnabled, setIsPickingToolEnabled] = useState(false);
  const panoOffset = usePanoOffset(paths, sheetElement);

  const shouldWayPointsBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypoints),
  );

  return (
    <>
      {sheet && (
        <>
          <SheetRenderer
            sheet={sheet}
            onPointerMove={
              activeTool
                ? (ev) => onModelHovered(ev, sheet.iElement.id)
                : undefined
            }
            onPointerDown={activeTool ? undefined : onPointerDown}
            onClick={
              activeTool
                ? (ev) => onModelClicked(ev, sheet.iElement.id)
                : onClick
            }
            onWheel={
              activeTool
                ? (ev) => onModelZoomed(ev, sheet.iElement.id)
                : undefined
            }
          />
          <PickingTools
            ref={tools}
            activeModels={[sheet]}
            onToolActiveChanged={setIsPickingToolEnabled}
          />
        </>
      )}
      <SheetModeControls camera={camera} referencePlaneHeight={position[1]} />
      {!isTransitioning && !isPickingToolEnabled && (
        <>
          <OdometryPathsRenderer
            paths={paths}
            activeSheet={sheetElement}
            activePath={pathElement}
            onPlaceholderHovered={onPlaceholderHovered}
            onPlaceholderClick={clickOnPlaceholder}
            onPathClick={onPathActivated}
          />
          {isPlaceholderPreviewVisible && (
            <PlaceholderPreview placeholder={hoveredPlaceholder} />
          )}
          {annotations && (
            <AnnotationsRenderer
              annotations={annotations}
              lookAtId={lookAtId}
              depthTest={false}
              renderOrder={SheetModeRenderOrders.MeasurementsAndAnnotations}
            />
          )}
          {/* Place panorama images above waypoints in odometric paths, to increase their visibility */}
          <group position-y={panoOffset}>
            <group position={placeholdersOffset}>
              <MapWaypointsRenderer
                customColors={colors}
                visible={shouldWayPointsBeVisible}
                waypoints={shiftedPlaceholders}
                onPlaceholderClick={clickOnPlaceholder}
                onPlaceholderHovered={onPlaceholderHovered}
              />
            </group>
          </group>
        </>
      )}
      {!isTransitioning && measurements && (
        <MeasurementsRenderer
          measurements={measurements}
          isToolActive={isPickingToolEnabled}
          depthTest={false}
          renderOrder={SheetModeRenderOrders.MeasurementsAndAnnotations}
        />
      )}

      {/*
       * The enabled flag from DesaturationPipeline is not used here so that the components using SheetModeSceneBase
       * need not have an instance of a EffectPipeline when there is no desaturateSheet tool in them
       *
       * Having this EffectPipeline along with another one in a R3f canvas or in a View
       * using SheetModeSceneBase would conflict with each other
       */}
      {sheetElement && (
        <DesaturationPipeline
          id={sheetElement.id}
          enabled={activeTool === ToolName.desaturateSheet}
        />
      )}
    </>
  );
}

/**
 * @param paths The trajectories in the sheet
 * @param sheetElement The area sheet being displayed
 * @returns the offset to apply to the pano placeholders to ensure they are rendered above the trajectory panos
 */
function usePanoOffset(
  paths: IElementSection[],
  sheetElement?: IElementGenericImgSheet,
): number {
  const pathPanos = useAppSelector(
    (state) =>
      paths.flatMap((path) =>
        selectChildrenDepthFirst(path, isIElementImg360)(state),
      ),
    isEqual,
  );
  const pathPanoPositions = useMapPlaceholderPositions(pathPanos, sheetElement);
  // To avoid z-fighting and to account for additional layer offset of the trajectory panos
  const additionalOffset = 0.5;
  return (
    additionalOffset +
    useMemo(
      () => Math.max(0, ...pathPanoPositions.map((pos) => pos.y)),
      [pathPanoPositions],
    )
  );
}

/**
 * @returns The waypoints color based on altitude, if the option is enabled
 * @param panos The list of all panos in the scene
 */
function useWaypointsColors(panos: IElementImg360[]): Color[] | undefined {
  const shouldColorWaypoints = useAppSelector(selectShouldColorWaypoints);

  const range = useWaypointAltitudeRange();

  const { getState } = useAppStore();
  return useMemo(() => {
    if (!shouldColorWaypoints) return;

    const state = getState();
    const positions = panos.map((p) => {
      if (selectIsPanoExtractedFromData(p)(state)) {
        return selectIElementWorldPosition(p.id)(state);
      }
    });

    return positions.map((p) => {
      if (!p || !range) return new Color(INVALID_ALTITUDE_COLOR);

      const alpha = (p[1] - range.lowest) / (range.highest - range.lowest);
      return new Color(
        interpolateColor(MIN_ALTITUDE_COLOR, MAX_ALTITUDE_COLOR, alpha),
      );
    });
  }, [getState, panos, range, shouldColorWaypoints]);
}
