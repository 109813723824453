import { ToolName } from "@/store/ui/ui-slice";
import { ClipSceneTool } from "@/tools/clip-scene-tool";
import { Box3, Plane } from "three";

interface RevisionScansToolsProps {
  /** The currently active tool */
  activeTool?: ToolName;

  /** The combined bounding box of all point clouds */
  combinedPcBoundingBox?: Box3;

  /** Callback used when the clipping planes change */
  onClippingPlanesChanged(planes: Plane[]): void;
}

/** @returns The tool logics used in the revision scans scene */
export function RevisionScansTools({
  activeTool,
  combinedPcBoundingBox,
  onClippingPlanesChanged,
}: RevisionScansToolsProps): JSX.Element {
  return (
    <ClipSceneTool
      active={activeTool === ToolName.clipScene}
      modelBox={combinedPcBoundingBox}
      clippingPlanesChanged={onClippingPlanesChanged}
    />
  );
}
