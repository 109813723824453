import { ToSheetAnimation } from "@/components/r3f/animations/to-sheet-animation";
import { ModeTransitionProps } from "@/modes/mode";
import { useCurrentScene } from "@/modes/mode-data-context";
import { assert } from "@faro-lotv/foundation";
import { useState } from "react";

/**
 *  @returns The transition from a mode to this one
 *  @param props The properties provided by the App during the transition
 */
export function FloorScaleModeTransition(
  props: ModeTransitionProps,
): JSX.Element {
  const { sheet } = useCurrentScene();
  assert(sheet, "A sheet is required for the floor scale mode");
  const [placeholders] = useState(() => []);
  return (
    <ToSheetAnimation
      placeholders={placeholders}
      sheetElement={sheet}
      {...props}
    />
  );
}
