import { clearStore } from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/ielement-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

export type PointCloudAnalysis = {
  /** The unique id of this analysis */
  id: GUID;

  /** The polygon used to select points for analysis */
  polygonSelection: Vector3Tuple[];

  /** The id of the point cloud iElement this analysis originated from */
  parentId: GUID;
};

type PointCloudAnalysisToolState = {
  /** For each specific point cloud, identified by its GUID, store the list of analyses associated to it */
  analyses: Record<GUID, PointCloudAnalysis[]>;

  /** The active analysis or undefined if none*/
  activeAnalysis?: PointCloudAnalysis;

  /** True if an analysis is being created */
  isAnalysisBeingCreated: boolean;

  /** Number of analysis created this session */
  numberOfSessionAnalyses: number;
};

const initialState: PointCloudAnalysisToolState = {
  analyses: {},
  activeAnalysis: undefined,
  isAnalysisBeingCreated: false,
  numberOfSessionAnalyses: 0,
};

const pointCloudAnalysisToolSlice = createSlice({
  name: "pointCloudAnalysisTool",
  initialState,
  reducers: {
    addAnalysis(
      state,
      action: PayloadAction<{
        pointCloudID: GUID;
        analysis: PointCloudAnalysis;
      }>,
    ) {
      state.numberOfSessionAnalyses++;
      if (!(action.payload.pointCloudID in state.analyses)) {
        state.analyses[action.payload.pointCloudID] = [];
      }

      state.analyses[action.payload.pointCloudID].push(action.payload.analysis);
    },
    setActiveAnalysis(
      state,
      action: PayloadAction<PointCloudAnalysis | undefined>,
    ) {
      state.activeAnalysis = action.payload;
    },
    setIsAnalysisBeingCreated(state, action: PayloadAction<boolean>) {
      state.isAnalysisBeingCreated = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const pointCloudAnalysisToolReducer =
  pointCloudAnalysisToolSlice.reducer;

export const { addAnalysis, setActiveAnalysis, setIsAnalysisBeingCreated } =
  pointCloudAnalysisToolSlice.actions;
