import { GUID } from "@faro-lotv/foundation";
import {
  RegistrationRevision,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useEffect, useState } from "react";

/**
 * @param revisionId The ID of the revision to load.
 * @returns The revision with the given ID.
 *  While the revision is loading, `undefined` is returned.
 *  If the revision does not exist, an error is thrown.
 */
export function useLoadRevision(
  revisionId: GUID,
): RegistrationRevision | undefined {
  const { projectApiClient } = useApiClientContext();

  const [revision, setRevision] = useState<RegistrationRevision>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const abortController = new AbortController();

    setRevision(undefined);
    setError(undefined);

    projectApiClient
      .getRegistrationRevision(revisionId, abortController.signal)
      .then(setRevision)
      .catch((error) => {
        if (abortController.signal.aborted) {
          // Ignore errors from unmounting
          return;
        }
        setError(error);
      });

    return () => {
      abortController.abort("useRevision hook unmounted");
    };
  }, [projectApiClient, revisionId]);

  if (error) {
    throw error;
  }

  return revision;
}
