import {
  EmbeddedToolbar,
  EmbeddedToolbarButton,
} from "@/components/ui/embedded-toolbar";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import {
  ANNOTATION_ZINDEX_RANGE_MAP,
  DeleteIcon,
  PointCloudAnalysisColormapIcon,
  PointCloudAnalysisReferencePlaneIcon,
} from "@faro-lotv/app-component-toolbox";
import { Tooltip, useTheme } from "@mui/material";
import { Vector3 } from "three";
import { AppAwareHtml } from "../app-aware-html";
import { MEASURE_ANIMATION_LENGTH } from "../measurements/measure-constants";

type AnalysisActionBarProp = {
  /** 3D point to anchor the html component to. */
  anchorPoint: Vector3;
};

/**
 * @returns Action toolbar for modify active analysis.
 */
export function AnalysisActionBar({
  anchorPoint,
}: AnalysisActionBarProp): JSX.Element | null {
  const parentRef = useViewOverlayRef();

  const buttonSize = "36px";
  const actionBarPointOffset = "5px";
  const theme = useTheme();

  return (
    <AppAwareHtml
      portal={parentRef}
      position={anchorPoint}
      zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
      eps={-1}
      style={{
        pointerEvents: "auto",
        // Add offset to put the container in the top left of the anchorPoint
        transform: `translate(calc(-100% - ${actionBarPointOffset}), calc(-100% - ${actionBarPointOffset}))`,
      }}
    >
      <EmbeddedToolbar
        isActive={true}
        sx={{
          transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
          backgroundColor: theme.palette.gray999,
        }}
      >
        <Tooltip title="Change color scale" placement="top">
          <EmbeddedToolbarButton
            aria-label="change color scale"
            buttonSize={buttonSize}
            value="Change color scale"
            onClick={(ev) => {
              ev.stopPropagation();
              // To be implemented by https://faro01.atlassian.net/browse/CADBIM-726
            }}
          >
            <PointCloudAnalysisColormapIcon />
          </EmbeddedToolbarButton>
        </Tooltip>
        <Tooltip title="Change reference plane" placement="top">
          <EmbeddedToolbarButton
            aria-label="change reference plane"
            buttonSize={buttonSize}
            value="Change refernece plane"
            onClick={(ev) => {
              ev.stopPropagation();
              // To be implemented by https://faro01.atlassian.net/browse/CADBIM-727
            }}
          >
            <PointCloudAnalysisReferencePlaneIcon />
          </EmbeddedToolbarButton>
        </Tooltip>
        <Tooltip title="Delete" placement="top">
          <EmbeddedToolbarButton
            aria-label="delete analysis"
            buttonSize={buttonSize}
            value="Delete"
            onClick={(ev) => {
              ev.stopPropagation();
              // To be implemented by https://faro01.atlassian.net/browse/CADBIM-728
            }}
          >
            <DeleteIcon />
          </EmbeddedToolbarButton>
        </Tooltip>
      </EmbeddedToolbar>
    </AppAwareHtml>
  );
}
